<div class="p-3">
  <kadu-table
    [columns]="cols"
    [rows]="20"
    [lazy]="true"
    [request]="shopsRequest"
    (onChange)="setCurrentFilters($event)"
    [withFilters]="true"
    [sortable]="true"
  >
  </kadu-table>
</div>

<ng-template #actionsTemplate let-shop>
  <kadu-button (clickHandler)="showData(shop)" class="btn btn-small btn-submit">
    Prikaži podatke
  </kadu-button>
</ng-template>
