import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from 'src/app/_interceptors/skip-interceptors';
import { convertToFormData } from 'src/app/_models/util';
import { ReceiveNote } from 'src/app/_view-models/receive-note';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReceiveNoteService {
  baseUrl = 'backoffice/receive_notes/';
  constructor(private http: HttpClient) { }

  create(receiveNote: ReceiveNote): Observable<ReceiveNote> {
    return this.http.post<ReceiveNote>(`${this.baseUrl}`, convertToFormData(receiveNote, ['receiveNoteFile', 'fellerFile']), {
      headers: new HttpHeaders().set(SKIP_DTO_TRANSFORM_INTERCEPTOR, SKIP_DTO_TRANSFORM_INTERCEPTOR)
    });
  }

  rollback(receiveNoteId: number): Observable<any> {
    return this.http.patch(`${this.baseUrl}${receiveNoteId}/rollback`, {});
  }
}
