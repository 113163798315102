import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { StockCheckupService } from 'src/app/_services/stock-checkup/stock-checkup.service';
import { PopupService } from 'src/app/components/popup/popup.service';

@Component({
  selector: 'app-stock-checkup-single',
  templateUrl: './stock-checkup-single.component.html',
  styleUrls: ['./stock-checkup-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockCheckupSingleComponent {

  formControls = {
    startTime: { state: new Date(), validators: [Validators.required] },
    file: {state: null, validators: [Validators.required]},
    stockItemType: {state: null, validators: [Validators.required]}
  };

  stockItemTypeOptions = [
    {
      label: "Roba",
      value: "PRODUCT_TYPE_ITEM",
    },
    {
      label: "Repromaterijal",
      value: "RAW_MATERIAL",
    },
  ];

  constructor(private ref: DynamicDialogRef, private stockCheckupService: StockCheckupService, private popupService: PopupService) { }

  create(formValue): void {
    this.stockCheckupService.create(formValue).subscribe(res => {
      this.popupService.fireToast({
          title: "Uspešno dodat popis",
          type: "success",
      });
      this.ref.close({sucess:true});
    }, (error) => {
      this.popupService.fire({
        title: "Greška prilikom dodavanja popisa",
        type: "error",
        text: error.error.message
      });
    });
  }
}
