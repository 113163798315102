import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-partner-single",
  templateUrl: "./partner-single.component.html",
  styleUrls: ["./partner-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerSingleComponent {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  partner = this.config.data.partner;

  formControls = {
    name: { state: null, validators: [Validators.required] },
    firstName: { state: null, validators: [Validators.required] },
    lastName: { state: null, validators: [Validators.required] },
    address: { state: null, validators: [Validators.required] },
    city: { state: null, validators: [Validators.required] },
    country: { state: null, validators: [Validators.required] },
    email: {
      state: null,
      validators: [
        Validators.required,
        Validators.pattern(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ],
    },
    password: { state: null, validators: [Validators.required] },
    phoneNumber: { state: null, validators: [Validators.required] },
    username: { state: null, validators: [Validators.required] },
    dispatchOrdersNumber: { state: null, validators: [Validators.required] },
    dispatchOrdersAddress: { state: null, validators: [Validators.required] },
    dispatchOrdersCity: { state: null, validators: [Validators.required] },
    dispatchOrdersPostalCode: {
      state: null,
      validators: [Validators.required],
    },
    returnOrdersNumber: { state: null, validators: [Validators.required] },
    returnOrdersAddress: { state: null, validators: [Validators.required] },
    returnOrdersCity: { state: null, validators: [Validators.required] },
    returnOrdersPostalCode: { state: null, validators: [Validators.required] },
  };

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngAfterViewInit(): void {
    if (this.partner) {
      this.formComponent.formGroup.patchValue(this.partner);
    }
  }

  close(): void {
    this.ref.close(false);
  }

  save(formValue: any): void {
    if (this.partner) {
      this.ref.close({
        editMode: true,
        data: { ...formValue, id: this.partner.id },
      });
    } else {
      this.ref.close({ editMode: false, data: formValue });
    }
  }
}
