import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/_interceptors/loading-spinner.interceptor";

@Injectable({
  providedIn: "root",
})
export class SupplyService {
  baseUrl = "backoffice/product_type_items";
  constructor(private http: HttpClient) {}

  getAll(params: any): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}`, {
      params,
      headers: new HttpHeaders().set(
        SKIP_LOADING_INTERCEPTOR,
        SKIP_LOADING_INTERCEPTOR
      ),
    });
  }

  update(id: number, amount: number): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}/amount`, { amount });
  }
}
