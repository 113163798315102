import { Injectable } from "@angular/core";
import {
  DynamicDialogRef,
  DialogService as NgDialogService,
} from "primeng/dynamicdialog";

@Injectable()
export class DialogService {
  constructor(public dialogService: NgDialogService) {}

  open(component: any, configuration: any): DynamicDialogRef {
    if (
      !configuration.data ||
      (configuration.data && !configuration.data.main)
    ) {
      configuration = {
        ...configuration,
        styleClass: "dulo-dialog-wrapper " + (configuration.styleClass || ""),
        data: {
          ...configuration.data,
          styleClass: configuration.innerStyleClass,
        },
      };
    }
    const ref: DynamicDialogRef = this.dialogService.open(
      component,
      configuration
    );
    return ref;
  }
}
