import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PaginatedResult } from "src/app/_models/paginated-result";
import { OrderService } from "src/app/_services/order/order.service";
import { ReclamationService } from "src/app/_services/reclamation/reclamation.service";
import { Reclamation } from "src/app/_view-models/reclamation";
import { KaduTableComponent } from "@kadung/kadung/kadu-table";
import { TableColumn } from "src/app/_models/table-column";
import { TableFilter } from "src/app/_models/table-filter";
import { PopupService } from "src/app/components/popup/popup.service";
import { SubSink } from "subsink";
import { generateTableRequestParams } from "src/app/_models/table-util";

@Component({
  selector: "app-reclamations",
  templateUrl: "./reclamations.component.html",
  styleUrls: ["./reclamations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReclamationsComponent implements OnInit {
  @ViewChild("reclamationItemActionsTemplate", { static: true })
  reclamationItemActionsTemplate: TemplateRef<any>;
  @ViewChild("reclamationItemImagesTemplate", { static: true })
  reclamationItemImagesTemplate: TemplateRef<any>;
  @ViewChild("reclamationItemInfoTemplate", { static: true })
  reclamationItemInfoTemplate: TemplateRef<any>;
  @ViewChild("table", { static: false }) table: KaduTableComponent;

  currentFilters: any = {
    first: 0,
    rows: 20,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  initialFilters = {
    processed: {
      value: [false],
      matchMode: "in",
    },
  };
  persistantFilters = {};
  orderItemInfo = {};

  reclamationCategory = [
    {
      value: null,
      label: "",
    },
    {
      value: "SIZE",
      label: "Veličina",
    },
    {
      value: "PRINT",
      label: "Štampa",
    },
    {
      value: "SEWING",
      label: "Šivara",
    },
    {
      value: "WRONG_PRODUCT",
      label: "Pogrešan proizvod",
    },
    {
      value: "BAD_DESIGN",
      label: "Loš kvalitet dizajna",
    },
    {
      value: "RETURN",
      label: "Povraćaj",
    },
  ];

  actions = [
    {
      value: null,
      label: "",
    },
    {
      value: "ACCEPTED",
      label: "Prihvaćena",
    },
    {
      value: "REJECTED",
      label: "Odbij",
    },
  ];

  filtersMeta = {
    id: { key: "id", matchMode: "eq:" },
    orderId: { key: "orderId", matchMode: "eq:" },
    created: { key: "created", matchMode: "btn:" },
    name: { key: "name", matchMode: "like:" },
    processed: { key: "processed", matchMode: "in:" },
  };

  cols: TableColumn[] = [
    {
      header: "ID",
      field: "id",
      filterType: "string",
      filterTypeMatch: "equals",
      type: "normal",
      width: "10%",
    },
    {
      header: "Order ID",
      field: "orderId",
      filterType: "string",
      filterTypeMatch: "equals",
      sortDisabled: true,
      type: "normal",
      width: "10%",
    },
    {
      header: "Datum",
      field: "created",
      filterType: "date",
      type: "date",
      options: {
        format: "dd.MM.yyyy",
      },
      width: "23%",
    },
    {
      header: "Kupac",
      field: "name",
      sortDisabled: true,
      type: "normal",
      width: "23%",
    },
    {
      header: "Procesuirano",
      field: "processed",
      filterType: "select",
      type: "boolean",
      width: "23%",
      options: {
        optionValues: [
          {
            label: "Da",
            value: true,
          },
          {
            label: "Ne",
            value: false,
          },
        ],
        initialValues: [false],
      },
    },
  ];

  reclamationItemCols: TableColumn[] = [];

  reclamationRequest = ((
    filters: TableFilter
  ): Observable<PaginatedResult<Reclamation>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...this.initialFilters,
        ...this.persistantFilters,
        ...filters.filters,
      },
    };

    return this.reclamationService
      .getAll(generateTableRequestParams(this.currentFilters, this.filtersMeta));
  }).bind(this);

  subs = new SubSink();
  constructor(
    private reclamationService: ReclamationService,
    private orderService: OrderService,
    private popupService: PopupService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.reclamationItemCols = [
      {
        field: "info",
        header: "Info",
        type: "custom",
        options: {
          template: this.reclamationItemInfoTemplate,
        },
      },
      {
        field: "note",
        header: "Komentar",
        type: "normal",
      },
      {
        field: "images",
        header: "Slike",
        type: "custom",
        options: {
          template: this.reclamationItemImagesTemplate,
        },
      },
      {
        field: "action",
        header: "Akcija",
        type: "custom",
        options: {
          template: this.reclamationItemActionsTemplate,
        },
      },
    ];
  }

  ngAfterViewInit(): void {
    this.currentFilters.filters = this.persistantFilters;
    this.table.load(this.currentFilters);
  }

  setCurrentFilters(event) {
    this.currentFilters = event;
  }

  setReclamationItemAction(reclamationItem, event) {
    reclamationItem.action = event.value;
  }

  setReclamationItemCategory(reclamationItem, event) {
    reclamationItem.category = event.value;
  }

  setReclamationItemColor(reclamationItem, event) {
    reclamationItem.newColorId = event.value;
    reclamationItem.newColorName = this.orderItemInfo[
      reclamationItem.id
    ].colors.filter((c) => +c.value === +event.value)[0].label;
  }

  setReclamationItemSize(reclamationItem, event) {
    reclamationItem.newSizeId = event.value;
    reclamationItem.newSizeName = this.orderItemInfo[
      reclamationItem.id
    ].sizes.filter((c) => +c.value === +event.value)[0].label;
  }

  closeReclamation(reclamation) {
    const anyUnfinished =
      reclamation.reclamationItems.length > 0
        ? reclamation.reclamationItems.filter((ri) => {
            return (
              ri.action === undefined ||
              ri.action === null ||
              (ri.action &&
                ri.action !== "REJECTED" &&
                (ri.category === undefined || ri.category === null))
            );
          }).length > 0
        : false;
    if (anyUnfinished) {
      this.popupService.fire({
        title: "Greška",
        text: "Moraš obraditi sve stakve pre nego što završis reklamaciju",
        type: "error",
      });
      return;
    }
    const value: any = {
      id: reclamation.id,
      orderId: reclamation.orderId,
      comment: "",
      reclamationItemList: reclamation.reclamationItems
        .filter((ri) => ri.action === "ACCEPTED")
        .map((ri) => {
          return {
            id: ri.id,
            errorCategory: ri.category,
            productId: ri.productId,
            colorId: ri.newColorId ? ri.newColorId : ri.colorId,
            colorName: ri.newColorName ? ri.newColorName : ri.colorName,
            sizeId: ri.newSizeId ? ri.newSizeId : ri.sizeId,
            sizeName: ri.newSizeName ? ri.newSizeName : ri.sizeName,
            productTypeId: ri.productTypeId,
            productTypeName: ri.productTypeName,
            orderItemId: ri.orderItemId,
          };
        }),
    };
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da završite reklamaciju ${reclamation.id}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.reclamationService.finish(value).subscribe(
            (res) => {
              if (
                res &&
                res["exceptionType"] ===
                  "E_COMMERCE_RECLAMATION_POLICY_VIOLATION"
              ) {
                this.popupService.fire({
                  title: `Prodavnica ${res.data} ne prihvata zamene za veličinu!`,
                  type: "error",
                });
              } else {
                this.popupService.fire({
                  title: "Reklamacija uspešno obradjena",
                  type: "success",
                });
                this.table.load(this.currentFilters);
              }
            },
            (err) => {
              this.popupService.fire({
                title: "Greška",
                text: err.error.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  toggleChangeReclamationItemMode(ri) {
    ri.change = !ri.change;
    if (ri.change) {
      this.orderService
        .getOrderItemPossibilities(ri.productId)
        .subscribe((response: any) => {
          this.orderItemInfo[ri.id] = {
            colors: response.colors.map((c) => {
              return {
                value: c.id,
                label: c.name,
              };
            }),
            sizes: response.sizes.map((s) => {
              return {
                value: s.id,
                label: s.name,
              };
            }),
          };
          this.cdr.detectChanges();
        });
    }
  }
}
