import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-warehouse-location-single",
  templateUrl: "./warehouse-location-single.component.html",
  styleUrls: ["./warehouse-location-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarehouseLocationSingleComponent {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  warehouseLocation = this.config.data.warehouseLocation;

  formControls = {
    address: { state: null, validators: [Validators.required] },
    skuCode: { state: null, validators: [Validators.required] },
  };

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngAfterViewInit(): void {
    if (this.warehouseLocation) {
      this.formComponent.formGroup.patchValue(this.warehouseLocation);
    }
  }

  close(): void {
    this.ref.close(false);
  }

  save(formValue: any): void {
    if (this.warehouseLocation) {
      this.ref.close({
        editMode: true,
        data: { ...formValue, id: this.warehouseLocation.id },
      });
    } else {
      this.ref.close({ editMode: false, data: formValue });
    }
  }
}
