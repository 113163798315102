import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { PopupService } from "src/app/components/popup/popup.service";
import { OrderService } from "src/app/_services/order/order.service";
import { Order } from "src/app/_view-models/order";

@Component({
  selector: "app-posta-order-actions",
  templateUrl: "./posta-order-actions.component.html",
  styleUrls: ["./posta-order-actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostaOrderActionsComponent {
  @Input() order: Order;
  @Output() actionHandler = new EventEmitter<boolean>();

  constructor(
    private popupService: PopupService,
    private orderService: OrderService
  ) {}

  assignNewDeliveryCode() {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da dodelite novi delivery code za porudžbinu ${this.order.id}`,
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.orderService
            .assignNewDeliveryCode(this.order.id)
            .subscribe(() => {
              this.actionHandler.emit(true);
              this.popupService.fire({
                title: "Uspešno dodeljen novi broj pošiljke",
                type: "success",
                toast: true,
                position: "bottom-end",
              });
            });
        }
      });
  }

  sendOrderAgain() {
    this.popupService
      .fire({
        titleText: "Pošalji ponovo",
        text: "Unesi novi delivery code",
        input: "text",
        type: "warning",
        showCancelButton: true,
      })
      .then((result) => {
        if (!result.value) return;
        this.orderService
          .sendOrderAgain(this.order.id, result.value)
          .subscribe(() => {
            this.popupService.fire({
              titleText: "Uspešno",
              type: "success",
              text: "Porudžbina ponovo poslata",
            });
            this.actionHandler.emit(true);
          });
      });
  }
}
