import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { OrderItemSingleComponent } from "src/app/_components/order-item-single/order-item-single.component";
import { OrderService } from "src/app/_services/order/order.service";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { SubSink } from "subsink";

@Component({
  selector: "app-change-order-item",
  templateUrl: "./change-order-item.component.html",
  styleUrls: ["./change-order-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderItemComponent implements OnInit {
  orderItem = this.config.data.orderItem;
  table = this.config.data.table;
  currentFilters = this.config.data.currentFilters;

  orderItemInfo = {};
  subs = new SubSink();

  constructor(
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    private popupService: PopupService,
    private orderService: OrderService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.orderService
      .getOrderItemPossibilities(this.orderItem.productId)
      .subscribe((response: any) => {
        this.orderItemInfo[this.orderItem.id] = {
          colors: response.colors.map((c) => {
            return {
              value: c.id,
              label: c.name,
            };
          }),
          sizes: response.sizes.map((s) => {
            return {
              value: s.id,
              label: s.name,
            };
          }),
        };
        this.cdr.detectChanges();
      });
  }

  editOrderItemDetails() {
    const dialog = this.dialogService.open(OrderItemSingleComponent, {
      width: "70%",
      height: "90%",
      data: {
        orderItem: {
          price: this.orderItem.productPrice,
          ...this.orderItem,
        },
      },
    });
    this.subs.add(
      dialog.onClose.subscribe((data) => {
        if (data) {
          this.orderService
            .editOrderItemDetails(this.orderItem, data)
            .subscribe(
              (res) => {
                this.popupService.fire({
                  title: "Uspešno promenjeni podaci",
                  type: "success",
                });
                this.table.load(this.currentFilters);
                this.cdr.markForCheck();
              },
              (err) => {
                this.popupService.fire({
                  title: "Greška",
                  text: err.error.message,
                  type: "error",
                });
              }
            );
        }
      })
    );
  }

  deleteOrderItem() {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da obrišete ${this.orderItem.id}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.orderService.deleteOrderItem(this.orderItem.id).subscribe(() => {
            this.table.load(this.currentFilters);
            this.cdr.markForCheck();

            this.popupService.fire({
              title: "Uspešno obrisana stavka porudžbine",
              type: "success",
              toast: true,
              position: "bottom-end",
            });
          });
        }
      });
  }

  repeatManufacturingOrderItem() {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da ponovite izradu ${this.orderItem.id}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.orderService
            .repeatManufacturingOrderItem(this.orderItem.id)
            .subscribe(() => {
              this.table.load(this.currentFilters);
              this.cdr.markForCheck();

              this.popupService.fire({
                title: "Uspešno označeno za ponovnu izradu",
                type: "success",
                toast: true,
                position: "bottom-end",
              });
            });
        }
      });
  }

  changeColor(colorId) {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da dodelite promenite boju za ${this.orderItem.id}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.orderService
            .changeOrderItemColor(this.orderItem.id, colorId)
            .subscribe(() => {
              this.table.load(this.currentFilters);
              this.cdr.markForCheck();
              this.popupService.fire({
                title: "Uspešno promenjena boja",
                type: "success",
                toast: true,
                position: "bottom-end",
              });
            });
        }
      });
  }

  changeSize(sizeId) {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da dodelite promenite veličinu za ${this.orderItem.id}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.orderService
            .changeOrderItemSize(this.orderItem.id, sizeId)
            .subscribe(() => {
              this.table.load(this.currentFilters);
              this.cdr.markForCheck();

              this.popupService.fire({
                title: "Uspešno promenjena veličina",
                type: "success",
                toast: true,
                position: "bottom-end",
              });
            });
        }
      });
  }
}
