import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { KaduTableComponent, PaginatedResult } from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";
import { TableFilter } from "src/app/_models/table-filter";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { PrintTypeService } from "src/app/_services/print-type/print-type.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { PrintTypeSingleComponent } from "./_components/print-type-single/print-type-single.component";
import { DialogService } from "src/app/components/dialog/dialog.service";

@Component({
  selector: "app-print-types",
  templateUrl: "./print-types.component.html",
  styleUrls: ["./print-types.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintTypesComponent implements OnInit {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild(KaduTableComponent, { static: false }) table: KaduTableComponent;

  cols;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  printTypes$ = ((filters: TableFilter): Observable<PaginatedResult<any>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };

    return this.printTypeService.getAllPaginated(
      generateTableRequestParams(this.currentFilters, {})
    );
  }).bind(this);

  constructor(
    private printTypeService: PrintTypeService,
    private popupService: PopupService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "Id",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Ime",
        field: "name",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Code",
        field: "code",
        filterType: "string",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "string",
        type: "custom",
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  deletePrintType(printType: any): void {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da obrišete tip printa ${printType.name}?`,
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.printTypeService.deletePrintType(printType.id).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno obrisan tip printa",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  openDialog(printType = null): void {
    const dialog = this.dialogService.open(PrintTypeSingleComponent, {
      showHeader: false,
      width: "600px",
      data: {
        printType,
      },
    });

    dialog.onClose.subscribe((response) => {
      if (!response) return;

      if (response.editMode) {
        this.printTypeService.editPrintType(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno izmenjen tip printa",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      } else {
        this.printTypeService.addPrintType(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno dodat tip printa",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      }
    });
  }
}
