<kadu-button
  class="btn btn-small btn-submit ms-2 mb-2 mt-2"
  (clickHandler)="createStockCheckup()"
>
  Unesi novi popis
</kadu-button>

<kadu-table
  #table
  [columns]="cols"
  [rows]="40"
  [lazy]="true"
  [request]="stockCheckupsRequest"
  [withFilters]="true"
  [loading]="true"
  (onChange)="setCurrentFilters($event)"
  [pageablePageKey]="'currentResults'"
  [sortable]="true"
  [pageablePageKey]="'currentResults'"
  [pageableTotalResultsKey]="'totalResults'"
>
  <ng-template #buttonsTemplate let-purchaseOrder let-index="index">
    <!-- <div class="d-flex justify-content-around gap-2">
      <kadu-button
        class="btn btn-small btn-danger"
        (clickHandler)="voidStockCheckup(purchaseOrder)"
        >
        Storniraj
      </kadu-button
      >
    </div> -->
  </ng-template>
</kadu-table>
