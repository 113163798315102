<div class="d-flex justify-content-center">
  <kadu-form
    class="d-flex w-50"
    [controls]="formControls"
    (submitHandler)="update($event)"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <div class="d-block d-flex flex-column justify-content-start gap-3">
          <div>
            <div class="mb-2 w-100">
              <p class="p2 mb-1">Tip</p>
              <kadu-select
                class=""
                formControlName="type"
                [options]="typeOptions"
              ></kadu-select>
            </div>

            <kadu-checkbox
              elClass="d-flex align-items-baseline justify-content-start gap-2"
              formControlName="isUsed"
            >
              <p class="p2 mb-0 ms-1">Iskorišćeno</p>
            </kadu-checkbox>
          </div>

          <kadu-button
            class="btn btn-small btn-submit w-100"
            type="submit"
          >
            Izmeni
          </kadu-button>

          <p class="p3">* Obavezna polja</p>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>
</div>
