<main class="text-start p-3">
  <kadu-form
    #form
    (submitHandler)="createProductType($event)"
    [controls]="formControls"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <section class="col-12 row">
          <div class="col-6">
            <div class="d-flex gap-2 align-items-center">
              <div>
                <label class="p3">Ime</label>
                <kadu-input
                  #name
                  formControlName="name"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="name"
                  [control]="formGroup.get('name')"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Slug</label>
                <kadu-input
                  #slug
                  formControlName="slug"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="slug"
                  [control]="formGroup.get('slug')"
                ></app-form-errors>
              </div>
            </div>

            <div class="d-flex gap-2 align-items-center">
              <div>
                <label class="p3">Opis</label>

                <kadu-text-editor
                  #description
                  placeholder="Unesi tekst"
                  formControlName="description"
                ></kadu-text-editor>
                <app-form-errors
                  [formElement]="description"
                  [control]="formGroup.get('description')"
                ></app-form-errors>
              </div>
            </div>
            <div class="d-flex gap-2 align-items-center">
              <div>
                <label class="p3">SKU</label>
                <kadu-input
                  #skuCode
                  formControlName="skuCode"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="skuCode"
                  [control]="formGroup.get('skuCode')"
                  [Errors]="{
                    pattern: 'Polje mora imati tačno 6 karaktera'
                  }"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Brand</label>
                <kadu-input
                  #brand
                  formControlName="brand"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="brand"
                  [control]="formGroup.get('brand')"
                  [Errors]="{
                    pattern: 'Brojevi nisu dozvoljeni'
                  }"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Brand SKU</label>
                <kadu-input
                  #brandSkuCode
                  formControlName="brandSkuCode"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="brandSkuCode"
                  [control]="formGroup.get('brandSkuCode')"
                  [Errors]="{
                    pattern:
                      'Polje mora imati tačno 3 karaktera i sadržati isključivo velika slova'
                  }"
                ></app-form-errors>
              </div>
            </div>

            <div class="d-flex gap-2 align-items-center">
              <div>
                <label class="p3">Preporučena cena</label>
                <kadu-number-range-input
                  #recommendedPrice
                  formControlName="recommendedPrice"
                ></kadu-number-range-input>
                <app-form-errors
                  [formElement]="recommendedPrice"
                  [control]="formGroup.get('recommendedPrice')"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Preporučeni profit</label>
                <kadu-input
                  formControlName="recommendedProfit"
                  elClass="p3"
                  #recommendedProfit
                ></kadu-input>
                <app-form-errors
                  [formElement]="recommendedProfit"
                  [control]="formGroup.get('recommendedProfit')"
                  [Errors]="{
                    pattern: 'Polje mora sadržati isključivo brojeve'
                  }"
                ></app-form-errors>
              </div>
            </div>

            <div class="d-flex gap-2 align-items-baseline">
              <div>
                <label class="p3"> Primarni patner</label>
                <kadu-select
                  optionValue="value.id"
                  elClass="p3 smaller"
                  formControlName="primaryPartnerId"
                  [options]="partners$ | async"
                  [filter]="true"
                  filterBy="label"
                  #primaryPartnerId
                ></kadu-select>
                <app-form-errors
                  [formElement]="primaryPartnerId"
                  [control]="formGroup.get('primaryPartnerId')"
                ></app-form-errors>
              </div>
              <div class="mt-3">
                <label class="p3">Autorizovani partneri</label>
                <kadu-select
                  #authorizedPartnerList
                  elClass="p3"
                  formControlName="authorizedPartnerList"
                  [multiple]="true"
                  [options]="partners$ | async"
                  selectedItemsLabel="Partner ({ 0 })"
                  [filter]="true"
                  filterBy="name"
                ></kadu-select>
                <app-form-errors
                  [formElement]="authorizedPartnerList"
                  [control]="formGroup.get('authorizedPartnerList')"
                ></app-form-errors>
                <p class="p3 mb-0" style="min-height: 18px">
                  {{
                    formGroup.get("authorizedPartnerList")?.value
                      | join : ", " : "name"
                  }}
                </p>
              </div>
              <div>
                <label class="p3">SKU code lokacije magacina</label>
                <kadu-select
                  [filter]="true"
                  filterBy="label"
                  formControlName="warehouseLocationSkuCode"
                  elClass="p3 smaller"
                  [options]="warehouseLocations$ | async"
                  #warehouseLocationSkuCode
                ></kadu-select>
                <app-form-errors
                  [formElement]="warehouseLocationSkuCode"
                  [control]="formGroup.get('warehouseLocationSkuCode')"
                ></app-form-errors>
              </div>
            </div>

            <div class="d-flex gap-2 align-items-center">
              <div class="mt-3 d-flex gap-2 align-items-center">
                <label class="p3">Aktivan</label>
                <kadu-checkbox formControlName="active"></kadu-checkbox>
              </div>
            </div>
          </div>

          <div class="col-6 d-flex gap-1">
            <div class="col-6">
              <div class="col-7">
                <label>Size info url</label>

                <kadu-file-upload
                  [customFileTemplate]="sizeInfoUrlTemplate"
                  formControlName="sizeInfo"
                  chooseLabel="Size info url"
                  accept="image/png,image/jpeg,image/webp"
                  [auto]="true"
                  #sizeInfoUrlUpload
                >
                  <ng-template #sizeInfoUrlTemplate let-file>
                    <ng-container
                      *ngTemplateOutlet="
                        fileTemplate;
                        context: {
                          $implicit: file,
                          fileUpload: sizeInfoUrlUpload
                        }
                      "
                    ></ng-container>
                  </ng-template>
                </kadu-file-upload>
              </div>
            </div>

            <div class="col-6">
              <div class="col-7">
                <label>Cover image</label>

                <kadu-file-upload
                  [customFileTemplate]="coverImageTemplate"
                  formControlName="coverImage"
                  chooseLabel="Unesi cover image"
                  accept="image/png,image/jpeg,image/webp"
                  [auto]="true"
                  #coverImageUrlUpload
                >
                  <ng-template #coverImageTemplate let-file>
                    <ng-container
                      *ngTemplateOutlet="
                        fileTemplate;
                        context: {
                          $implicit: file,
                          fileUpload: coverImageUrlUpload
                        }
                      "
                    ></ng-container>
                  </ng-template>
                </kadu-file-upload>
              </div>
            </div>
          </div>
        </section>

        <hr />

        <section formGroupName="productTypeItemData" class="mt-3 col-12">
          <div class="d-flex gap-2 align-items-center">
            <div class="col-2">
              <label class="p3">Boje</label>
              <kadu-select
                formControlName="colors"
                #colors
                elClass="p3"
                [multiple]="true"
                [options]="colors$ | async"
                selectedItemsLabel="Boja ({ 0 })"
                [filter]="true"
                filterBy="name"
              ></kadu-select>
              <app-form-errors
                [formElement]="colors"
                [control]="formGroup.get('productTypeItemData.colors')"
              ></app-form-errors>
              <p class="p3 mb-0" style="min-height: 18px">
                {{ colors?.value | join : ", " : "name" }}
              </p>
            </div>

            <div class="col-2">
              <label class="p3">Veličine</label>
              <kadu-select
                formControlName="sizes"
                #sizes
                selectedItemsLabel="Veličina ({ 0 })"
                elClass="p3"
                [multiple]="true"
                [options]="sizes$ | async"
                [filter]="true"
                filterBy="name"
              ></kadu-select>
              <app-form-errors
                [formElement]="sizes"
                [control]="formGroup.get('productTypeItemData.sizes')"
              ></app-form-errors>
              <p class="p3 mb-0" style="min-height: 18px">
                {{ sizes?.value | join : ", " : "name" }}
              </p>
            </div>
          </div>

          <hr />

          <div class="col-12 mt-2 ms-2 row border border-dark p-3">
            <div class="col-4 border-end">
              <div class="d-flex gap-2 align-items-center mt-2">
                <div>
                  <label class="p3">Is Default</label>
                  <kadu-select
                    #isDefaultColorId
                    optionValue="id"
                    optionLabel="name"
                    formControlName="defaultColorId"
                    elClass="p3 smaller"
                    [options]="
                      formGroup.get('productTypeItemData.colors').value
                    "
                    [filter]="true"
                    filterBy="name"
                  ></kadu-select>
                  <app-form-errors
                    [formElement]="isDefaultColorId"
                    [control]="
                      formGroup.get('productTypeItemData.defaultColorId')
                    "
                  ></app-form-errors>
                </div>
              </div>

              <div
                formArrayName="rawMaterialList"
                *ngFor="
                  let control of formGroup.get(
                    'productTypeItemData.rawMaterialList'
                  ).controls;
                  let i = index
                "
              >
                <div
                  class="d-flex gap-2 align-items-end pb-2 mt-2"
                  [formGroupName]="i"
                >
                  <div class="col-4">
                    <label class="p3">Repromaterijal</label>
                    <kadu-select
                      formControlName="id"
                      elClass="p3 smaller"
                      selectedItemsLabel="Repromaterijal ({ 0 })"
                      [options]="rawMaterials$ | async"
                      [filter]="true"
                      filterBy="label"
                      #rawMaterialId
                    ></kadu-select>
                    <app-form-errors
                      [formElement]="rawMaterialId"
                      [control]="control.get('id')"
                    ></app-form-errors>
                  </div>

                  <div class="col-3">
                    <label class="p3">Potrošnja po jedinici</label>
                    <kadu-input
                      formControlName="consumptionPerUnit"
                      elClass="p4"
                      #consumptionPerUnit
                    ></kadu-input>
                    <app-form-errors
                      [formElement]="consumptionPerUnit"
                      [control]="control.get('consumptionPerUnit')"
                    ></app-form-errors>
                  </div>

                  <div class="position-relative">
                    <label class="p3">Veličine</label>
                    <kadu-select
                      formControlName="sizeIdList"
                      #rawMaterialSizes
                      selectedItemsLabel="Veličina ({ 0 })"
                      elClass="p3"
                      [options]="
                        formGroup.get('productTypeItemData.sizes').value
                      "
                      [multiple]="true"
                      optionLabel="name"
                      [filter]="true"
                      filterBy="name"
                    ></kadu-select>
                    <app-form-errors
                      [formElement]="rawMaterialSizes"
                      [control]="control.get('sizeIdList')"
                    ></app-form-errors>
                    <p class="p3 mb-0 position-absolute">
                      {{ rawMaterialSizes?.value | join : ", " : "name" }}
                    </p>
                  </div>

                  <kadu-button
                    *ngIf="
                      formGroup.get('productTypeItemData.rawMaterialList')
                        .controls.length > 1
                    "
                    kaduTooltip="Ukloni raw material"
                    (clickHandler)="removeRawMaterial(i)"
                    class="btn btn-cancel btn-small"
                    elClass="d-flex justify-content-center"
                  >
                    <i class="bi bi-x-lg"></i>
                  </kadu-button>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-2">
                <kadu-button
                  kaduTooltip="Dodaj raw material"
                  (clickHandler)="addRawMaterial()"
                  class="btn btn-submit btn-small"
                >
                  <i class="bi bi-plus-lg"></i>
                </kadu-button>
              </div>
            </div>

            <div class="col-5 border-end">
              <div class="colors mb-4">
                <div class="d-flex gap-1 mb-2 align-items-baseline">
                  <kadu-select
                    formControlName="groupedStockState"
                    elClass="p3 smaller mt-2"
                    [options]="stockStates$ | async"
                    [filter]="true"
                    filterBy="label"
                  ></kadu-select>
                  <kadu-input
                    formControlName="groupedBasePrice"
                    placeholder="Grupna bazna cena"
                  ></kadu-input>
                  <kadu-input
                    formControlName="groupedPackageCost"
                    placeholder="Grupna cena pakovanja"
                  ></kadu-input>
                </div>
                <div formArrayName="priceColorData">
                  <div
                    *ngFor="
                      let control of formGroup.get(
                        'productTypeItemData.priceColorData'
                      ).controls;
                      let i = index
                    "
                    [formGroupName]="i"
                  >
                    <label class="col-3 p2">{{ control.value.name }}</label>

                    <div class="d-flex gap-3 mb-2 align-items-center">
                      <div>
                        <kadu-select
                          formControlName="stockState"
                          elClass="p3 smaller"
                          [options]="stockStates$ | async"
                          [filter]="true"
                          filterBy="label"
                          #stockState
                        ></kadu-select>
                        <app-form-errors
                          [formElement]="stockState"
                          [control]="control.get('stockState')"
                        ></app-form-errors>
                      </div>

                      <div>
                        <kadu-input
                          formControlName="basePrice"
                          placeholder="Bazna cena"
                          #basePrice
                        ></kadu-input>
                        <app-form-errors
                          [formElement]="basePrice"
                          [control]="control.get('basePrice')"
                        ></app-form-errors>
                      </div>

                      <div>
                        <kadu-input
                          formControlName="packageCost"
                          placeholder="Cena pakovanja"
                          #packageCost
                        ></kadu-input>
                        <app-form-errors
                          [formElement]="packageCost"
                          [control]="control.get('packageCost')"
                        ></app-form-errors>
                      </div>
                    </div>

                    <div class="d-flex gap-3 mb-2 align-items-center">
                      <ng-container
                        *ngIf="control.value.stockState?.additionalInformation"
                      >
                        <div
                          formGroupName="stockStateAdditionalInformation"
                          *ngFor="
                            let stockStateControl of control.get(
                              'stockStateAdditionalInformation'
                            ).controls | keyvalue;
                            let i = index
                          "
                        >
                          <kadu-input
                            #i
                            [formControlName]="stockStateControl.key"
                            [placeholder]="stockStateControl.key"
                          ></kadu-input>

                          <app-form-errors
                            [formElement]="i"
                            [control]="stockStateControl.value"
                            [Errors]="{
                              pattern:
                                stockStateControlValidators[
                                  stockStateControl.key
                                ]
                            }"
                          ></app-form-errors>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3">
              <div formArrayName="sizeData" class="sizes col-8">
                <div
                  class="mb-2 d-flex gap-3 align-items-baseline"
                  *ngFor="
                    let control of formGroup.get('productTypeItemData.sizeData')
                      .controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <label class="col-3 p3">{{ control.value.name }}</label>
                  <div class="d-flex flex-column align-items-center">
                    <kadu-input
                      formControlName="weight"
                      placeholder="Težina"
                      #weight
                    ></kadu-input>
                    <app-form-errors
                      [formElement]="weight"
                      [control]="control.get('weight')"
                    ></app-form-errors>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <hr />
        <section
          *ngFor="
            let productTypePartControl of formGroup.get('productTypePartList')
              .controls;
            let i = index
          "
          formArrayName="productTypePartList"
          class="mt-3"
        >
          <div [formGroupName]="i" class="ms-2 p-3 border border-dark mt-2">
            <div class="d-flex gap-2 align-items-start justify-content-between">
              <div>
                <label class="p3">Strana proizvoda</label>
                <kadu-input #name formControlName="name"></kadu-input>
                <app-form-errors
                  [formElement]="name"
                  [control]="productTypePartControl.get('name')"
                ></app-form-errors>
              </div>

              <div
                *ngIf="formGroup.get('productTypePartList').controls.length > 1"
              >
                <kadu-button
                  kaduTooltip="Ukloni stranu proizvoda"
                  (clickHandler)="removeProductTypePart(i)"
                  class="btn btn-submit btn-small"
                >
                  <i class="bi bi-x-lg"></i>
                </kadu-button>
              </div>
            </div>
            <div class="d-flex gap-3 mb-2 align-items-center">
              <div>
                <label class="p3">Top</label>
                <kadu-input
                  #top
                  formControlName="top"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="top"
                  [control]="productTypePartControl.get('top')"
                  [Errors]="{
                    pattern: 'Morate uneti broj'
                  }"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Left</label>
                <kadu-input
                  #left
                  formControlName="left"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="left"
                  [control]="productTypePartControl.get('left')"
                  [Errors]="{
                    pattern: 'Morate uneti broj'
                  }"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Width</label>
                <kadu-input
                  #width
                  formControlName="width"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="width"
                  [control]="productTypePartControl.get('width')"
                  [Errors]="{
                    pattern: 'Morate uneti broj'
                  }"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Height</label>
                <kadu-input
                  #height
                  formControlName="height"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="height"
                  [control]="productTypePartControl.get('height')"
                  [Errors]="{
                    pattern: 'Morate uneti broj'
                  }"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Angle</label>
                <kadu-input
                  #angle
                  formControlName="angle"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="angle"
                  [control]="productTypePartControl.get('angle')"
                  [Errors]="{
                    pattern: 'Morate uneti broj'
                  }"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Scale Y</label>
                <kadu-input
                  #scaleY
                  formControlName="scaleY"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="scaleY"
                  [control]="productTypePartControl.get('scaleY')"
                  [Errors]="{
                    pattern: 'Morate uneti broj'
                  }"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Scale X</label>
                <kadu-input
                  #scaleX
                  formControlName="scaleX"
                  elClass="p3"
                ></kadu-input>
                <app-form-errors
                  [formElement]="scaleX"
                  [control]="productTypePartControl.get('scaleX')"
                  [Errors]="{
                    pattern: 'Morate uneti broj'
                  }"
                ></app-form-errors>
              </div>
            </div>

            <div class="d-flex gap-3 mb-2 align-items-end">
              <div>
                <label class="p3">Transform initial</label>
                <kadu-input
                  formControlName="transformInitial"
                  elClass="p3"
                  #transformInitial
                ></kadu-input>
                <app-form-errors
                  [formElement]="transformInitial"
                  [control]="productTypePartControl.get('transformInitial')"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Transform hover</label>
                <kadu-input
                  formControlName="transformHover"
                  elClass="p3"
                  #transformHover
                ></kadu-input>
                <app-form-errors
                  [formElement]="transformHover"
                  [control]="productTypePartControl.get('transformHover')"
                ></app-form-errors>
              </div>

              <div>
                <label class="p3">Tip fajla pripreme za štampu</label>
                <kadu-select
                  [options]="prepressFileTypes"
                  formControlName="prepressFileType"
                  elClass="p3"
                  [filter]="true"
                  filterBy="label"
                  #prepressFileType
                ></kadu-select>
                <app-form-errors
                  [formElement]="prepressFileType"
                  [control]="productTypePartControl.get('prepressFileType')"
                ></app-form-errors>
              </div>
            </div>

            <div class="col-3">
              <div>
                <label class="p3">Preporučene osobine fajla</label>

                <kadu-textarea
                  placeholder="Npr. 3100px x 2600px, PNG, 300 DPI"
                  formControlName="recommendedFileOptions"
                  cols="30"
                  rows="5"
                  elClass="p3"
                  #recommendedFileOptions
                ></kadu-textarea>
                <app-form-errors
                  [formElement]="recommendedFileOptions"
                  [control]="
                    productTypePartControl.get('recommendedFileOptions')
                  "
                ></app-form-errors>
              </div>
            </div>

            <div class="d-flex gap-2 align-items-center">
              <div>
                <label class="p3">Referent size</label>
                <kadu-select
                  formControlName="referentSizeId"
                  elClass="p3 smaller"
                  selectedItemsLabel="Veličina ({ 0 })"
                  [options]="formGroup.get('productTypeItemData.sizes').value"
                  optionLabel="name"
                  optionValue="id"
                  [filter]="true"
                  filterBy="name"
                  #referentSizeId
                ></kadu-select>
                <app-form-errors
                  [formElement]="referentSizeId"
                  [control]="productTypePartControl.get('referentSizeId')"
                ></app-form-errors>
              </div>

              <div class="d-flex gap-2 align-items-center mt-3">
                <label class="p3">Dozvoli odabir boje pozadine</label>
                <kadu-checkbox
                  formControlName="allowBackgroundChooser"
                ></kadu-checkbox>
              </div>
            </div>

            <div class="col-12 row mt-2">
              <div formArrayName="printTypeList" class="col-5 border-end">
                <label class="p2">Tip printa</label>
                <div class="d-flex flex-column gap-2 mt-2">
                  <div
                    *ngFor="
                      let printTypeControl of productTypePartControl.get(
                        'printTypeList'
                      ).controls;
                      let printTypeIndex = index
                    "
                    [formGroupName]="printTypeIndex"
                  >
                    <kadu-checkbox
                      formControlName="choosen"
                      #printTypeChoosen
                      class="d-flex align-items-start"
                    >
                      {{ printTypeControl.value.name }}
                      <div *ngIf="printTypeChoosen.Checked">
                        <div
                          formArrayName="printSizeList"
                          *ngFor="
                            let printSizeControl of printTypeControl.get(
                              'printSizeList'
                            ).controls;
                            let printSizeIndex = index
                          "
                          class="ms-2 mt-2 border border-dark p-3"
                        >
                          <div
                            class="d-flex gap-2"
                            [formGroupName]="printSizeIndex"
                          >
                            <div>
                              <div class="mb-2">
                                <label class="p3">Ime veličine printa</label>
                                <kadu-input
                                  #name
                                  formControlName="name"
                                ></kadu-input>
                                <app-form-errors
                                  [formElement]="name"
                                  [control]="printSizeControl.get('name')"
                                ></app-form-errors>
                              </div>

                              <div class="ms-2">
                                <div class="d-flex gap-2 mb-2">
                                  <label
                                    class="p3 col-4 d-flex align-items-center"
                                  >
                                    Min area
                                  </label>
                                  <kadu-input
                                    formControlName="minArea"
                                    elClass="p3"
                                    #minArea
                                  ></kadu-input>
                                  <app-form-errors
                                    [formElement]="minArea"
                                    [control]="printSizeControl.get('minArea')"
                                    [Errors]="{
                                      pattern: 'Morate uneti broj'
                                    }"
                                  ></app-form-errors>
                                </div>

                                <div class="d-flex gap-2 mb-2">
                                  <label
                                    class="p3 col-4 d-flex align-items-center"
                                  >
                                    Max area
                                  </label>
                                  <kadu-input
                                    formControlName="maxArea"
                                    elClass="p3"
                                    #maxArea
                                  ></kadu-input>
                                  <app-form-errors
                                    [formElement]="maxArea"
                                    [control]="printSizeControl.get('maxArea')"
                                    [Errors]="{
                                      pattern: 'Morate uneti broj'
                                    }"
                                  ></app-form-errors>
                                </div>

                                <div class="d-flex gap-2 mb-2">
                                  <label
                                    class="p3 col-4 d-flex align-items-center"
                                  >
                                    Code
                                  </label>
                                  <kadu-input
                                    formControlName="code"
                                    elClass="p3"
                                    #code
                                  ></kadu-input>
                                  <app-form-errors
                                    [formElement]="code"
                                    [control]="printSizeControl.get('code')"
                                  ></app-form-errors>
                                </div>
                              </div>
                            </div>

                            <div formArrayName="colorData">
                              <div
                                *ngFor="
                                  let colorControl of printSizeControl.get(
                                    'colorData'
                                  ).controls;
                                  let colorDataIndex = index
                                "
                                [formGroupName]="colorDataIndex"
                              >
                                <label class="col-5 p3">
                                  {{ colorControl.value.name }}
                                </label>
                                <div
                                  class="d-flex gap-1 mb-2 align-items-center"
                                >
                                  <div>
                                    <kadu-input
                                      #colorControlBasePrice
                                      formControlName="basePrice"
                                      placeholder="Bazna cena"
                                    ></kadu-input>
                                    <app-form-errors
                                      [formElement]="colorControlBasePrice"
                                      [control]="colorControl.get('basePrice')"
                                      [Errors]="{
                                        pattern: 'Morate uneti broj'
                                      }"
                                    ></app-form-errors>
                                  </div>
                                  <div>
                                    <kadu-input
                                      #serviceCost
                                      formControlName="serviceCost"
                                      placeholder="Service cost"
                                    ></kadu-input>
                                    <app-form-errors
                                      [formElement]="serviceCost"
                                      [control]="
                                        colorControl.get('serviceCost')
                                      "
                                      [Errors]="{
                                        pattern: 'Morate uneti broj'
                                      }"
                                    ></app-form-errors>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-end mt-2">
                          <kadu-button
                            kaduTooltip="Dodaj veličinu printa"
                            (clickHandler)="addPrintSize(printTypeControl)"
                            class="btn btn-submit btn-small"
                          >
                            <i class="bi bi-plus-lg"></i>
                          </kadu-button>
                        </div>
                      </div>
                    </kadu-checkbox>
                  </div>
                </div>
              </div>

              <div class="col-4 border-end">
                <div formArrayName="sizeData" class="sizes mt-3">
                  <div
                    class="d-flex gap-1 mb-2 align-items-center"
                    *ngFor="
                      let control of formGroup
                        .get('productTypePartList')
                        .controls[i].get('sizeData').controls;
                      let sizeIndex = index
                    "
                    [formGroupName]="sizeIndex"
                  >
                    <label class="col-2 p3">{{ control.value.name }}</label>
                    <div>
                      <kadu-input
                        #printAreaWidthMM
                        formControlName="printAreaWidthMM"
                        placeholder="Print area width mm"
                      ></kadu-input>
                      <app-form-errors
                        [formElement]="printAreaWidthMM"
                        [control]="control.get('printAreaWidthMM')"
                        [Errors]="{
                          pattern: 'Morate uneti broj'
                        }"
                      ></app-form-errors>
                    </div>
                    <div>
                      <kadu-input
                        #printVerticalHeightMM
                        formControlName="printVerticalHeightMM"
                        placeholder="Print vertical height mm"
                      ></kadu-input>
                      <app-form-errors
                        [formElement]="printVerticalHeightMM"
                        [control]="control.get('printVerticalHeightMM')"
                        [Errors]="{
                          pattern: 'Morate uneti broj'
                        }"
                      ></app-form-errors>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div formArrayName="colorUrlData" class="colors">
                  <div
                    *ngFor="
                      let control of formGroup
                        .get('productTypePartList')
                        .controls[i].get('colorUrlData').controls;
                      let colorIndex = index
                    "
                    class="d-flex align-items-center justify-content-evenly mb-2"
                  >
                    <div [formGroupName]="colorIndex" class="col-8 mb-2">
                      <label>{{ control.value.name }}</label>
                      <kadu-file-upload
                        #productTypePartImageUrlUpload
                        [auto]="true"
                        formControlName="productTypePartImage"
                        [customFileTemplate]="productTypePartImageUrlTemplate"
                        chooseLabel="Dodaj url"
                        accept="image/png,image/jpeg,image/webp"
                      >
                        <ng-template #productTypePartImageUrlTemplate let-file>
                          <ng-container
                            *ngTemplateOutlet="
                              fileTemplate;
                              context: {
                                $implicit: file,
                                fileUpload: productTypePartImageUrlUpload
                              }
                            "
                          ></ng-container>
                        </ng-template>
                      </kadu-file-upload>
                      <app-form-errors
                        [formElement]="productTypePartImageUrlUpload"
                        [control]="control.get('productTypePartImage')"
                      ></app-form-errors>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="d-flex justify-content-end mt-2">
          <kadu-button
            kaduTooltip="Dodaj stranu proizvoda"
            (clickHandler)="addProductTypePart()"
            class="btn btn-submit btn-small"
          >
            <i class="bi bi-plus-lg"></i>
          </kadu-button>
        </div>

        <hr />
        <div class="d-flex justify-content-end mt-2 gap-2">
          <kadu-button
            (clickHandler)="close()"
            class="btn btn-cancel btn-small"
          >
            Odustani
          </kadu-button>
          <kadu-button type="submit" class="btn btn-submit btn-small">
            Kreiraj tip proizvoda
          </kadu-button>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>
</main>

<ng-template #fileTemplate let-file let-fileUpload="fileUpload">
  <div class="p-2">
    <div class="d-flex gap-2 align-items-baseline justify-content-between">
      <p class="p3 file-label">{{ file.name }}</p>
      <i
        (click)="fileUpload.remove($event)"
        class="bi bi-x-lg cursor-pointer"
      ></i>
    </div>

    <img [src]="file.objectURL" [alt]="file.name" class="w-100" />
  </div>
</ng-template>
