import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginatedResult } from "@kadung/kadung/kadu-table";
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from "src/app/_interceptors/skip-interceptors";
import { convertToFormData } from "src/app/_models/util";
import { StockCheckup } from "src/app/_view-models/stock-checkup";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StockCheckupService {
  baseUrl = "backoffice/stock_checkups";
  constructor(private http: HttpClient) {}

  getAll(params: any): Observable<PaginatedResult<StockCheckup>> {
    return this.http.get<PaginatedResult<StockCheckup>>(`${this.baseUrl}`, {
      params,
    });
  }

  create(stockCheckup: StockCheckup): Observable<StockCheckup> {
    return this.http.post<StockCheckup>(
      `${this.baseUrl}`,
      convertToFormData(stockCheckup, ["file"]),
      {
        headers: new HttpHeaders().set(
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
          SKIP_DTO_TRANSFORM_INTERCEPTOR
        ),
      }
    );
  }

  void(stockCheckupId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${stockCheckupId}`);
  }
}
