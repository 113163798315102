<kadu-button
  (clickHandler)="openDialog()"
  class="btn btn-submit btn-small mb-2"
>
  Dodaj novu boju
</kadu-button>
<kadu-table
  [columns]="cols"
  [request]="colors$"
  [lazy]="true"
  [rows]="20"
  [loading]="false"
></kadu-table>

<ng-template #colorPreview let-color>
  <kadu-color-picker
    [isDisabled]="true"
    [Value]="{ r: color.red, g: color.green, b: color.blue }"
  ></kadu-color-picker>
</ng-template>

<ng-template #actionsTemplate let-color>
  <div class="d-flex justify-content-center gap-2">
    <span class="cursor-pointer">
      <i
        (click)="openDialog(color)"
        class="btn btn-submit btn-small bi bi-pencil p3"
      >
      </i>
    </span>
    <span class="cursor-pointer">
      <i
        (click)="deleteColor(color)"
        class="btn btn-cancel btn-small bi bi-trash p3"
      >
      </i>
    </span>
  </div>
</ng-template>
