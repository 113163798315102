import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-raw-material-single",
  templateUrl: "./raw-material-single.component.html",
  styleUrls: ["./raw-material-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RawMaterialSingleComponent {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  rawMaterial = this.config.data.rawMaterial;

  formControls = {
    name: { state: null, validators: [Validators.required] },
    consumptionPerOrder: { state: null, validators: [Validators.required] },
  };

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngAfterViewInit(): void {
    if (this.rawMaterial) {
      this.formComponent.formGroup.patchValue(this.rawMaterial);
    }
  }

  close(): void {
    this.ref.close(false);
  }

  save(formValue: any): void {
    if (this.rawMaterial) {
      this.ref.close({
        editMode: true,
        data: { ...formValue, id: this.rawMaterial.id },
      });
    } else {
      this.ref.close({ editMode: false, data: formValue });
    }
  }
}
