import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/_interceptors/loading-spinner.interceptor";
import { PaginatedResult } from "../../_models/paginated-result";
import { Designer } from "../../_view-models/designer";

@Injectable({
  providedIn: "root",
})
export class DesignerService {
  baseUrl = "backoffice/users";
  constructor(private http: HttpClient) {}

  getAll(params: any): Observable<PaginatedResult<Designer>> {
    return this.http.get<PaginatedResult<Designer>>(`${this.baseUrl}`, {
      params,
      headers: new HttpHeaders().set(
        SKIP_LOADING_INTERCEPTOR,
        SKIP_LOADING_INTERCEPTOR
      ),
    });
  }

  generateInvoice(id): Observable<any> {
    return this.http.post(`${this.baseUrl}/${id}/invoice`, {});
  }

  banDesigner(designerId: string, banReason: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/${designerId}/ban_user`, {
      banReason,
    });
  }

  unbanDesigner(designerId: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/${designerId}/unban_user`, {});
  }
}
