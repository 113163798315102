import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-print-type-single",
  templateUrl: "./print-type-single.component.html",
  styleUrls: ["./print-type-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintTypeSingleComponent {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  printType = this.config.data.printType;

  formControls = {
    name: { state: null, validators: [Validators.required] },
    code: { state: null, validators: [Validators.required] },
  };

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngAfterViewInit(): void {
    if (this.printType) {
      this.formComponent.formGroup.patchValue(this.printType);
    }
  }

  close(): void {
    this.ref.close(false);
  }

  save(formValue: any): void {
    if (this.printType) {
      this.ref.close({
        editMode: true,
        data: { ...formValue, id: this.printType.id },
      });
    } else {
      this.ref.close({ editMode: false, data: formValue });
    }
  }
}
