import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { PurchaseOrderService } from 'src/app/_services/purchase-order/purchase-order.service';
import { PopupService } from 'src/app/components/popup/popup.service';

@Component({
  selector: 'app-purchase-order-single',
  templateUrl: './purchase-order-single.component.html',
  styleUrls: ['./purchase-order-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseOrderSingleComponent {

  formControls = {
    startTime: { state: new Date(), validators: [Validators.required] },
    expectedCompletionTime: {state: '', validators: [Validators.required]},
    file: {state: null, validators: [Validators.required]},
    note: {state: '', validators: [Validators.required]},
    stockItemType: {state: null, validators: [Validators.required]}
  };

  stockItemTypeOptions = [
    {
      label: "Roba",
      value: "PRODUCT_TYPE_ITEM",
    },
    {
      label: "Repromaterijal",
      value: "RAW_MATERIAL",
    },
  ];

  constructor(private ref: DynamicDialogRef, private purchaseOrderService: PurchaseOrderService, private popupService: PopupService) { }

  create(formValue): void {
    this.purchaseOrderService.create(formValue).subscribe(res => {
      this.popupService.fireToast({
          title: "Uspešno dodata porudžbenica",
          type: "success",
      });
      this.ref.close({sucess:true});
    }, (error) => {
      this.popupService.fire({
        title: "Greška prilikom dodavanja porudžbenice",
        type: "error",
        text: error.error.message
      });
    });
  }
}
