import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginatedResult } from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StockStateService {
  baseUrl = "backoffice/stock_states";

  constructor(private http: HttpClient) {}

  getAll(params: any): Observable<PaginatedResult<any[]>> {
    return this.http.get<PaginatedResult<any[]>>(`${this.baseUrl}`, {
      params,
    });
  }

  deleteStockState(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  editStockState(stockState: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/edit/${stockState.id}`, stockState);
  }

  addStockState(stockState: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/`, stockState);
  }
}
