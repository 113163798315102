import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Observable } from 'rxjs';

export const SkipEmptyStringAsNullInterceptor = 'SkipEmptyStringAsNull';
export const EmptyStringAsNullWhitelist = 'EmptyStringAsNullWhitelist';

@Injectable()
export class EmptyStringAsNullInterceptor implements HttpInterceptor {
  emptyStringAsNullWhitelist: string[] = [];

  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.headers.get(SkipEmptyStringAsNullInterceptor) === '') {
      return next.handle(req);
    }

    if(req.headers.get(EmptyStringAsNullWhitelist)) {
      this.emptyStringAsNullWhitelist = req.headers.get(EmptyStringAsNullWhitelist).split(',');
    }

    req = req.clone({
      body: this.convertEmptyStringsToNull(req.body, '')
    })

    return next.handle(req);
  }

  convertEmptyStringsToNull(object: any, prefix: string) {
    if (!object) return object;
    for (const key in object) {
      if(this.emptyStringAsNullWhitelist.includes(prefix + '.' + key)) return object;
      if(object[key] === '') {
        object[key] = null;
      }
      if(typeof object[key] === 'object' && !Array.isArray(object[key])) {
        object[key] = this.convertEmptyStringsToNull(object[key], key);
      }

      if(Array.isArray(object[key])) {
        for (let index = 0; index < object[key].length; index++) {
          object[key][index] = this.convertEmptyStringsToNull(object[key][index], `${key}.${index}`);
        }
      }
    }
    return object;
  }


}
