import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { SKIP_INTERCEPTORS } from "./skip-interceptors";
import { LoaderService } from "../_services/loader/loader.service";

export const SKIP_LOADING_INTERCEPTOR = "SKIP_LOADING_INTERCEPTOR";

@Injectable({
  providedIn: "root",
})
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  finished: boolean;
  constructor(private loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
      return next.handle(req);
    }
    if (
      req.headers.get(SKIP_LOADING_INTERCEPTOR) === SKIP_LOADING_INTERCEPTOR
    ) {
      return next.handle(req);
    }

    this.finished = false;
    setTimeout(() => {
      if (!this.finished) {
        this.loaderService.show();
      }
    }, 800);
    return next.handle(req).pipe(
      finalize(() => {
        this.finished = true;
        this.loaderService.hide();
      })
    );
  }
}
