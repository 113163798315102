<main class="d-flex flex-column pb-3 align-items-center">
  <div
    class="d-flex flex-column gap-1 align-items-center mb-2 py-2 border-bottom"
  >
    <label class="p2">Veličina</label>
    <kadu-select
      elClass="w-100"
      [SelectedValue]="orderItem.productSizeId"
      [options]="orderItemInfo[orderItem.id]?.sizes"
      #sizeSelect
    >
    </kadu-select>
    <kadu-button
      class="btn btn-small btn-submit w-100"
      (clickHandler)="changeSize(sizeSelect.SelectedValue)"
    >
      Zameni veličinu
    </kadu-button>
  </div>
  <div
    class="d-flex flex-column gap-1 align-items-center mb-2 py-2 border-bottom"
  >
    <label class="p2">Boja</label>
    <kadu-select
      elClass="w-100"
      [SelectedValue]="orderItem.productColorId"
      [options]="orderItemInfo[orderItem.id]?.colors"
      #colorSelect
    >
    </kadu-select>
    <kadu-button
      class="btn btn-small btn-submit w-100"
      (clickHandler)="changeColor(colorSelect.SelectedValue)"
    >
      Zameni boju
    </kadu-button>
  </div>

  <div class="d-flex gap-2 justify-content-center">
    <kadu-button
      *ngIf="orderItem.isManufactured"
      class="btn btn-small btn-submit"
      (clickHandler)="repeatManufacturingOrderItem()"
    >
      Ponovi izradu
    </kadu-button>
    <kadu-button
      class="btn btn-small btn-delete"
      (clickHandler)="deleteOrderItem()"
    >
      Obriši
    </kadu-button>
    <kadu-button
      class="btn btn-small btn-submit"
      (clickHandler)="editOrderItemDetails()"
    >
      Izmeni podatke
    </kadu-button>
  </div>
</main>
