import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-color-single",
  templateUrl: "./color-single.component.html",
  styleUrls: ["./color-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorSingleComponent {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  color = this.config.data.color;

  formControls = {
    name: { state: null, validators: [Validators.required] },
    red: {
      state: null,
      validators: [
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        Validators.max(255),
      ],
    },
    blue: {
      state: null,
      validators: [
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        Validators.max(255),
      ],
    },
    alpha: {
      state: null,
      validators: [
        Validators.required,
        Validators.pattern("/^d+(.d+)?$/"),
        Validators.min(0),
        Validators.max(1),
      ],
    },
    green: {
      state: null,
      validators: [
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        Validators.max(255),
      ],
    },
    skuCode: { state: null, validators: [Validators.required] },
  };

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngAfterViewInit(): void {
    if (this.color) {
      this.formComponent.formGroup.patchValue(this.color);
    }
  }

  close(): void {
    this.ref.close(false);
  }

  save(formValue: any): void {
    if (this.color) {
      this.ref.close({
        editMode: true,
        data: { ...formValue, id: this.color.id },
      });
    } else {
      this.ref.close({ editMode: false, data: formValue });
    }
  }
}
