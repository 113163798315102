import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { KaduTableComponent, PaginatedResult } from "@kadung/kadung/kadu-table";
import { TableFilter } from "src/app/_models/table-filter";
import { StockCheckup } from "src/app/_view-models/stock-checkup";
import { Observable } from "rxjs";
import { StockCheckupService } from "src/app/_services/stock-checkup/stock-checkup.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { SubSink } from "subsink";
import {
  generateTableRequestParams,
  prepareCurrentFilters,
} from "src/app/_models/table-util";
import { StockCheckupSingleComponent } from "../stock-checkup-single/stock-checkup-single.component";

@Component({
  selector: "app-stock-checkup-list",
  templateUrl: "./stock-checkup-list.component.html",
  styleUrls: ["./stock-checkup-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockCheckupListComponent implements OnInit {
  @ViewChild("buttonsTemplate", { static: true })
  buttonsTemplate: TemplateRef<any>;
  @ViewChild("table") table: KaduTableComponent;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: -1,
    sortField: "startTime",
    filters: {},
  };

  cols: any[];

  filtersMeta = {
    id: { key: "id", matchMode: "eq:" },
    created: { key: "created", matchMode: "btn:" },
    stockItemType: { key: "stock_item_type", matchMode: "in:" },
    startTime: { key: "start_time", matchMode: "btn:" },
  };

  stockCheckupsRequest = ((
    filters: TableFilter
  ): Observable<PaginatedResult<StockCheckup>> => {
    this.currentFilters = prepareCurrentFilters(this.currentFilters, filters);

    return this.stockCheckupService.getAll(
      generateTableRequestParams(this.currentFilters, this.filtersMeta)
    );
  }).bind(this);

  subs = new SubSink();

  constructor(
    private stockCheckupService: StockCheckupService,
    private dialogService: DialogService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "ID",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Datum unosa",
        field: "created",
        filterType: "date",
        type: "date",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "Datum početka popisa",
        field: "startTime",
        filterType: "date",
        type: "date",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "Excel",
        field: "fileUrl",
        type: "file",
        sortDisabled: true,
        options: {
          icon: "fa fa-download",
        },
      },
      {
        header: "Tip",
        field: "stockItemType",
        filterType: "select",
        type: "normal",
        options: {
          optionValues: [
            { value: "PRODUCT_TYPE_ITEM", label: "Roba" },
            { value: "RAW_MATERIAL", label: "Repromaterijal" },
          ],
        },
      },
      {
        header: "Akcije",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.buttonsTemplate,
        },
      },
    ];
  }

  setCurrentFilters(event): any {
    this.currentFilters = event;
  }

  createStockCheckup(): any {
    const dialog = this.dialogService.open(StockCheckupSingleComponent, {
      width: "70%",
      height: "90%",
    });

    this.subs.add(
      dialog.onClose.subscribe((res) => {
        if (res) {
          this.table.load(this.currentFilters);
        }
      })
    );
  }

  voidStockCheckup(stockCheckup: StockCheckup): any {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da stornirate popis ${stockCheckup.id}?`,
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.stockCheckupService.void(stockCheckup.id).subscribe(
            () => {
              this.popupService.fireToast({
                title: `Uspešno obrisan popis ${stockCheckup.id}`,
              });
              this.table.load(this.currentFilters);
            },
            (error) => {
              this.popupService.fire({
                title: `Došlo je do greške`,
                type: "error",
                text: error.error.message,
              });
            }
          );
        }
      });
  }
}
