import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
  TemplateRef,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { KaduTableComponent } from "@kadung/kadung/kadu-table";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import {
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
  skip,
} from "rxjs/operators";
import { PaginatedResult } from "src/app/_models/paginated-result";
import { TableColumn } from "src/app/_models/table-column";
import { TableFilter } from "src/app/_models/table-filter";
import { AddressService } from "src/app/_services/address/address.service";
import { ColorService } from "src/app/_services/color/color.service";
import { CommentService } from "src/app/_services/comment/comment.service";
import { OrderPartnerService } from "src/app/_services/order-partner/order-partner.service";
import { OrderStateTransitionService } from "src/app/_services/order-state-transition/order-state-transition.service";
import { OrderService } from "src/app/_services/order/order.service";
import { PartnerService } from "src/app/_services/partner/partner.service";
import { Order } from "src/app/_view-models/order";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { JsonViewComponent } from "src/app/components/json-view/json-view.component";
import { PopupService } from "src/app/components/popup/popup.service";
import { SubSink } from "subsink";
import { OrderItemSingleComponent } from "../order-item-single/order-item-single.component";
import { OrderSingleComponent } from "../order-single/order-single.component";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { EditColumn } from "src/app/_models/edit-colum.enum";
import { ChangeOrderItemComponent } from "./_components/change-order-item/change-order-item.component";
import { ShopService } from "src/app/_services/shop/shop.service";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OrdersComponent implements AfterViewInit, OnDestroy {
  @ViewChild("table") table: KaduTableComponent;
  @ViewChild("commentForm") commentForm: KaduFormComponent;
  @ViewChild("filtersForm", { static: false }) filtersForm: KaduFormComponent;
  @ViewChild("stateDefaultTemplate", { static: false })
  stateDefaultTemplate: TemplateRef<any>;
  @ViewChild("orderActionsTemplate", { static: true })
  orderActionsTemplate: TemplateRef<any>;
  @ViewChild("orderItemListActionTemplate", { static: true })
  orderItemListActionTemplate: TemplateRef<any>;

  type: string;
  totalResults$ = new BehaviorSubject<number>(0);
  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  additionalFilters = {};
  initialFilters = this.route.snapshot.params.type
    ? { state: { key: "state", value: ["PENDING"], matchMode: "in:" } }
    : {
        state: { key: "state", value: ["SHIPPING"], matchMode: "in:" },
      };

  editCommentIds = {};
  customLoader = false;

  partners = new BehaviorSubject<any[]>([]);

  filterFormControls = {
    email: { state: "" },
    phoneNumber: { state: "" },
    manufacturingDate: { state: "" },
    partnerId: { state: [] },
  };

  orderItemListCols: TableColumn[];

  orderPriorityDeliveryCols: TableColumn[] = [
    {
      header: "Ime proizvoda",
      field: "productName",
      filterType: "string",
      type: "normal",
    },
    {
      header: "Veličina",
      field: "sizeName",
      filterType: "string",
      type: "normal",
    },
    {
      header: "Boja",
      field: "colorName",
      filterType: "string",
      type: "normal",
    },
    {
      header: "Višak",
      field: "overhead",
      filterType: "number",
      type: "normal",
    },
  ];

  commentFormControls = {
    author: { state: "", validators: Validators.required },
    value: { state: "", validators: Validators.required },
  };

  addressFilterValue$ = new Subject<{ filter: string; order: Order }>();
  cityFilterValue$ = new Subject<{ filter: string; order: Order }>();

  cols;

  otherOrdersCols: TableColumn[] = [
    {
      header: "OrderID",
      field: "id",
      filterType: "string",
      type: "normal",
    },
    {
      header: "Datum",
      field: "created",
      filterType: "date",
      type: "date",
      options: {
        format: "dd.MM.yyyy",
      },
    },
    {
      header: "State",
      field: "state",
      filterType: "select",
      type: "normal",
      options: {
        optionValues: [
          { value: "PENDING", label: "PENDING" },
          { value: "ACCEPTED", label: "ACCEPTED" },
          { value: "REJECTED", label: "REJECTED" },
          { value: "MANUFACTURING", label: "MANUFACTURING" },
          { value: "READY_FOR_SHIPPING", label: "READY_FOR_SHIPPING" },
          { value: "SHIPPING", label: "SHIPPING" },
          { value: "DELIVERED", label: "DELIVERED" },
          { value: "NOT_DELIVERED", label: "NOT_DELIVERED" },
          { value: "CHARGED", label: "CHARGED" },
        ],
      },
    },
  ];

  otherOrdersFiltersMeta = {
    id: { key: "id", matchMode: "neq:" },
    created: { key: "created", matchMode: "btn:" },
    state: { key: "state", matchMode: "in:" },
    phoneNumber: { key: "phoneNumber", matchMode: "like:" },
  };

  orderStateTransitionsCols: TableColumn[] = [
    {
      header: "Datum",
      field: "timestamp",
      filterType: "date",
      type: "date",
      options: {
        format: "dd.MM.yyyy HH:mm",
      },
    },
    {
      header: "Staro stanje",
      field: "oldState",
      filterType: "select",
      type: "normal",
      options: {
        optionValues: [
          { value: "PENDING", label: "PENDING" },
          { value: "ACCEPTED", label: "ACCEPTED" },
          { value: "REJECTED", label: "REJECTED" },
          { value: "MANUFACTURING", label: "MANUFACTURING" },
          { value: "READY_FOR_SHIPPING", label: "READY_FOR_SHIPPING" },
          { value: "SHIPPING", label: "SHIPPING" },
          { value: "DELIVERED", label: "DELIVERED" },
          { value: "NOT_DELIVERED", label: "NOT_DELIVERED" },
          { value: "CHARGED", label: "CHARGED" },
        ],
      },
    },
    {
      header: "Novo stanje",
      field: "newState",
      filterType: "select",
      type: "normal",
      options: {
        optionValues: [
          { value: "PENDING", label: "PENDING" },
          { value: "ACCEPTED", label: "ACCEPTED" },
          { value: "REJECTED", label: "REJECTED" },
          { value: "MANUFACTURING", label: "MANUFACTURING" },
          { value: "READY_FOR_SHIPPING", label: "READY_FOR_SHIPPING" },
          { value: "SHIPPING", label: "SHIPPING" },
          { value: "DELIVERED", label: "DELIVERED" },
          { value: "NOT_DELIVERED", label: "NOT_DELIVERED" },
          { value: "CHARGED", label: "CHARGED" },
        ],
      },
    },
  ];

  filtersMeta = {
    id: { key: "id", matchMode: "eq:" },
    created: { key: "created", matchMode: "btn:" },
    firstName: { key: "firstName", matchMode: "like:" },
    lastName: { key: "lastName", matchMode: "like:" },
    email: { key: "email", matchMode: "like:" },
    partnerId: { key: "partnerId", matchMode: "in:" },
    phoneNumber: { key: "phoneNumber", matchMode: "like:" },
    manufacturingDate: { key: "manufacturingDate", matchMode: "eq:" },
    state: { key: "state", matchMode: "in:" },
    city: { key: "city", matchMode: "like:" },
    address: { key: "address", matchMode: "like:" },
    postalCode: { key: "postalCode", matchMode: "like:" },
    shopName: { key: "shopName", matchMode: "like:" },
    deliveryCode: { key: "deliveryCode", matchMode: "like:" },
    fulfillmentType: { key: "fulfillmentType", matchMode: "in:" },
  };

  fulfillmentTypes$: Observable<any> = this.shopService
    .getOrderFulfillmentTypes()
    .pipe(
      map((fulfillmentTypes) => {
        return fulfillmentTypes.map((fulfillmentType) => {
          return {
            value: fulfillmentType,
            label: fulfillmentType,
          };
        });
      })
    );

  orderRequest = ((
    filters: TableFilter
  ): Observable<PaginatedResult<Order>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...this.initialFilters,
        ...filters.filters,
        ...this.additionalFilters,
      },
    };

    return this.orderService
      .getAll(generateTableRequestParams(this.currentFilters, this.filtersMeta))
      .pipe(
        map((pr: PaginatedResult<Order>) => {
          this.totalResults$.next(pr.totalResults);
          this.cdr.markForCheck();

          return {
            ...pr,
            currentResults: pr.currentResults
              ? pr.currentResults.map((o: any) => {
                  return {
                    ...o,
                    fullPrice: o.price + o.buyerShippingCost,
                    fullNumber:
                      o.number +
                      (o.subNumber ? o.subNumber : "") +
                      (o.floor ? "/" + o.floor : "") +
                      (o.apartment ? "/" + o.apartment : ""),
                    _meta: {
                      address: {
                        editable: o.shippingCarrierCode === "BEX",
                        class:
                          o.isAddressDataValid === false &&
                          o.shippingCarrierCode === "BEX"
                            ? "bg-orange"
                            : "",
                      },
                      city: {
                        editable: o.shippingCarrierCode === "GLS",
                        class:
                          o.isAddressDataValid === false &&
                          o.shippingCarrierCode === "GLS"
                            ? "bg-orange"
                            : "",
                      },
                    },
                    meta: {
                      editState: this.editingOrders.find(
                        (editingOrder) => editingOrder.id === o.id
                      )
                        ? EditColumn.EDITING
                        : EditColumn.NONE,
                      showDesign: false,
                      rowClass: o.lastOrderId !== "-1" ? "bg-red" : "",
                      expressDeliveryUnavailable:
                        o.priorityDeliveryAvailable === false
                          ? "bg-warning"
                          : "",
                    },
                    orderItemList: o.orderItemList.map((oi) => {
                      return {
                        ...oi,
                        meta: {
                          rowClass: oi.fromManufacturedProductId
                            ? "bg-success"
                            : "",
                        },
                        change: false,
                      };
                    }),
                  };
                })
              : [],
          };
        }),
        tap((orders) => {
          this.expandedOrders.forEach((order) => {
            this.expandRow(order);
          });

          this.customLoader = false;
        })
      );
  }).bind(this);

  colors$ = this.colorService.getAll().pipe(
    map((colors) => {
      return colors.map((c) => {
        return {
          value: c["name"],
          label: c["name"],
        };
      });
    })
  );

  orderItemInfo = {};
  editingOrder$ = new BehaviorSubject<Order>(null);
  subs = new SubSink();
  constructor(
    private orderService: OrderService,
    private orderPartnerService: OrderPartnerService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private colorService: ColorService,
    private commentService: CommentService,
    private partnerService: PartnerService,
    private popupService: PopupService,
    private orderStateTransitionService: OrderStateTransitionService,
    private addressService: AddressService,
    private shopService: ShopService
  ) {}

  selectedOrdersIdList = [];
  selectedOrders = [];
  expandedOrders = [];
  editingOrders = [];

  editAddressDetails(event: any): any {
    if (!event.originalEvent.value) return;
    this.customLoader = true;
    event.item.meta.editState = EditColumn.LOADING;

    switch (event.item.shippingCarrierCode) {
      case "BEX":
        if (
          event.originalEvent.value.street &&
          event.originalEvent.value.place &&
          event.originalEvent.value.postalCode
        ) {
          this.orderService
            .editDetails(event.item, {
              ...event.item,
              address: event.originalEvent.value.street,
              city: event.originalEvent.value.place,
              postalCode: event.originalEvent.value.postalCode,
            })
            .subscribe((res: any) => {
              event.item.address = res.address;
              event.item.city = res.city;
              event.item.postalCode = res.postalCode;
              event.item.isAddressDataValid = true;
              event.item._meta.address.class = "";
              this.removeEditingOrder(event.item.id);

              this.table.load(this.currentFilters);
            });

          break;
        }

      case "GLS":
        if (
          event.originalEvent.value.place &&
          event.originalEvent.value.postalCode
        ) {
          this.orderService
            .editDetails(event.item, {
              ...event.item,
              city: event.originalEvent.value.place,
              postalCode: event.originalEvent.value.postalCode,
            })
            .subscribe((res: any) => {
              event.item.city = res.city;
              event.item.postalCode = res.postalCode;
              event.item.isAddressDataValid = true;
              event.item._meta.address.class = "";
              this.removeEditingOrder(event.item.id);

              this.table.load(this.currentFilters);
            });
          break;
        }

      default:
        break;
    }
  }

  setEditingOrders(event: any) {
    event.data.meta.editState = EditColumn.EDITING;
    this.editingOrders = [...this.editingOrders, event.data];
  }

  removeEditingOrder(id: number) {
    this.editingOrders = [
      ...this.editingOrders.filter((editingOrder) => editingOrder.id !== id),
    ];

    if (this.editingOrders.length === 0) {
      this.table.cancelEditMode();
    }
  }

  getInputSettings(order: Order, filter: string): any {
    switch (order.shippingCarrierCode) {
      case "BEX":
        return this.addressService
          .filterAddresses(filter, order.shippingCarrierCode)
          .pipe(
            map((streets) =>
              streets.map((address: any) => ({
                label: address.address,
                value: {
                  place: address.placeName,
                  street: address.streetName,
                  postalCode: address.postalCode,
                },
              }))
            )
          );

      case "GLS":
        return this.addressService
          .filterAddresses(filter, order.shippingCarrierCode)
          .pipe(
            map((streets) =>
              streets.map((address: any) => ({
                label: address.address,
                value: {
                  place: address.city,
                  postalCode: address.postalCode,
                  zone: address.zone,
                },
              }))
            )
          );

      default:
        return of([]);
    }
  }

  bulkAccept() {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da prihvatite porudžbine?",
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.orderService.bulkAccept(this.selectedOrdersIdList).subscribe(
            () => {
              this.popupService.fire({
                title: "Uspešno prihvaćena porudžbina",
                type: "success",
              });
              this.selectedOrdersIdList = [];
              this.selectedOrders = [];
              this.table.load(this.currentFilters);
            },
            (err) => {
              this.popupService.fire({
                title: "Greška",
                text: err.error.message,
                type: "error",
              });
              this.selectedOrdersIdList = [];
              this.selectedOrders = [];
            }
          );
        }
      });
  }

  bulkReject() {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da odbijete porudžbine?",
        text: "Unesi razlog odbijanja",
        type: "warning",
        input: "text",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.orderService
            .bulkReject(this.selectedOrdersIdList, res.value)
            .subscribe(
              (res) => {
                this.popupService.fire({
                  title: "Uspešno odbijena porudžbina",
                  type: "success",
                });
                this.selectedOrdersIdList = [];
                this.selectedOrders = [];
                this.table.load(this.currentFilters);
              },
              (err) => {
                this.popupService.fire({
                  title: "Greška",
                  text: err.error.message,
                  type: "error",
                });
                this.selectedOrdersIdList = [];
                this.selectedOrders = [];
              }
            );
        }
      });
  }

  getSelectedOrders(orders: any) {
    this.selectedOrders = orders;
    this.selectedOrdersIdList = orders.map((order) => order.id);
  }

  ngOnInit() {
    this.cols = [
      {
        header: "ID",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Datum",
        field: "created",
        filterType: "date",
        type: "date",
        options: {
          format: "dd.MM.yyyy",
        },
      },
      {
        header: "Ime",
        field: "firstName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Prezime",
        field: "lastName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Adresa",
        field: "address",
        filterType: "string",
        sortDisabled: true,
        type: "normal",
        inputType: "select",
        width: "10%",
        options: {
          inputOptions: this.addressFilterValue$.pipe(
            filter(
              (filterValue) =>
                filterValue.filter && filterValue.filter.length >= 3
            ),
            debounceTime(600),
            switchMap(({ filter, order }) =>
              this.getInputSettings(order, filter)
            )
          ),
          onShow: ((event) => {
            this.addressFilterValue$.next({
              filter: event.item.address,
              order: event.item,
            });
          }).bind(this),
          onFilter: ((event) => {
            this.addressFilterValue$.next({
              filter: event.event.filter,
              order: event.item,
            });
          }).bind(this),
          onConfirm: ((event) => {
            this.editAddressDetails(event);
          }).bind(this),
          onCancel: ((event) => {
            event.item.meta.editState = EditColumn.NONE;
            this.removeEditingOrder(event.item.id);
          }).bind(this),
        },
      },
      {
        header: "Grad",
        field: "city",
        filterType: "string",
        type: "normal",
        inputType: "select",
        options: {
          inputOptions: this.addressFilterValue$.pipe(
            filter(
              (filterValue) =>
                filterValue.filter && filterValue.filter.length >= 3
            ),
            debounceTime(600),
            switchMap(({ filter, order }) =>
              this.getInputSettings(order, filter)
            )
          ),
          onShow: ((event) => {
            this.addressFilterValue$.next({
              filter: event.item.address,
              order: event.item,
            });
          }).bind(this),
          onFilter: ((event) => {
            this.addressFilterValue$.next({
              filter: event.event.filter,
              order: event.item,
            });
          }).bind(this),
          onConfirm: ((event) => {
            this.editAddressDetails(event);
          }).bind(this),
          onCancel: ((event) => {
            event.item.meta.editState = EditColumn.NONE;
            this.removeEditingOrder(event.item.id);
          }).bind(this),
        },
      },
      {
        header: "Broj",
        field: "fullNumber",
        filterType: "string",
        sortDisabled: true,
        type: "normal",
      },
      {
        header: "Post. Br.",
        field: "postalCode",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Delivery code",
        field: "deliveryCode",
        filterType: "string",
        sortDisabled: true,
        type: "normal",
      },
      {
        header: "Prodavnica",
        field: "shopName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Tip isporuke",
        field: "fulfillmentType",
        filterType: "select",
        type: "normal",
        options: {
          optionValues: this.fulfillmentTypes$,
          lazy: true,
        },
      },
      {
        header: "State",
        field: "state",
        filterType: "custom",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "custom",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.orderActionsTemplate,
        },
      },
    ];

    this.orderItemListCols = [
      {
        header: "Id",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Proizvod",
        field: "productName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Tip proizvoda",
        field: "productTypeName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Veličina",
        field: "productSize",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Boja",
        field: "productColor",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Cena",
        field: "productPrice",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Izrađen",
        field: "isManufactured",
        filterType: "string",
        type: "normal",
      },
      {
        header: "MP Id",
        field: "fromManufacturedProductId",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Stanje na zalihama",
        field: "stockAmount",
        filterType: "string",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "custom",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.orderItemListActionTemplate,
        },
      },
    ];

    this.partnerService
      .getAll()
      .subscribe((partners) => this.partners.next(partners));

    this.setInitialData();
  }

  mapValues(order: any): any {
    return order.orderItemList.reduce(
      (acc, orderItem) => ({ ...acc, [orderItem.id]: true }),
      {}
    );
  }

  ngAfterViewInit() {
    this.filtersForm.formGroup.valueChanges
      .pipe(debounceTime(600))
      .subscribe((values) => {
        this.additionalFilters = {};

        Object.keys(values).forEach((key) => {
          if (
            values[key] instanceof Array ? values[key].length > 0 : values[key]
          ) {
            this.additionalFilters[this.filtersMeta[key].key] = {
              key: this.filtersMeta[key].key,
              value: values[key],
              matchMode: this.filtersMeta[key].matchMode,
            };
          } else {
            this.additionalFilters[this.filtersMeta[key].key] = undefined;
          }
        });

        this.table.load(this.currentFilters);
      });

    this.route.paramMap.pipe(skip(1)).subscribe((res) => {
      this.setInitialData();
      this.table.dt.first = 0;
      this.table.load(this.currentFilters);
    });
  }

  setInitialData(): void {
    this.selectedOrders = [];
    this.selectedOrdersIdList = [];

    if (this.currentFilters.filters["state"]) {
      delete this.currentFilters.filters["state"];
    }
    this.type = this.route.snapshot.params.type;

    if (this.type === "accept" || this.type === "finish") {
      this.cols[this.cols.length - 2] = {
        header: "State",
        field: "state",
        filterType: "custom",
        type: "normal",
        sortDisabled: true,
        width: "8%",
        options: {
          filtersTemplate: this.stateDefaultTemplate,
        },
      };

      this.initialFilters =
        this.type === "accept"
          ? { state: { key: "state", value: ["PENDING"], matchMode: "in:" } }
          : {
              state: { key: "state", value: ["SHIPPING"], matchMode: "in:" },
            };
    } else {
      this.cols[this.cols.length - 2] = {
        header: "State",
        field: "state",
        filterType: "select",
        type: "normal",
        width: "8%",
        options: {
          optionValues: [
            { value: "PENDING", label: "PENDING" },
            { value: "ACCEPTED", label: "ACCEPTED" },
            { value: "REJECTED", label: "REJECTED" },
            { value: "MANUFACTURING", label: "MANUFACTURING" },
            { value: "READY_FOR_SHIPPING", label: "READY_FOR_SHIPPING" },
            { value: "SHIPPING", label: "SHIPPING" },
            { value: "DELIVERED", label: "DELIVERED" },
            { value: "NOT_DELIVERED", label: "NOT_DELIVERED" },
            { value: "CHARGED", label: "CHARGED" },
          ],
        },
      };
      delete this.initialFilters["state"];
    }

    this.currentFilters = {
      ...this.currentFilters,
      first: 0,
      filters: {
        ...this.additionalFilters,
        ...this.currentFilters.filters,
      },
    };
  }

  acceptOrder(order: Order) {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da prihvatite porudžbinu ${order.id}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.editingOrder$.next(order);
          this.orderService.acceptOrder(order).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno prihvaćena porudžbina",
                type: "success",
              });
              if (this.type === "accept") {
                this.expandedOrders = this.expandedOrders.filter(
                  (o) => o.data.id !== order.id
                );
              }
              this.table.load(this.currentFilters);
            },
            (err) => {
              if (err.error.exceptionType === "USER_BANNED") {
                this.popupService.fire({
                  title: "Dizajner banovan",
                  text: "Nije moguće prihvatiti porudžbinu",
                  type: "error",
                });

                return;
              }

              this.popupService.fire({
                title: "Greška",
                text: err.error.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  rejectOrder(order: Order) {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da odbijete porudžbinu ${order.id}?`,
        text: "Unesi razlog odbijanja",
        type: "warning",
        input: "text",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.editingOrder$.next(order);

          this.orderService.rejectOrder(order, res.value).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno odbijena porudžbina",
                type: "success",
              });
              if (this.type === "accept") {
                this.expandedOrders = this.expandedOrders.filter(
                  (o) => o.data.id !== order.id
                );
              }
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err.error.exceptionType);
              this.popupService.fire({
                title: "Greška",
                text: err.error.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  finishSuccessfully(order: Order) {
    this.popupService
      .fire({
        title: "Da li ste sigurni da hoćete da uspešno završite porudžbinu?",
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.editingOrder$.next(order);

          this.orderService.finishSuccessfully(order).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Porudžbina uspešno završena",
                type: "success",
              });
              if (this.type === "finish") {
                this.expandedOrders = this.expandedOrders.filter(
                  (o) => o.data.id !== order.id
                );
              }
              this.table.load(this.currentFilters);
            },
            (err) => {
              this.popupService.fire({
                title: "Greška",
                text: err.error.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  finishUnsuccessfully(order: Order) {
    this.popupService
      .fire({
        title: "Da li ste sigurni da hoćete da neuspešno završite porudžbinu?",
        text: "Unesi razlog neuspelog dostavljanja",
        input: "text",
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.editingOrder$.next(order);

          this.orderService.finishUnsuccessfully(order, res.value).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Porudžbina neuspešno završena",
                type: "success",
              });
              if (this.type === "finish") {
                this.expandedOrders = this.expandedOrders.filter(
                  (o) => o.data.id !== order.id
                );
              }
              this.table.load(this.currentFilters);
            },
            (err) => {
              this.popupService.fire({
                title: "Greška",
                text: err.error.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  setCurrentFilters(event) {
    this.currentFilters = event;
  }

  editDetails(order: Order) {
    this.orderService.priorityDeliveryCheck(order.id).subscribe((res: []) => {
      const priorityDeliveryAvailable = res.length === 0 ? true : false;
      const dialog = this.dialogService.open(OrderSingleComponent, {
        width: "70%",
        height: "90%",
        data: {
          order,
          priorityDeliveryAvailable,
        },
      });

      this.subs.add(
        dialog.onClose.subscribe((data) => {
          if (data) {
            this.orderService.editDetails(order, data).subscribe(
              (res) => {
                this.editingOrder$.next(order);

                this.popupService.fire({
                  title: "Uspešno promenjeni podaci",
                  type: "success",
                });
                this.table.load(this.currentFilters);
              },
              (err) => {
                this.popupService.fire({
                  title: "Greška",
                  text: err.error.message,
                  type: "error",
                });
              }
            );
          }
        })
      );
    });
  }

  changeOrderItem(oi: any): void {
    const dialog = this.dialogService.open(ChangeOrderItemComponent, {
      width: "400px",
      dismissableMask: true,
      data: {
        orderItem: oi,
        table: this.table,
        currentFilters: this.currentFilters,
      },
    });
  }

  mergeOrders(o1Id, o2Id) {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da spojite porudžbine ${o1Id} i ${o2Id}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.orderService.mergeOrders(o1Id, o2Id).subscribe(() => {
            this.expandedOrders = this.expandedOrders.filter(
              (o) => String(o.data.id) !== o2Id
            );

            this.table.load(this.currentFilters);
            this.popupService.fire({
              title: "Uspešno spojene porudžbine",
              type: "success",
              toast: true,
              position: "bottom-end",
            });
          });
        }
      });
  }

  duplicateOrderItem(orderItemId) {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da kopirate ${orderItemId}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.orderService.duplicateOrderItem(orderItemId).subscribe(() => {
            this.table.load(this.currentFilters);
          });
        }
      });
  }

  onAction(event): void {
    this.table.load(this.currentFilters);
  }

  isKiM(postalCode) {
    return postalCode.startsWith("38");
  }

  editComment(order: any, comment: any) {
    this.editCommentIds[order.data.id] = comment.id;
    this.commentForm.formGroup.patchValue(comment);
  }

  exitEditCommentMode(order: any) {
    delete this.editCommentIds[order.data.id];

    this.commentForm.formGroup.reset();
  }

  deleteComment(order: any, comment: any) {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da obrišete komentar ${comment.value}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.editingOrder$.next(order);

          this.commentService.delete(comment.id).subscribe((res) => {
            this.loadComments(order);
          });
        }
      });
  }

  submitComment(order: any, comment: any) {
    this.editingOrder$.next(order);

    let request = null;

    if (this.editCommentIds[order.data.id]) {
      request = this.commentService.edit(+this.editCommentIds[order.data.id], {
        ...comment,
        orderId: order.data.id,
      });
    } else {
      request = this.commentService.add({
        ...comment,
        orderId: order.data.id,
      });
    }
    request.subscribe((res) => {
      this.commentForm.formGroup.reset();
      delete this.editCommentIds[order.data.id];

      this.loadComments(order);
    });
  }

  collapseRow(event: any) {
    this.expandedOrders = this.expandedOrders.filter(
      (order) => order.data.id !== event.data.id
    );
  }

  expandRow(event: any) {
    if (!this.expandedOrders.includes(event)) {
      this.expandedOrders.push(event);
    }

    if (this.editingOrder$.value) {
      this.expandedOrders.forEach((expandedRow) => {
        if (expandedRow.data.id === this.editingOrder$.value.id) {
          this.reloadData(event);
        } else {
          expandedRow.data = this.table.values.find(
            (o) => +o.id === +expandedRow.data.id
          );
        }
      });
    } else {
      this.reloadData(event);
    }

    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  reloadData(event: any): void {
    this.loadComments(event);
    this.loadOrderStateTransitions(event);
    this.priorityDeliveryCheck(event);
  }

  priorityDeliveryCheck(event: any) {
    this.orderService
      .priorityDeliveryCheck(event.data.id)
      .subscribe((result) => {
        const order = this.table.values.find((o) => +o.id === +event.data.id);
        if (order) {
          order.priorityDelivery = result;
          this.cdr.markForCheck();
        }
      });
  }

  loadOtherOrders(otherOrder: any) {
    return ((event): Observable<any> => {
      return this.orderService
        .getAll(
          generateTableRequestParams(
            {
              first: event.first,
              rows: 5,
              sortOrder: 1,
              sortField: "created",
              filters: {
                phoneNumber: {
                  matchMode: "contains",
                  value: otherOrder.data.phoneNumber,
                },
                id: {
                  matchMode: "notEquals",
                  value: otherOrder.data.id,
                },
              },
            },
            this.otherOrdersFiltersMeta
          )
        )
        .pipe(
          map((pr: PaginatedResult<Order>) => {
            return {
              ...pr,
              currentResults: pr.currentResults.map((o: any) => {
                return {
                  ...o,
                  fullPrice: o.price + o.buyerShippingCost,
                  fullNumber:
                    o.number +
                    (o.subNumber ? o.subNumber : "") +
                    (o.floor ? "/" + o.floor : "") +
                    (o.apartment ? "/" + o.apartment : ""),
                };
              }),
            };
          })
        );
    }).bind(this);
  }

  loadComments(event: any) {
    this.commentService.getAll(event.data.id).subscribe((comments) => {
      const order = this.table.values.find((o) => +o.id === +event.data.id);
      if (order) {
        order.comments = comments;

        this.cdr.markForCheck();
      }
    });
  }

  loadOrderStateTransitions(event: any) {
    this.orderStateTransitionService
      .getAll(event.data.id)
      .subscribe((result) => {
        const order = this.table.values.find((o) => +o.id === +event.data.id);
        if (order) {
          order.stateTransitions = result;
          this.cdr.markForCheck();
        }
      });
  }

  changeOrderPartner(orderPartnerId: number, partnerId: number) {
    this.orderPartnerService
      .update({
        id: orderPartnerId,
        partnerId: partnerId,
      })
      .subscribe((res) => {
        this.popupService.fire({
          title: "Uspešno promenjen partner",
          type: "success",
          toast: true,
          position: "bottom-end",
        });
        this.table.load(this.currentFilters);
      });
  }

  showData(data: any): void {
    this.dialogService.open(JsonViewComponent, { data, width: "80%" });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
