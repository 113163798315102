import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ManufacturedProduct } from "src/app/_view-models/manufactured-product";

@Component({
  selector: "app-manufactured-product-single",
  templateUrl: "./manufactured-product-single.component.html",
  styleUrls: ["./manufactured-product-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturedProductSingleComponent implements AfterViewInit {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;

  formControls = {
    type: { state: "", validators: Validators.required },
    isUsed: { state: "" },
  };

  typeOptions = [
    {
      label: "NOT_DELIVERED",
      value: "NOT_DELIVERED",
    },
    {
      label: "SIZE",
      value: "SIZE",
    },
    {
      label: "RETURN",
      value: "RETURN",
    },
  ];

  mp: ManufacturedProduct = this.config.data.mp;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ngAfterViewInit() {
    this.formComponent.formGroup.patchValue(this.mp);
  }

  update(event: any): void {
    this.ref.close(event);
  }

  close(): void {
    this.ref.close();
  }
}
