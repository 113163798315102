<kadu-form
  elClass="p-3 mt-2"
  [controls]="formControls"
  (submitHandler)="save($event)"
>
  <ng-template kaduTemplate let-formGroup>
    <ng-container [formGroup]="formGroup">
      <div>
        <div>
          <label class="p3">Ime</label>
          <kadu-input #name elClass="p3" formControlName="name"></kadu-input>
          <app-form-errors
            [formElement]="name"
            [control]="formGroup.get('name')"
          ></app-form-errors>
        </div>

        <div>
          <label class="p3">Red</label>
          <kadu-input #red elClass="p3" formControlName="red"></kadu-input>
          <app-form-errors
            [formElement]="red"
            [control]="formGroup.get('red')"
            [Errors]="{
              pattern: 'Morate uneti broj',
              max: 'Maksimalna vrednost je 255.'
            }"
          ></app-form-errors>
        </div>

        <div>
          <label class="p3">Blue</label>
          <kadu-input #blue elClass="p3" formControlName="blue"></kadu-input>
          <app-form-errors
            [formElement]="blue"
            [control]="formGroup.get('blue')"
            [Errors]="{
              pattern: 'Morate uneti broj',
              max: 'Maksimalna vrednost je 255.'
            }"
          ></app-form-errors>
        </div>

        <div>
          <label class="p3">Green</label>
          <kadu-input #green elClass="p3" formControlName="green"></kadu-input>
          <app-form-errors
            [formElement]="green"
            [control]="formGroup.get('green')"
            [Errors]="{
              pattern: 'Morate uneti broj',
              max: 'Maksimalna vrednost je 255.'
            }"
          ></app-form-errors>
        </div>

        <div>
          <label class="p3">Alpha</label>
          <kadu-input #alpha elClass="p3" formControlName="alpha"></kadu-input>
          <app-form-errors
            [formElement]="alpha"
            [control]="formGroup.get('alpha')"
            [Errors]="{
              pattern: 'Morate uneti broj',
              max: 'Maksimalna vrednost je 1.',
              min: 'Minimalna vrednost je 0.'
            }"
          ></app-form-errors>
        </div>

        <div class="d-flex gap-2 align-items-center">
          <label class="p3">Pregled boje</label>
          <kadu-color-picker
            [isDisabled]="true"
            [Value]="{
              r: formGroup.get('red').value,
              g: formGroup.get('green').value,
              b: formGroup.get('blue').value
            }"
          ></kadu-color-picker>
        </div>

        <div>
          <label class="p3">SKU</label>
          <kadu-input
            #skuCode
            elClass="p3"
            formControlName="skuCode"
          ></kadu-input>
          <app-form-errors
            [formElement]="skuCode"
            [control]="formGroup.get('skuCode')"
          ></app-form-errors>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-2 mt-2">
        <kadu-button (clickHandler)="close()" class="btn btn-cancel btn-small">
          Odustani
        </kadu-button>
        <kadu-button type="submit" class="btn btn-submit btn-small">
          Sačuvaj
        </kadu-button>
      </div>
    </ng-container>
  </ng-template>
</kadu-form>
