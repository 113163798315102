<div class="col-12 pt-2">
  <kadu-button
    class="btn btn-small btn-submit w-100"
    (clickHandler)="sendOrderAgain()"
  >
    Pošalji ponovo
  </kadu-button>
</div>
<div class="col-12 pt-2">
  <kadu-button
    class="btn btn-small btn-submit w-100"
    (clickHandler)="getShippingLabel()"
  >
    Preuzmi adresnicu
  </kadu-button>
</div>
