import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginatedResult } from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/_interceptors/loading-spinner.interceptor";

@Injectable({
  providedIn: "root",
})
export class RawMaterialService {
  baseUrl = "backoffice/raw_materials";
  constructor(private http: HttpClient) {}

  getAll(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}`, {
      headers: new HttpHeaders().set(
        SKIP_LOADING_INTERCEPTOR,
        SKIP_LOADING_INTERCEPTOR
      ),
    });
  }

  update(id: number, amount: number): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}/amount`, { amount });
  }

  getAllPaginated(params: any): Observable<PaginatedResult<any[]>> {
    return this.http.get<PaginatedResult<any[]>>(`${this.baseUrl}/paginated`, {
      params,
    });
  }

  deleteRawMaterial(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  editRawMaterial(rawMaterial: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/edit/${rawMaterial.id}`, rawMaterial);
  }

  addRawMaterial(rawMaterial: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/add`, rawMaterial);
  }
}
