import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { map } from "rxjs/operators";
import { CompanyService } from "src/app/_services/company/company.service";
import { OrderService } from "src/app/_services/order/order.service";
import { ShippingCarrierService } from "src/app/_services/shipping-carrier/shipping-carrier.service";

@Component({
  selector: "app-misc",
  templateUrl: "./misc.component.html",
  styleUrls: ["./misc.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiscComponent {
  currentDeliveryCode = "";

  companies$ = this.companyService
    .getAll()
    .pipe(
      map((companies) => companies.map((c) => ({ label: c.name, value: c.id })))
    );
  shippingCarriers$ = this.shippingCarrierService
    .getAll()
    .pipe(
      map((shippingCarriers) =>
        shippingCarriers.map((sc) => ({ value: sc.id, label: sc.name }))
      )
    );

  constructor(
    private orderService: OrderService,
    private companyService: CompanyService,
    private shippingCarrierService: ShippingCarrierService,
    private cdr: ChangeDetectorRef
  ) {}

  getDeliveryCode(companyId, shippingCarrierId): void {
    this.orderService
      .getDeliveryCode(companyId, shippingCarrierId)
      .subscribe((res) => {
        this.currentDeliveryCode = res["delivery_code"];
        this.cdr.markForCheck();
      });
  }
}
