import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TableColumn } from "src/app/_models/table-column";
import { RawMaterialService } from "src/app/_services/raw-material/raw-material.service";
import { PopupService } from "src/app/components/popup/popup.service";

@Component({
  selector: "app-raw-materials",
  templateUrl: "./raw-materials-supplies.component.html",
  styleUrls: ["./raw-materials-supplies.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RawMaterialsSuppliesComponent implements OnInit {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private rawMaterialService: RawMaterialService,
    private popupService: PopupService
  ) {}

  rawMaterialsSource = new BehaviorSubject<any>([]);
  rawMaterials$ = this.rawMaterialsSource.asObservable();

  cols: TableColumn[];

  ngOnInit() {
    this.cols = [
      {
        header: "Proizvod",
        field: "rawMaterialName",
        filterType: "select",
        type: "normal",
      },
      {
        header: "Količina na stanju",
        field: "amountOnStock",
        filterType: "number",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "custom",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.actionsTemplate,
        },
      },
    ];

    this.load();
  }

  load() {
    this.rawMaterialService.getAll().subscribe((rawMaterials) => {
      this.rawMaterialsSource.next(rawMaterials);
    });
  }

  addSupplies(rawMaterial) {
    this.popupService
      .fire({
        title: "Broj novih komada",
        input: "text",
        showCancelButton: true,
      })
      .then((res: any) => {
        if (res.value) {
          this.rawMaterialService
            .update(rawMaterial.id, res.value)
            .subscribe((response) => {
              this.popupService.fire({
                type: "success",
                title: rawMaterial.raw_material_name,
                text: "Uspesno dodato " + res.value + " komada",
                toast: true,
                position: "bottom-end",
                timer: 5000,
              });
              this.load();
            });
        }
      });
  }
}
