import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-posta-order-check',
  templateUrl: './posta-order-check.component.html',
  styleUrls: ['./posta-order-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostaOrderCheckComponent {
  @Input() deliveryCode;
}
