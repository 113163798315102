import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { PaginatedResult } from "@kadung/kadung/kadu-table";
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from "src/app/_interceptors/skip-interceptors";

@Injectable({
  providedIn: "root",
})
export class ProductTypeService {
  baseUrl = "backoffice/product_types";
  constructor(private http: HttpClient) {}

  getAll(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}`);
  }

  getAllPaginated(params: any): Observable<PaginatedResult<any[]>> {
    return this.http.get<PaginatedResult<any[]>>(`${this.baseUrl}/paginated`, {
      params,
    });
  }

  deleteProductType(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  editProductType(productType: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/edit/${productType.id}`, productType);
  }

  addProductType(productType: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/`, productType);
  }
}
