import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { KaduTableComponent, PaginatedResult } from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";
import { TableFilter } from "src/app/_models/table-filter";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { CompanyService } from "src/app/_services/company/company.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { CompanySingleComponent } from "./_components/company-single/company-single.component";
import { DialogService } from "src/app/components/dialog/dialog.service";

@Component({
  selector: "app-companies",
  templateUrl: "./companies.component.html",
  styleUrls: ["./companies.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesComponent implements OnInit {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild(KaduTableComponent, { static: false }) table: KaduTableComponent;
  cols;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  companies$ = ((filters: TableFilter): Observable<PaginatedResult<any>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };

    return this.companyService.getAllPaginated(
      generateTableRequestParams(this.currentFilters, {})
    );
  }).bind(this);

  constructor(
    private companyService: CompanyService,
    private popupService: PopupService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "Id",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Ime",
        field: "name",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Adresa",
        field: "address",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Grad",
        field: "city",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Država",
        field: "country",
        filterType: "string",
        type: "normal",
      },
      {
        header: "U PDV-u",
        field: "inVat",
        filterType: "string",
        type: "boolean",
      },
      {
        header: "PIB",
        field: "tin",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Poštanski broj",
        field: "postalCode",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Skraćeno ime",
        field: "shortName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Banka",
        field: "bank",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Broj računa",
        field: "bankAccountNumber",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Pravni broj",
        field: "legalNumber",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Kod",
        field: "code",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Kontakt osoba",
        field: "contactPerson",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Broj kontakta",
        field: "contactPhone",
        filterType: "string",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "string",
        type: "custom",
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  deleteCompany(company: any): void {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da obrišete kompaniju ${company.name}?`,
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.companyService.deleteCompany(company.id).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno obrisana kompanija",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  openDialog(company = null): void {
    const dialog = this.dialogService.open(CompanySingleComponent, {
      showHeader: false,
      width: "600px",
      data: {
        company,
      },
    });

    dialog.onClose.subscribe((response) => {
      if (!response) return;

      if (response.editMode) {
        this.companyService.editCompany(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno izmenjena kompanija",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      } else {
        this.companyService.addCompany(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno dodata kompanija",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      }
    });
  }
}
