export function arrayComplement<T>(
  arr1: T[],
  arr2: T[],
  searchById = false
): T[] {
  // Use filter() to get elements in arr1 that are not in arr2

  if (searchById) {
    return arr1.filter(
      (item1: any) => !arr2.some((item2: any) => item2.id === item1.id)
    );
  }

  return arr1.filter((item) => !arr2.includes(item));
}

export function arrayIntersection<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter((item) => arr2.includes(item));
}
