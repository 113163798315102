import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { ShopService } from "src/app/_services/shop/shop.service";
import { Observable } from "rxjs";
import { Shop } from "src/app/_view-models/shop";
import { TableColumn } from "src/app/_models/table-column";
import { PaginatedResult } from "src/app/_models/paginated-result";
import { JsonViewComponent } from "src/app/components/json-view/json-view.component";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { generateTableRequestParams } from "src/app/_models/table-util";

@Component({
  selector: "app-shops",
  templateUrl: "./shops.component.html",
  styleUrls: ["./shops.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopsComponent {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;

  cols: TableColumn[];

  currentFilters = {
    first: 0,
    rows: 20,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  filtersMeta = {
    id: { key: "id", matchMode: "eq:" },
    name: { key: "name", matchMode: "like:" },
    url: { key: "url", matchMode: "like:" },
    productsNumber: { key: "productsNumber", matchMode: "like:" },
  };

  shopsRequest = ((filters): Observable<PaginatedResult<Shop>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };
    return this.shopService.getAll(
      generateTableRequestParams(this.currentFilters, this.filtersMeta)
    );
  }).bind(this);

  constructor(
    private shopService: ShopService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "ID",
        field: "id",
        filterType: "string",
        filterTypeMatch: "equals",
        type: "normal",
      },
      {
        header: "Naziv",
        field: "name",
        filterType: "string",
        type: "normal",
      },
      {
        header: "URL",
        field: "url",
        filterType: "string",
        type: "link",
      },
      {
        header: "Broj proizvoda",
        field: "productsNumber",
        sortDisabled: true,
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "custom",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  setCurrentFilters(event) {
    this.currentFilters = event;
  }

  showData(data: any): void {
    this.dialogService.open(JsonViewComponent, { data, width: "80%" });
  }
}
