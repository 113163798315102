import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginatedResult } from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  baseUrl = "backoffice/companies";
  constructor(private http: HttpClient) {}

  getAll(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}`).pipe(
      map((companies: any[]) => {
        return companies.sort((a, b) => +a.id - +b.id);
      })
    );
  }

  getAllPaginated(params: any): Observable<PaginatedResult<any[]>> {
    return this.http.get<PaginatedResult<any[]>>(`${this.baseUrl}/paginated`, {
      params,
    });
  }

  deleteCompany(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  editCompany(company: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/edit/${company.id}`, company);
  }

  addCompany(company: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/add`, company);
  }
}
