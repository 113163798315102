import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedResult } from '@kadung/kadung/kadu-table';
import { Observable } from 'rxjs';
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from 'src/app/_interceptors/skip-interceptors';
import { convertToFormData } from 'src/app/_models/util';
import { DispatchNote } from 'src/app/_view-models/dispatch-note';
import { PurchaseOrder } from 'src/app/_view-models/purchase-order';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {

  baseUrl = 'backoffice/purchase_orders/';
  constructor(private http: HttpClient) { }

  getAll(params: any): Observable<PaginatedResult<PurchaseOrder>> {
    return this.http.get<PaginatedResult<PurchaseOrder>>(`${this.baseUrl}`, {params});
  }

  getAllDispatchNotes(purchaseOrderId: number): Observable<DispatchNote[]> {
    return this.http.get<DispatchNote[]>(`${this.baseUrl}${purchaseOrderId}/dispatch_notes`);
  }

  create(purchaseOrder: PurchaseOrder): Observable<PurchaseOrder> {
    return this.http.post<PurchaseOrder>(`${this.baseUrl}`, convertToFormData(purchaseOrder, ['file']), {
      headers: new HttpHeaders().set(SKIP_DTO_TRANSFORM_INTERCEPTOR, SKIP_DTO_TRANSFORM_INTERCEPTOR)
    });
  }

  finish(purchaseOrderId: number): Observable<any> {
    return this.http.patch(`${this.baseUrl}${purchaseOrderId}/finish`, {});
  }

  delete(purchaseOrderId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${purchaseOrderId}`);
  }
}
