import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-company-single",
  templateUrl: "./company-single.component.html",
  styleUrls: ["./company-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySingleComponent {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  company = this.config.data.company;

  formControls = {
    name: { state: null, validators: [Validators.required] },
    address: { state: null, validators: [Validators.required] },
    city: { state: null, validators: [Validators.required] },
    country: { state: null, validators: [Validators.required] },
    inVat: { state: null },
    tin: { state: null },
    postalCode: { state: null, validators: [Validators.required] },
    shortName: { state: null, validators: [Validators.required] },
    bank: { state: null, validators: [Validators.required] },
    bankAccountNumber: { state: null, validators: [Validators.required] },
    legalNumber: { state: null, validators: [Validators.required] },
    code: { state: null, validators: [Validators.required] },
    contactPerson: { state: null, validators: [Validators.required] },
    contactPhone: { state: null, validators: [Validators.required] },
  };

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngAfterViewInit(): void {
    if (this.company) {
      this.formComponent.formGroup.patchValue(this.company);
    }
  }

  close(): void {
    this.ref.close(false);
  }

  save(formValue: any): void {
    if (this.company) {
      this.ref.close({
        editMode: true,
        data: { ...formValue, id: this.company.id },
      });
    } else {
      this.ref.close({ editMode: false, data: formValue });
    }
  }
}
