<div class='container d-flex justify-content-evenly align-items-center'>
  <kadu-form
  [controls]="formControls"
  class="py-5 h-100"
  elClass="d-flex flex-column align-items-center justify-content-center"
  style="max-width: 400px;"
  (submitHandler)="login($event)"
>
  <ng-template kaduTemplate let-formGroup>
    <ng-container [formGroup]="formGroup">
        <div class="w-100">
          <kadu-input
            #email
            formControlName="email"
            class=""
            placeholder="Email"
          ></kadu-input>
          <app-form-errors
            [formElement]="email"
            [control]="formGroup.get('email')"
          ></app-form-errors>
        </div>

        <div class="w-100">
          <kadu-input
            #password
            formControlName="password"
            type="password"
            class=""
            placeholder="Lozinka"
          ></kadu-input>
          <app-form-errors
            [formElement]="password"
            [control]="formGroup.get('password')"
          ></app-form-errors>
        </div>

        <kadu-button
          kaduSubmitButton
          type="submit"
          class="d-flex justify-content-center fw-600 w-50 btn btn-small btn-submit"
        >
          Uloguj se
        </kadu-button>
    </ng-container>
  </ng-template>
</kadu-form>
</div>

