import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-bex-order-check',
  templateUrl: './bex-order-check.component.html',
  styleUrls: ['./bex-order-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BexOrderCheckComponent {
  @Input() deliveryCode;
}
