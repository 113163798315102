<kadu-form
  elClass="p-3 mt-2"
  [controls]="formControls"
  (submitHandler)="save($event)"
>
  <ng-template kaduTemplate let-formGroup>
    <ng-container [formGroup]="formGroup">
      <div>
        <div>
          <label class="p3">Ime</label>
          <kadu-input #name elClass="p3" formControlName="name"></kadu-input>
          <app-form-errors
            [formElement]="name"
            [control]="formGroup.get('name')"
          ></app-form-errors>
        </div>

        <div formArrayName="additionalInformation">
          <label class="p3">Dodatne informacije</label>
          <div
            *ngFor="
              let control of formGroup.get('additionalInformation').controls;
              let i = index
            "
            [formGroupName]="i"
            class="d-flex align-items-start gap-2"
          >
            <div class="col-3">
              <kadu-input
                placeholder="Ime"
                formControlName="name"
                elClass="p3"
                #name
              ></kadu-input>
              <app-form-errors
                [formElement]="name"
                [control]="control.get('name')"
              ></app-form-errors>
            </div>

            <div class="col-3">
              <kadu-select
                placeholder="Tip"
                formControlName="type"
                elClass="p3"
                [options]="types"
                #type
              ></kadu-select>
              <app-form-errors
                [formElement]="type"
                [control]="control.get('type')"
              ></app-form-errors>
            </div>

            <div *ngIf="type.value === 'pattern'" class="col-3">
              <kadu-input
                placeholder="Validator pattern"
                formControlName="pattern"
                elClass="p3"
                #pattern
              ></kadu-input>
              <app-form-errors
                [formElement]="pattern"
                [control]="control.get('pattern')"
              ></app-form-errors>
            </div>

            <kadu-checkbox
              class="d-flex flex-column-reverse"
              formControlName="required"
              elClass="p3"
            >
              <p class="p3 mb-0">Obavezno polje</p>
            </kadu-checkbox>

            <kadu-button
              kaduTooltip="Ukloni red"
              (clickHandler)="removeAdditionalInformationControl(i)"
              class="btn btn-cancel btn-small"
              elClass="d-flex justify-content-center"
            >
              <i class="bi bi-x-lg"></i>
            </kadu-button>
          </div>

          <div class="d-flex align-items-end justify-content-end">
            <kadu-button
              (clickHandler)="addAdditionalInformationControl()"
              class="btn btn-small btn-submit"
            >
              <i class="bi bi-plus-lg"></i>
            </kadu-button>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-2 mt-2">
        <kadu-button (clickHandler)="close()" class="btn btn-cancel btn-small">
          Odustani
        </kadu-button>
        <kadu-button type="submit" class="btn btn-submit btn-small">
          Sačuvaj
        </kadu-button>
      </div>
    </ng-container>
  </ng-template>
</kadu-form>
