<div class="w-50 m-auto">
  <kadu-form
    elClass="d-flex justify-content-center flex-column gap-2"
    [controls]="formControls"
    (submitHandler)="update($event)"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <div>
          <p class="p3 mb-1">*Cena</p>
          <kadu-input #price class="p3" formControlName="price"></kadu-input>
          <app-form-errors
            [formElement]="price"
            [control]="formGroup.get('price')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">*Bazna cena</p>
          <kadu-input
            #basePrice
            class="p3"
            formControlName="basePrice"
          ></kadu-input>
          <app-form-errors
            [formElement]="basePrice"
            [control]="formGroup.get('basePrice')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">Komentar za proizvodnju</p>
          <kadu-textarea class="p3" formControlName="comment"></kadu-textarea>
        </div>

        <div>
          <kadu-checkbox
            elClass="d-flex align-items-center gap-2"
            formControlName="isManufactured"
          >
            <p class="p3 mb-0">Izrađena</p>
          </kadu-checkbox>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <kadu-button
            class="btn btn-small btn-submit w-100"
            type="submit"
          >
            Izmeni
          </kadu-button>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>
  <p class="p3">* Obavezna polja</p>
</div>
