import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ColorService } from "src/app/_services/color/color.service";
import { ManufacturedProductService } from "src/app/_services/manufactured-product/manufactured-product.service";
import { ProductTypeService } from "src/app/_services/product-type/product-type.service";
import { SizeService } from "src/app/_services/size/size.service";
import { StockUpdateLogService } from "src/app/_services/stock-update-log/stock-update-log.service";
import { SupplyService } from "src/app/_services/supply/supply.service";
import { ManufacturedProduct } from "src/app/_view-models/manufactured-product";
import { SubSink } from "subsink";
import { KaduTableComponent } from "@kadung/kadung/kadu-table";
import { TableColumn } from "src/app/_models/table-column";
import { TableFilter } from "src/app/_models/table-filter";
import { PaginatedResult } from "src/app/_models/paginated-result";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { ManufacturedProductSingleComponent } from "../manufactured-product-single/manufactured-product-single.component";
import { ManufacturedProductsCreateComponent } from "../manufactured-products-create/manufactured-products-create.component";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { StockCheckupListComponent } from "./_components/stock-checkup-list/stock-checkup-list.component";
import { PurchaseOrderListComponent } from "../purchase-order-list/purchase-order-list.component";
import { RawMaterialsSuppliesComponent } from "./_components/raw-materials-supplies/raw-materials-supplies.component";
import { RawMaterialsComponent } from "./_components/raw-materials/raw-materials.component";
import { ProductTypesComponent } from "./_components/product-types/product-types.component";

@Component({
  selector: "app-supplies",
  templateUrl: "./supplies.component.html",
  styleUrls: ["./supplies.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuppliesComponent implements OnInit, OnDestroy {
  @ViewChild("suppliesTemplate", { static: true })
  suppliesTemplate: TemplateRef<any>;
  @ViewChild("stockUpdateLogsTemplate", { static: true })
  stockUpdateLogsTemplate: TemplateRef<any>;
  @ViewChild("manufacturedProductsTemplate", { static: true })
  manufacturedProductsTemplate: TemplateRef<any>;
  @ViewChild("manufacturedProductsTable")
  manufacturedProductsTable: KaduTableComponent;
  @ViewChild("supplyTable")
  supplyTable: KaduTableComponent;
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild("manufacturedProductsActions", { static: true })
  manufacturedProductsActions: TemplateRef<any>;
  tabs;

  stockUpdateLogFilters = {
    first: 0,
    rows: 20,
    sortOrder: -1,
    sortField: "created",
    filters: {},
  };

  manufacturedProductFilters = {
    first: 0,
    rows: 20,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  supplyFilters = {
    first: 0,
    rows: 20,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  subs = new SubSink();

  cols: TableColumn[];

  supplyFiltersMeta = {
    productType: { key: "product_type", matchMode: "in:" },
    productColor: { key: "product_color", matchMode: "in:" },
    productSize: { key: "product_size", matchMode: "in:" },
  };

  stockUpdateCols: TableColumn[] = [
    {
      header: "Datum",
      field: "created",
      filterType: "date",
      type: "date",
      options: {
        format: "dd.MM.yyyy HH:mm",
      },
    },
    {
      header: "Nova kolicina",
      field: "newAmount",
      type: "normal",
    },
    {
      header: "Tip izmene",
      field: "stockUpdateType",
      filterType: "select",
      type: "normal",
      options: {
        optionValues: [
          { value: "CREATE_ORDER", label: "CREATE_ORDER" },
          { value: "REJECT_ORDER", label: "REJECT_ORDER" },
          { value: "UPDATE", label: "UPDATE" },
          { value: "WRITE_OFF", label: "WRITE_OFF" },
          { value: "STOCK_CHECKUP", label: "STOCK_CHECKUP" },
        ],
      },
    },
    {
      header: "Tip zaliha",
      field: "stockItemType",
      filterType: "select",
      type: "normal",
      options: {
        optionValues: [
          { value: "PRODUCT_TYPE_ITEM", label: "PRODUCT_TYPE_ITEM" },
          { value: "RAW_MATERIAL", label: "RAW_MATERIAL" },
        ],
      },
    },
    {
      header: "Izmenjena količina",
      field: "updateAmount",
      filterType: "number",
      type: "normal",
    },
    {
      header: "SKU",
      field: "sku",
      sortDisabled: true,
      type: "normal",
    },
  ];

  stockUpdateLogFiltersMeta = {
    created: { key: "created", matchMode: "btn:" },
    newAmount: { key: "newAmount", matchMode: "eq:" },
    stockUpdateType: { key: "stockUpdateType", matchMode: "in:" },
    stockItemType: { key: "stockItemType", matchMode: "in:" },
    updateAmount: { key: "updateAmount", matchMode: "numberBetween" },
    sku: { key: "sku", matchMode: "eq:" },
    productTypeItemId: { key: "product_type_item_id", matchMode: "eq:" },
  };

  manufacturedProductsCols: TableColumn[];

  manufacturedProductFiltersMeta = {
    id: { key: "id", matchMode: "eq:" },
    productName: { key: "productName", matchMode: "like:" },
    productTypeName: { key: "productTypeName", matchMode: "in:" },
    colorName: { key: "colorName", matchMode: "in:" },
    sizeName: { key: "sizeName", matchMode: "in:" },
    type: { key: "type", matchMode: "in:" },
    used: { key: "used", matchMode: "in:" },
    created: { key: "created", matchMode: "btn:" },
  };

  stockUpdateLogsCols: TableColumn[] = [
    {
      header: "Datum",
      field: "created",
      filterType: "date",
      type: "date",
      options: {
        format: "dd.MM.yyyy HH:mm",
      },
    },
    {
      header: "Proizvod",
      field: "productTypeId",
      filterType: "select",
      type: "normal",
      options: {
        lazy: true,
        optionValues: this.productTypeService.getAll().pipe(
          map((productTypes) => {
            return productTypes.map((pt) => {
              return {
                value: pt["id"],
                label: pt["name"],
              };
            });
          })
        ),
      },
    },
    {
      header: "Veličina",
      field: "sizeId",
      filterType: "select",
      type: "normal",
      options: {
        lazy: true,
        optionValues: this.sizeService.getAll().pipe(
          map((sizes) => {
            return sizes.map((s) => {
              return {
                value: s["id"],
                label: s["name"],
              };
            });
          })
        ),
      },
    },
    {
      header: "Boja",
      field: "colorId",
      filterType: "select",
      type: "normal",
      options: {
        lazy: true,
        optionValues: this.colorService.getAll().pipe(
          map((colors) => {
            return colors.map((c) => {
              return {
                value: c["id"],
                label: c["name"],
              };
            });
          })
        ),
      },
    },
    {
      header: "Nova količina",
      field: "newAmount",
      filterType: "string",
      filterTypeMatch: "equals",
      type: "normal",
    },
    {
      header: "Tip izmene",
      field: "stockUpdateType",
      filterType: "select",
      type: "normal",
      options: {
        optionValues: [
          { value: "CREATE_ORDER", label: "CREATE_ORDER" },
          { value: "REJECT_ORDER", label: "REJECT_ORDER" },
          { value: "UPDATE", label: "UPDATE" },
          { value: "WRITE_OFF", label: "WRITE_OFF" },
          { value: "STOCK_CHECKUP", label: "STOCK_CHECKUP" },
        ],
      },
    },
    {
      header: "Tip zaliha",
      field: "stockItemType",
      filterType: "select",
      type: "normal",
      options: {
        optionValues: [
          { value: "PRODUCT_TYPE_ITEM", label: "PRODUCT_TYPE_ITEM" },
          { value: "RAW_MATERIAL", label: "RAW_MATERIAL" },
        ],
      },
    },
    {
      header: "Izmenjena količina",
      field: "updateAmount",
      filterType: "string",
      filterTypeMatch: "equals",
      type: "normal",
    },
    {
      header: "SKU",
      field: "sku",
      sortDisabled: true,
      type: "normal",
    },
  ];

  stockUpdateLogsFiltersMeta = {
    created: { key: "created", matchMode: "btn:" },
    productTypeId: { key: "productTypeId", matchMode: "in:" },
    sizeId: { key: "sizeId", matchMode: "in:" },
    colorId: { key: "colorId", matchMode: "in:" },
    newAmount: { key: "newAmount", matchMode: "eq:" },
    stockUpdateType: { key: "stockUpdateType", matchMode: "in:" },
    stockItemType: { key: "stockItemType", matchMode: "in:" },
    updateAmount: { key: "updateAmount", matchMode: "eq:" },
    sku: { key: "sku", matchMode: "in:" },
  };

  supplies = ((filters: TableFilter): Observable<PaginatedResult<any>> => {
    if (filters.sortField === undefined) {
      filters.sortField = "created";
      filters.sortOrder = 1;
    }

    this.supplyFilters = {
      ...this.supplyFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };
    return this.supplyService
      .getAll(
        generateTableRequestParams(this.supplyFilters, this.supplyFiltersMeta)
      )
      .pipe(
        map((s: any) => {
          this.cdr.markForCheck();
          return {
            ...s,
            amountOnStock: s.amountOnStock - 100,
          };
        })
      );
  }).bind(this);

  stockUpdateLogsRequest = ((
    filters: TableFilter
  ): Observable<PaginatedResult<any>> => {
    if (filters.sortField === undefined) {
      filters.sortField = "created";
      filters.sortOrder = -1;
    }
    this.stockUpdateLogFilters = {
      ...this.stockUpdateLogFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };
    return this.stockUpdateLogService
      .getAll(
        generateTableRequestParams(
          this.stockUpdateLogFilters,
          this.stockUpdateLogsFiltersMeta
        )
      )
      .pipe(
        map((pr: PaginatedResult<any>) => {
          this.cdr.markForCheck();
          return {
            ...pr,
            currentResults: pr.currentResults
              ? pr.currentResults.map((cr) => {
                  return {
                    ...cr,
                    colorId: cr.colorName,
                    sizeId: cr.sizeName,
                    productTypeId: cr.productTypeName,
                  };
                })
              : [],
          };
        })
      );
  }).bind(this);

  stockUpdateLogRequest = ((
    filters: TableFilter,
    supply: any
  ): Observable<PaginatedResult<any>> => {
    this.supplyFilters[supply.id] = {
      ...this.supplyFilters[supply.id],
      ...filters,
      filters: {
        ...filters.filters,
        productTypeItemId: {
          matchMode: "equals",
          value: supply.id,
        },
      },
    };

    return this.stockUpdateLogService
      .getAll(
        generateTableRequestParams(
          this.supplyFilters[supply.id],
          this.stockUpdateLogFiltersMeta
        )
      )
      .pipe(
        map((pr: PaginatedResult<any>) => {
          this.cdr.markForCheck();
          return {
            ...pr,
            currentResults: pr.currentResults
              ? pr.currentResults.map((cr) => {
                  return {
                    ...cr,
                    colorId: cr.colorName,
                    sizeId: cr.sizeName,
                    productTypeId: cr.productTypeName,
                  };
                })
              : [],
          };
        })
      );
  }).bind(this);

  manufacturedProductsRequest = ((
    filters: TableFilter
  ): Observable<PaginatedResult<ManufacturedProduct>> => {
    Object.entries(filters.filters).forEach(
      (filter: [string, { value: any; matchMode: string }]) => {
        const { 0: name, 1: filterInfo } = filter;

        switch (name) {
          case "id":
            filterInfo.matchMode = "eq";
            break;

          default:
            break;
        }
      }
    );
    this.manufacturedProductFilters = {
      ...this.manufacturedProductFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };

    return this.manufacturedProductService
      .getAll(
        generateTableRequestParams(
          this.manufacturedProductFilters,
          this.manufacturedProductFiltersMeta
        )
      )
      .pipe(
        map((pr: PaginatedResult<any>) => {
          this.cdr.markForCheck();
          return {
            ...pr,
            currentResults: pr.currentResults
              ? pr.currentResults.map((cr) => {
                  return {
                    ...cr,
                    used: cr.isUsed,
                  };
                })
              : [],
          };
        })
      );
  }).bind(this);

  constructor(
    private productTypeService: ProductTypeService,
    private stockUpdateLogService: StockUpdateLogService,
    private supplyService: SupplyService,
    private popupService: PopupService,
    private colorService: ColorService,
    private sizeService: SizeService,
    private dialogService: DialogService,
    private manufacturedProductService: ManufacturedProductService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tabs = [
      {
        header: "Zalihe proizvoda",
        content: this.suppliesTemplate,
        queryParamValue: "supplies",
      },
      {
        header: "Zalihe vraćenih proizvoda",
        content: this.manufacturedProductsTemplate,
        queryParamValue: "manufactured_products",
      },
      {
        header: "Zalihe repromaterijala",
        component: RawMaterialsSuppliesComponent,
        queryParamValue: "raw_materials-supplies",
      },
      {
        header: "Porudžbenice",
        component: PurchaseOrderListComponent,
        queryParamValue: "purchase_orders",
      },
      {
        header: "Promene stanja zaliha",
        content: this.stockUpdateLogsTemplate,
        queryParamValue: "log",
      },
      {
        header: "Popisi",
        component: StockCheckupListComponent,
        queryParamValue: "stock",
      },
      {
        header: "Repromaterijali",
        component: RawMaterialsComponent,
        queryParamValue: "raw-materials",
      },
      {
        header: "Proizvodi",
        component: ProductTypesComponent,
        queryParamValue: "product_types",
      },
    ];

    this.cols = [
      {
        header: "Proizvod",
        field: "productType",
        filterType: "select",
        type: "normal",
        sortDisabled: true,
        options: {
          lazy: true,
          optionValues: this.productTypeService.getAll().pipe(
            map((productTypes) => {
              return productTypes.map((pt) => {
                return {
                  value: pt["id"],
                  label: pt["name"],
                };
              });
            })
          ),
        },
      },
      {
        header: "Boja",
        field: "productColor",
        filterType: "select",
        type: "normal",
        sortDisabled: true,
        options: {
          lazy: true,
          optionValues: this.colorService.getAll().pipe(
            map((colors) => {
              return colors.map((c) => {
                return {
                  value: c["id"],
                  label: c["name"],
                };
              });
            })
          ),
        },
      },
      {
        header: "Veličina",
        field: "productSize",
        filterType: "select",
        type: "normal",
        sortDisabled: true,
        options: {
          lazy: true,
          optionValues: this.sizeService.getAll().pipe(
            map((sizes) => {
              return sizes.map((s) => {
                return {
                  value: s["id"],
                  label: s["name"],
                };
              });
            })
          ),
        },
      },
      {
        header: "Količina - trebovano",
        field: "amountOnStock",
        filterType: "none",
        type: "normal",
        sortDisabled: true,
      },
      {
        header: "Prava količina u bazi",
        field: "realStockAmountInBase",
        filterType: "none",
        type: "normal",
        sortDisabled: true,
      },
      {
        header: "",
        field: "actions",
        filterType: "custom",
        type: "custom",
        width: "10%",
        sortDisabled: true,
        options: {
          template: this.actionsTemplate,
        },
      },
    ];

    this.manufacturedProductsCols = [
      {
        header: "ID",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Datum",
        field: "created",
        filterType: "date",
        type: "normal",
      },
      {
        header: "Naziv proizvoda",
        field: "productName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Proizvod",
        field: "productTypeName",
        filterType: "select",
        type: "normal",
        options: {
          lazy: true,
          optionValues: this.productTypeService.getAll().pipe(
            map((productTypes) => {
              return productTypes.map((pt) => {
                return {
                  value: pt["name"],
                  label: pt["name"],
                };
              });
            })
          ),
        },
      },
      {
        header: "Boja",
        field: "colorName",
        filterType: "select",
        type: "normal",
        options: {
          lazy: true,
          optionValues: this.colorService.getAll().pipe(
            map((colors) => {
              return colors.map((c) => {
                return {
                  value: c["name"],
                  label: c["name"],
                };
              });
            })
          ),
        },
      },
      {
        header: "Veličina",
        field: "sizeName",
        filterType: "select",
        type: "normal",
        options: {
          optionValues: [
            { value: "XS", label: "XS" },
            { value: "S", label: "S" },
            { value: "M", label: "M" },
            { value: "L", label: "L" },
            { value: "XL", label: "XL" },
            { value: "2XL", label: "2XL" },
            { value: "3XL", label: "3XL" },
          ],
        },
      },
      {
        header: "Tip",
        field: "type",
        filterType: "select",
        type: "normal",
        options: {
          optionValues: [
            { value: "NOT_DELIVERED", label: "NOT_DELIVERED" },
            { value: "RETURN", label: "RETURN" },
            { value: "SIZE", label: "SIZE" },
          ],
        },
      },
      {
        header: "Iskorišćeno",
        field: "used",
        filterType: "select",
        type: "boolean",
        options: {
          optionValues: [
            {
              label: "Da",
              value: true,
            },
            {
              label: "Ne",
              value: false,
            },
          ],
        },
      },
      {
        header: "",
        field: "actions",
        filterType: "custom",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.manufacturedProductsActions,
        },
      },
    ];
  }

  setManufacturedProductFilters(event) {
    this.manufacturedProductFilters = event;
  }

  setStockUpdateLogFilters(event) {
    this.stockUpdateLogFilters = event;
  }

  setSupplyFilters(supply, event) {
    this.supplyFilters[supply.id] = event;
  }

  addSupplies(productTypeItem) {
    this.popupService
      .fire({
        title: "Broj novih komada",
        input: "text",
        showCancelButton: true,
      })
      .then((res: any) => {
        if (res.value) {
          this.supplyService
            .update(productTypeItem.id, res.value)
            .subscribe((response) => {
              this.supplyTable.load(this.supplyFilters);
              this.popupService.fire({
                type: "success",
                title: `${productTypeItem.productType}/${productTypeItem.productColor}/${productTypeItem.productSize}`,
                text: `Uspesno dodato ${res.value} komada`,
                toast: true,
                position: "bottom-end",
              });
            });
        }
      });
  }

  removeManufacturedProduct(product: ManufacturedProduct) {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete da obrišete proizvod ${product.productName}?`,
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.manufacturedProductService
            .delete(product.id)
            .subscribe((res) => {
              this.manufacturedProductsTable.load(
                this.manufacturedProductFilters
              );
            });
        }
      });
  }

  editManufacturedProduct(mp) {
    const dialog = this.dialogService.open(ManufacturedProductSingleComponent, {
      width: "50%",
      height: "60%",
      data: {
        mp,
      },
    });
    this.subs.add(
      dialog.onClose.subscribe((data) => {
        if (data) {
          delete data.submit;
          this.manufacturedProductService.edit(mp.id, data).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno promenjeni podaci",
                type: "success",
              });
              this.manufacturedProductsTable.load(
                this.manufacturedProductFilters
              );
            },
            (err) => {
              this.popupService.fire({
                title: "Greška",
                text: err.error.message,
                type: "error",
              });
            }
          );
        }
      })
    );
  }

  addManufacturedProducts() {
    const dialog = this.dialogService.open(
      ManufacturedProductsCreateComponent,
      {
        width: "70%",
        height: "90%",
      }
    );
    this.subs.add(
      dialog.onClose.subscribe((data) => {
        if (data) {
          this.manufacturedProductService.add(data).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno dodati SKU-ovi",
                type: "success",
              });
              this.manufacturedProductsTable.load(
                this.manufacturedProductFilters
              );
            },
            (err) => {
              this.popupService.fire({
                title: "Greška",
                text: err.error.message,
                type: "error",
              });
            }
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
