import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormArray, FormGroup, Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { PaginatedResult } from "@kadung/kadung/kadu-table";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { map, pairwise, shareReplay, startWith, tap } from "rxjs/operators";
import * as snakecaseKeys from "snakecase-keys";
import { ColorService } from "src/app/_services/color/color.service";
import { PartnerService } from "src/app/_services/partner/partner.service";
import { PrintTypeService } from "src/app/_services/print-type/print-type.service";
import { ProductTypeService } from "src/app/_services/product-type/product-type.service";
import { RawMaterialService } from "src/app/_services/raw-material/raw-material.service";
import { SizeService } from "src/app/_services/size/size.service";
import { StockStateService } from "src/app/_services/stock-state/stock-state.service";
import { WarehouseLocationService } from "src/app/_services/warehouse-location/warehouse-location.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { arrayComplement } from "src/app/util";
import { SubSink } from "subsink";

@Component({
  selector: "app-product-type-single",
  templateUrl: "./product-type-single.component.html",
  styleUrls: ["./product-type-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTypeSingleComponent {
  @ViewChild("form", { static: false }) formComponent: KaduFormComponent;
  subs = new SubSink();
  productType = this.config.data.productType;

  rawMaterialControls = {
    id: [{ state: null }],
    consumptionPerUnit: { state: null, validators: [Validators.required] },
    sizeIdList: { state: [], validators: [Validators.required] },
  };

  printSizeControls = {
    name: [null, [Validators.required]],
    maxArea: [null, [Validators.required, Validators.pattern("^[0-9]+$")]],
    minArea: [null, [Validators.required, Validators.pattern("^[0-9]+$")]],
    code: [null, [Validators.required]],
    colorData: { type: "array", controls: [], options: {} },
  };

  productTypePartListControls = {
    name: { state: null, validators: [Validators.required] },
    top: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[0-9]+$")],
    },
    left: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[0-9]+$")],
    },
    angle: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[0-9]+$")],
    },
    width: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[0-9]+$")],
    },
    height: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[0-9]+$")],
    },
    scaleY: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[0-9]+$")],
    },
    scaleX: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[0-9]+$")],
    },
    prepressFileType: { state: null, validators: [Validators.required] },
    transformInitial: { state: null, validators: [Validators.required] },
    transformHover: { state: null, validators: [Validators.required] },
    recommendedFileOptions: { state: "", validators: [Validators.required] },
    referentSizeId: { state: null, validators: [Validators.required] },
    allowBackgroundChooser: true,
    colorUrlData: { type: "array", controls: [], options: {} },
    sizeData: { type: "array", controls: [], options: {} },
    printTypeList: {
      type: "array",
      controls: [],
      options: {},
    },
  };

  formControls = {
    name: { state: null, validators: [Validators.required] },
    slug: { state: null, validators: [Validators.required] },
    description: { state: null, validators: [Validators.required] },
    warehouseLocationSkuCode: {
      state: null,
      validators: [Validators.required],
    },
    active: { state: false },
    brand: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[A-Za-z]+$")],
    },
    skuCode: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[A-Z]{6}$")],
    },
    brandSkuCode: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[A-Z]{3}$")],
    },
    recommendedProfit: {
      state: null,
      validators: [Validators.required, Validators.pattern("^[0-9]+$")],
    },
    recommendedPrice: {
      state: null,
      validators: [Validators.required],
    },
    primaryPartnerId: { state: null, validators: [Validators.required] },
    authorizedPartnerList: { state: null, validators: [Validators.required] },
    sizeInfo: { state: null },
    coverImage: { state: null },
    productTypeItemData: {
      type: "group",
      controls: {
        colors: { state: [], validators: [Validators.required] },
        sizes: { state: [], validators: [Validators.required] },
        defaultColorId: { state: null, validators: [Validators.required] },
        rawMaterialList: {
          type: "array",
          controls: [
            {
              type: "group",
              controls: {
                ...this.rawMaterialControls,
              },
              options: {},
            },
          ],
          options: {},
        },
        groupedStockState: { state: null },
        groupedBasePrice: { state: null },
        groupedPackageCost: { state: null },
        priceColorData: {
          type: "array",
          controls: [],
          options: {},
        },
        sizeData: {
          type: "array",
          controls: [],
          options: {},
        },
      },
      options: {},
    },
    productTypePartList: {
      type: "array",
      controls: [
        {
          type: "group",
          controls: {
            ...this.productTypePartListControls,
          },
          options: {},
        },
      ],
      options: {},
    },
  };

  stockStateWithAdditionalInformationControls = {};

  sizes$ = this.sizeService.getAll().pipe(
    map((sizes) => {
      return sizes.map((size) => {
        return {
          value: size,
          label: size["name"],
        };
      });
    }),
    shareReplay()
  );

  colors$ = this.colorService.getAll().pipe(
    map((colors: any[]) => {
      return colors.map((color) => {
        return {
          value: color,
          label: color["name"],
        };
      });
    }),
    shareReplay()
  );

  partners$ = this.partnerService.getAll().pipe(
    map((partners: any[]) => {
      return partners.map((partner) => {
        return {
          value: partner,
          label: partner["name"],
        };
      });
    }),
    shareReplay()
  );

  printTypes: any[];
  printTypes$ = this.printTypeService.getAll().pipe(
    map((printTypes: any[]) => {
      return printTypes.map((printType) => {
        return {
          value: printType["id"],
          label: printType["name"],
        };
      });
    }),
    tap((printTypes) => (this.printTypes = printTypes)),
    shareReplay()
  );

  rawMaterials$ = this.rawMaterialService.getAll().pipe(
    map((rawMaterials: any[]) => {
      return rawMaterials.map((rawMaterial) => {
        return {
          value: rawMaterial["id"],
          label: rawMaterial["rawMaterialName"],
        };
      });
    })
  );

  warehouseLocations$ = this.warehouseLocationService
    .getAll({
      first: 0,
      rows: 40,
      sortOrder: 1,
      sortField: "created",
      filters: {},
    })
    .pipe(
      map((response: PaginatedResult<any[]>) => {
        return response.currentResults.map((warehouseLocaton) => {
          return {
            value: warehouseLocaton["skuCode"],
            label: warehouseLocaton["address"],
          };
        });
      })
    );

  stockStates$ = this.stockStateService
    .getAll({
      first: 0,
      rows: 40,
      sortOrder: 1,
      sortField: "created",
      filters: {},
    })
    .pipe(
      map((response: PaginatedResult<any[]>) => {
        return response.currentResults.map((stockState) => {
          return {
            value: stockState,
            label: stockState["name"],
          };
        });
      }),
      tap((stockStates) => {
        stockStates.forEach((stockState: any) => {
          this.stockStateWithAdditionalInformationControls[stockState.label] =
            {};

          if (stockState.value.additionalInformation) {
            JSON.parse(stockState.value.additionalInformation).forEach(
              (information) => {
                const stateObject: any = { state: null, validators: [] };

                if (information.required) {
                  stateObject.validators = [Validators.required];
                }

                switch (information.type) {
                  case "number":
                    stateObject.validators.push(Validators.pattern("^[0-9]*$"));
                    this.stockStateControlValidators[information.name] =
                      "Morate uneti broj";
                    break;

                  case "decimal":
                    stateObject.validators.push(
                      Validators.pattern("/^d+(.d+)?$/")
                    );
                    this.stockStateControlValidators[information.name] =
                      "Morate uneti decimalni broj";
                    break;

                  case "string":
                    stateObject.validators.push(
                      Validators.pattern("^[a-zA-Z]*$")
                    );
                    this.stockStateControlValidators[information.name] =
                      "Unos može sadržati samo slova";
                    break;

                  case "pattern":
                    stateObject.validators.push(
                      Validators.pattern(information.pattern)
                    );
                    this.stockStateControlValidators[information.name] =
                      "Unos nije ispravnog formata";
                    break;

                  default:
                    break;
                }

                this.stockStateWithAdditionalInformationControls[
                  stockState.label
                ][information.name] = stateObject;
              }
            );
          }
        });
      }),
      shareReplay()
    );

  stockStateControlValidators = {};

  prepressFileTypes = [
    { value: "rgb_raster_png", label: "rgb_raster_png" },
    { value: "cmyk_raster_pdf", label: "cmyk_raster_pdf" },
  ];

  constructor(
    private sizeService: SizeService,
    private colorService: ColorService,
    private partnerService: PartnerService,
    private printTypeService: PrintTypeService,
    private rawMaterialService: RawMaterialService,
    private cdr: ChangeDetectorRef,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private warehouseLocationService: WarehouseLocationService,
    private stockStateService: StockStateService,
    private productTypeService: ProductTypeService,
    private popupService: PopupService
  ) {}

  ngAfterViewInit(): void {
    this.subs.add(
      this.formComponent.formGroup
        .get("name")
        .valueChanges.subscribe((name) => {
          this.formComponent.formGroup
            .get("slug")
            .setValue(this.createSlug(name));
        }),
      this.formComponent.formGroup
        .get("productTypeItemData.sizes")
        .valueChanges.pipe(startWith([]), pairwise())
        .subscribe(([previousSizes, currentSizes]) => {
          const addedSizes = arrayComplement(currentSizes, previousSizes, true);
          const removedSizes = arrayComplement(
            previousSizes,
            currentSizes,
            true
          );

          const productTypeItemDataSizeDataControl =
            this.formComponent.formGroup.get(
              "productTypeItemData.sizeData"
            ) as FormArray;

          const productTypePartListControls = (
            this.formComponent.formGroup.get("productTypePartList") as FormGroup
          ).controls;

          addedSizes.forEach((size: any) => {
            this.formComponent.insertIntoArray(
              productTypeItemDataSizeDataControl,
              {
                name: { state: size.name },
                id: { state: size.id },
                weight: { state: null, validators: [Validators.required] },
              }
            );
          });

          removedSizes.forEach((size) => {
            this.formComponent.removeControlFromArray(
              productTypeItemDataSizeDataControl,
              size
            );
          });

          Object.keys(productTypePartListControls).forEach(
            (productTypePartControl) => {
              const productTypePartSizeDataControl = (
                productTypePartListControls[productTypePartControl] as FormGroup
              ).get("sizeData") as FormArray;

              addedSizes.forEach((size: any) => {
                this.formComponent.insertIntoArray(
                  productTypePartSizeDataControl,
                  {
                    name: { state: size.name },
                    id: { state: size.id },
                    printVerticalHeightMM: {
                      state: null,
                      validators: [
                        Validators.required,
                        Validators.pattern("^[0-9]+$"),
                      ],
                    },
                    printAreaWidthMM: {
                      state: null,
                      validators: [
                        Validators.required,
                        Validators.pattern("^[0-9]+$"),
                      ],
                    },
                  }
                );
              });

              removedSizes.forEach((size) => {
                this.formComponent.removeControlFromArray(
                  productTypePartSizeDataControl,
                  size
                );
              });
            }
          );
          this.cdr.detectChanges();
        }),
      this.formComponent.formGroup
        .get("productTypeItemData.colors")
        .valueChanges.pipe(startWith([]), pairwise())
        .subscribe(([previousColors, currentColors]) => {
          const addedColors = arrayComplement(
            currentColors,
            previousColors,
            true
          );
          const removedColors = arrayComplement(
            previousColors,
            currentColors,
            true
          );

          const priceColorDataControl = this.formComponent.formGroup.get(
            "productTypeItemData.priceColorData"
          ) as FormArray;

          const productTypePartListControls = (
            this.formComponent.formGroup.get("productTypePartList") as FormGroup
          ).controls;

          addedColors.forEach((color: any) => {
            this.formComponent.insertIntoArray(priceColorDataControl, {
              name: { state: color.name },
              id: { state: color.id },
              basePrice: { state: null, validators: [Validators.required] },
              packageCost: {
                state: null,
                validators: [Validators.required],
              },
              stockState: {
                state: null,
                validators: [Validators.required],
              },
            });
          });

          priceColorDataControl
            .at(priceColorDataControl.length - 1)
            .get("basePrice")
            .patchValue(
              this.formComponent.formGroup.get(
                "productTypeItemData.groupedBasePrice"
              ).value
            );

          priceColorDataControl
            .at(priceColorDataControl.length - 1)
            .get("packageCost")
            .patchValue(
              this.formComponent.formGroup.get(
                "productTypeItemData.groupedPackageCost"
              ).value
            );

          setTimeout(() => {
            priceColorDataControl.controls.forEach((control, index) => {
              control.get("stockState").valueChanges.subscribe((value) => {
                if (
                  Object.values(
                    this.stockStateWithAdditionalInformationControls[
                      control.value.stockState.name
                    ].length > 0
                  )
                ) {
                  this.formComponent.removeControlFromForm(
                    "stockStateAdditionalInformation",
                    {
                      emitEvent: false,
                    },
                    `productTypeItemData.priceColorData.${index}`
                  );

                  this.formComponent.addGroupToForm(
                    "stockStateAdditionalInformation",
                    this.stockStateWithAdditionalInformationControls[
                      control.value.stockState.name
                    ],
                    {},
                    `productTypeItemData.priceColorData.${index}`,
                    { emitEvent: false }
                  );
                }
              });
            });
          });

          removedColors.forEach((color: any) => {
            this.formComponent.removeControlFromArray(
              priceColorDataControl,
              color
            );
          });

          Object.keys(productTypePartListControls).forEach(
            (productTypePartControl) => {
              const printTypeControl = productTypePartListControls[
                productTypePartControl
              ].get("printTypeList") as FormArray;
              const colorUrlDataControl = (
                productTypePartListControls[productTypePartControl] as FormGroup
              ).get("colorUrlData") as FormArray;

              printTypeControl.controls.forEach((group) => {
                const printSizeListControl = group.get(
                  "printSizeList"
                ) as FormArray;

                if (printSizeListControl) {
                  printSizeListControl.controls.forEach((printSizeControl) => {
                    addedColors.forEach((color: any) => {
                      this.formComponent.insertIntoArray(
                        printSizeControl.get("colorData"),
                        {
                          name: { state: color.name },
                          id: { state: color.id },
                          basePrice: {
                            state: null,
                            validators: [
                              Validators.required,
                              Validators.pattern("^[0-9]+$"),
                            ],
                          },
                          serviceCost: {
                            state: null,
                            validators: [
                              Validators.required,
                              Validators.pattern("^[0-9]+$"),
                            ],
                          },
                        }
                      );
                    });

                    removedColors.forEach((color: any) => {
                      this.formComponent.removeControlFromArray(
                        printSizeControl.get("colorData") as FormArray,
                        color
                      );
                    });
                  });
                }
              });

              addedColors.forEach((color: any) => {
                this.formComponent.insertIntoArray(colorUrlDataControl, {
                  name: { state: color.name },
                  id: { state: color.id },
                  productTypePartImage: {
                    state: null,
                    validators: [Validators.required],
                  },
                });
              });

              removedColors.forEach((color: any) => {
                this.formComponent.removeControlFromArray(
                  colorUrlDataControl,
                  color
                );
              });
            }
          );
          this.cdr.detectChanges();
        }),
      this.printTypes$.subscribe((printTypes) => {
        const productTypePartListControls = (
          this.formComponent.formGroup.get("productTypePartList") as FormGroup
        ).controls;

        Object.keys(productTypePartListControls).forEach(
          (productTypePartControl, productTypePartIndex) => {
            const printTypeListControl = productTypePartListControls[
              productTypePartControl
            ].get("printTypeList") as FormArray;

            printTypes.forEach((printType) => {
              this.formComponent.insertIntoArray(printTypeListControl, {
                name: { state: printType.label },
                id: { state: printType.value },
                choosen: { state: false },
              });
            });

            this.handlePrintSizeList(
              printTypeListControl,
              productTypePartIndex
            );

            this.cdr.detectChanges();
          }
        );
      })
    );

    this.setGroupedValue("productTypeItemData.groupedBasePrice", "basePrice");
    this.setGroupedValue("productTypeItemData.groupedStockState", "stockState");
    this.setGroupedValue(
      "productTypeItemData.groupedPackageCost",
      "packageCost"
    );
  }

  setGroupedValue(controlName: string, targetControlName: string) {
    this.subs.add(
      this.formComponent.formGroup
        .get(controlName)
        .valueChanges.subscribe((value) => {
          (
            this.formComponent.formGroup.get(
              "productTypeItemData.priceColorData"
            ) as FormArray
          ).controls.forEach((control) => {
            control.get(targetControlName).setValue(value);
          });
        })
    );
  }

  createSlug(input: string): string {
    const charMap = {
      č: "c",
      ć: "c",
      ž: "z",
      đ: "d",
      dž: "dz",
      š: "s",
    };

    return input
      .toLowerCase()
      .replace(/[čćžđš]/g, (match) => charMap[match])
      .replace(/\s+/g, "-");
  }

  generateColorsWithSizes(
    colors: any[],
    sizes: any[],
    mode: "productTypeItemData" | "printSize",
    files = []
  ): any[] {
    const result = [];
    colors.forEach((color) => {
      sizes.forEach((size) => {
        switch (mode) {
          case "productTypeItemData":
            result.push({
              colorId: color.id,
              basePrice: color.basePrice,
              packageCost: color.packageCost,
              stockState: color.stockState.name,
              sizeId: size.id,
              weight: size.weight,
              stockStateAdditionalInformation: JSON.stringify(
                color.stockStateAdditionalInformation
              ),
            });
            break;

          case "printSize":
            result.push({
              colorId: color.id,
              sizeId: size.id,
              printAreaWidthMM: size.printAreaWidthMM,
              printVerticalHeightMM: size.printVerticalHeightMM,
              productTypePartImageIndex: files.length - 1,
              serviceCost: color.serviceCost,
              basePrice: color.basePrice,
            });
            break;

          default:
            break;
        }
      });
    });
    return result;
  }

  mergeColorArrays(arr1: any[], arr2: any[]): any[] {
    const combinedArray = [...arr1, ...arr2];

    return Object.values(
      combinedArray.reduce((acc, obj) => {
        if (!acc[obj.name]) {
          acc[obj.name] = {};
        }
        acc[obj.name] = { ...acc[obj.name], ...obj };
        return acc;
      }, {})
    );
  }

  generateProductTypePartImageFormData(
    productType: any,
    productTypePart: any,
    files: any[]
  ): any {
    const result = {};

    productTypePart.colorUrlData.forEach((color) => {
      result[`${productType.slug}/${productTypePart.name}/${color.name}`] = {
        productTypePartImageIndex: files.length - 1,
        path: `brendly/product_types/${productType.slug}/${productTypePart.name}/${color.name}.jpg`,
      };

      files.push(color.productTypePartImage[0]);
    });

    return result;
  }

  createProductType(formValue: any) {
    const files = [];

    if (formValue.sizeInfo) {
      files.push(formValue.sizeInfo[0]);
      formValue = { ...formValue, sizeInfoIndex: 0 };
    }

    if (formValue.coverImage) {
      files.push(formValue.coverImage[0]);
      formValue = {
        ...formValue,
        coverImageIndex: formValue.sizeInfoIndex ? 1 : 0,
      };
    }

    delete formValue["sizeInfo"];
    delete formValue["coverImage"];

    const finalProductType = {
      ...formValue,
      basePrice: Math.min(
        ...formValue.productTypeItemData.priceColorData.map(
          (priceColor) => +priceColor.basePrice
        )
      ),
      recommendedPrice: `${formValue.recommendedPrice["min"]}-${formValue.recommendedPrice["max"]}`,
      productTypeItemData: {
        ...formValue.productTypeItemData,
        colorWithSize: this.generateColorsWithSizes(
          formValue.productTypeItemData.priceColorData,
          formValue.productTypeItemData.sizeData,
          "productTypeItemData"
        ),
        rawMaterialList: formValue.productTypeItemData.rawMaterialList.map(
          (rawMaterial) => ({
            ...rawMaterial,
            sizeIdList: rawMaterial.sizeIdList.map((size) => size.id),
          })
        ),
      },
      productTypePartList: formValue.productTypePartList.map(
        (productTypePart) => ({
          ...productTypePart,
          printTypeList: productTypePart.printTypeList
            .filter((pt) => pt.choosen)
            .map((printType) => ({
              ...printType,
              printSizeList: printType.printSizeList.map((printSize) => ({
                ...printSize,
                productTypePartImageIndex: files.length,
                productTypePartImageData:
                  this.generateProductTypePartImageFormData(
                    formValue,
                    productTypePart,
                    files
                  ),
                productTypePartColorWithSizeList: this.generateColorsWithSizes(
                  this.mergeColorArrays(
                    productTypePart.colorUrlData,
                    printSize.colorData
                  ),
                  productTypePart.sizeData,
                  "printSize",
                  files
                ),
              })),
            })),
        })
      ),
    };

    finalProductType.productTypePartList.forEach((productTypePart) => {
      delete productTypePart["colorUrlData"];

      productTypePart.printTypeList.forEach((printType) => {
        printType.printSizeList.forEach((printSize) => {
          delete printSize["productTypePartImage"];
        });
      });
    });

    if (this.productType) {
      const data = { ...formValue, id: this.productType.id };

      this.productTypeService.editProductType(data).subscribe(
        (res) => {
          this.ref.close(true);
          this.popupService.fire({
            title: "Uspešno izmenjen tip proizvoda",
            type: "success",
          });
        },
        (err) => {
          this.popupService.fire({
            title: "Greška",
            text: err?.error?.message,
            type: "error",
          });
        }
      );
    } else {
      const data = this.formComponent.generateFormData(
        snakecaseKeys(finalProductType, {
          deep: true,
          exclude: [/.*\..*/],
        }),
        "request",
        files,
        "file_list"
      );

      this.productTypeService.addProductType(data).subscribe(
        (res) => {
          this.ref.close(true);
          this.popupService.fire({
            title: "Uspešno dodat tip proizvoda",
            type: "success",
          });
        },
        (err) => {
          console.log(err);
          this.popupService.fire({
            title: "Greška",
            text: err?.error?.message,
            type: "error",
          });
        }
      );
    }
  }

  addPrintSize(printTypeControl: any): void {
    const printSizeListControl = printTypeControl.get(
      "printSizeList"
    ) as FormArray;

    this.formComponent.insertIntoArray(
      printSizeListControl,
      this.printSizeControls
    );

    this.formComponent.formGroup
      .get("productTypeItemData.colors")
      .value.forEach((color) => {
        this.formComponent.insertIntoArray(
          (
            printSizeListControl.controls[
              printSizeListControl.length - 1
            ] as FormGroup
          ).get("colorData"),
          {
            name: { state: color.name },
            id: { state: color.id },
            basePrice: {
              state: null,
              validators: [Validators.required, Validators.pattern("^[0-9]+$")],
            },
            serviceCost: {
              state: null,
              validators: [Validators.required, Validators.pattern("^[0-9]+$")],
            },
          }
        );
      });
  }

  handlePrintSizeList(
    printTypeListControl: FormArray,
    productTypePartIndex: number
  ): void {
    printTypeListControl.controls.forEach(
      (printTypeControl: FormGroup, printTypeIndex: number) => {
        this.subs.add(
          printTypeControl.get("choosen").valueChanges.subscribe((choosen) => {
            if (choosen) {
              this.formComponent.addArrayToForm(
                "printSizeList",
                [this.printSizeControls],
                `productTypePartList.${productTypePartIndex}.printTypeList.${printTypeIndex}`
              );

              this.formComponent.formGroup
                .get("productTypeItemData.colors")
                .value.forEach((color) => {
                  (
                    printTypeControl.get("printSizeList") as FormArray
                  ).controls.forEach((printSizeControl) => {
                    this.formComponent.insertIntoArray(
                      printSizeControl.get("colorData"),
                      {
                        name: { state: color.name },
                        id: { state: color.id },
                        basePrice: {
                          state: null,
                          validators: [
                            Validators.required,
                            Validators.pattern("^[0-9]+$"),
                          ],
                        },
                        serviceCost: {
                          state: null,
                          validators: [
                            Validators.required,
                            Validators.pattern("^[0-9]+$"),
                          ],
                        },
                      }
                    );
                  });
                });
            } else {
              this.formComponent.removeControlFromForm(
                "printSizeList",
                { emitEvent: false },
                `productTypePartList.${productTypePartIndex}.printTypeList.${printTypeIndex}`
              );
            }
          })
        );
      }
    );
  }

  addProductTypePart(): void {
    const productTypePartListControl = this.formComponent.formGroup.get(
      "productTypePartList"
    ) as FormArray;

    this.formComponent.insertIntoArray(
      productTypePartListControl,
      this.productTypePartListControls
    );

    const printTypeListControl = (
      productTypePartListControl.controls[
        productTypePartListControl.length - 1
      ] as FormGroup
    ).get("printTypeList") as FormArray;

    this.printTypes.forEach((printType) => {
      this.formComponent.insertIntoArray(printTypeListControl, {
        name: { state: printType.label },
        id: { state: printType.value },
        choosen: { state: false },
      });
    });

    this.handlePrintSizeList(
      printTypeListControl,
      productTypePartListControl.length - 1
    );

    this.formComponent.formGroup
      .get("productTypeItemData.colors")
      .value.forEach((color) => {
        const colorUrlDataControl = (
          productTypePartListControl.controls[
            productTypePartListControl.length - 1
          ] as FormGroup
        ).get("colorUrlData") as FormArray;

        this.formComponent.insertIntoArray(colorUrlDataControl, {
          name: { state: color.name },
          id: { state: color.id },
          productTypePartImage: {
            state: null,
            validators: [Validators.required],
          },
        });
      });

    this.formComponent.formGroup
      .get("productTypeItemData.sizes")
      .value.forEach((size) => {
        const productTypePartSizeDataControl = (
          productTypePartListControl.controls[
            productTypePartListControl.length - 1
          ] as FormGroup
        ).get("sizeData") as FormArray;

        this.formComponent.insertIntoArray(productTypePartSizeDataControl, {
          name: { state: size.name },
          id: { state: size.id },
          printVerticalHeightMM: {
            state: null,
            validators: [Validators.required, Validators.pattern("^[0-9]+$")],
          },
          printAreaWidthMM: {
            state: null,
            validators: [Validators.required, Validators.pattern("^[0-9]+$")],
          },
        });
      });
  }

  addRawMaterial(): void {
    const rawMaterialListControl = this.formComponent.formGroup.get(
      "productTypeItemData.rawMaterialList"
    ) as FormArray;

    this.formComponent.insertIntoArray(
      rawMaterialListControl,
      this.rawMaterialControls
    );
  }

  removeProductTypePart(index: number): void {
    const productTypePartListControl = this.formComponent.formGroup.get(
      "productTypePartList"
    ) as FormArray;

    productTypePartListControl.removeAt(index);
  }

  removeRawMaterial(index: number): void {
    const rawMaterialListControl = this.formComponent.formGroup.get(
      "productTypeItemData.rawMaterialList"
    ) as FormArray;

    rawMaterialListControl.removeAt(index);
  }

  close(): void {
    this.ref.close(false);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
