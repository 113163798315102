import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ProductTypeService } from "src/app/_services/product-type/product-type.service";
import { RawMaterialService } from "src/app/_services/raw-material/raw-material.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { ColorsComponent } from "./_components/colors/colors.component";
import { SizesComponent } from "./_components/sizes/sizes.component";
import { PrintTypesComponent } from "./_components/print-types/print-types.component";
import { PartnersComponent } from "./_components/partners/partners.component";
import { CompaniesComponent } from "./_components/companies/companies.component";
import { WarehouseLocationsComponent } from "./_components/warehouse-locations/warehouse-locations.component";
import { StockStatesComponent } from "./_components/stock-states/stock-states.component";

@Component({
  selector: "app-dictionaries",
  templateUrl: "./dictionaries.component.html",
  styleUrls: ["./dictionaries.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DictionariesComponent {
  tabs = [
    {
      header: "Boje",
      component: ColorsComponent,
      queryParamValue: "colors",
    },
    {
      header: "Veličine",
      component: SizesComponent,
      queryParamValue: "sizes",
    },
    {
      header: "Tipovi printa",
      component: PrintTypesComponent,
      queryParamValue: "printTypes",
    },
    {
      header: "Kompanije",
      component: CompaniesComponent,
      queryParamValue: "companies",
    },
    {
      header: "Partneri",
      component: PartnersComponent,
      queryParamValue: "partners",
    },
    {
      header: "Lokacije magacina",
      component: WarehouseLocationsComponent,
      queryParamValue: "warehouse-locations",
    },
    {
      header: "Stanje zaliha",
      component: StockStatesComponent,
      queryParamValue: "stock-states",
    },
  ];

  constructor() {}
}
