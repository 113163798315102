<h1 class="text-center mb-3">Porudžbenica {{ purchaseOrder.id }}</h1>

<main class="h-100">
  <kadu-tabs
    #tabs
    orientation="horizontal"
    [tabs]="[
      {
        header: 'Prijem',
        content: receiveTemplate
      },
      {
        header: 'Ispunjenost',
        content: fulfillmentInfoTemplate
      }
    ]"
  >
    <ng-template #receiveTemplate>
      <div>
        <kadu-file-upload
          class="p3 btn w-50"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          chooseLabel="Unesi novu otpremnicu"
          (selectHandler)="createDispatchNote($event)"
        >
        </kadu-file-upload>
      </div>
      <div>
        <kadu-table
          #table
          class="mb-2"
          [columns]="cols"
          [rows]="40"
          [values]="dispatchNotes$ | async"
          [expandable]="true"
          [withFilters]="true"
          [loading]="false"
          [expandedTemplate]="expandedTemplate"
          [sortable]="true"
        >
          <ng-template #expandedTemplate let-dispatchNote>
            <div class="row mb-2">
              <kadu-table
                [columns]="receiveNoteCols"
                [rows]="5"
                [values]="dispatchNote.receiveNotes"
                [loading]="false"
              >
              </kadu-table>
            </div>

            <fieldset
              *ngIf="
                !purchaseOrder.endTime && !dispatchNote.stockUpdateFinished
              "
              class="border p-2"
              style="border-style: dashed !important"
            >
              <legend class="float-none w-auto px-2 text-start">
                Nov prijem
              </legend>
              <kadu-form
                #form
                [controls]="formControls"
                (submitHandler)="addReceiveNote(dispatchNote, $event)"
              >
                <ng-template kaduTemplate let-formGroup>
                  <ng-container [formGroup]="formGroup">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <kadu-file-upload
                          formControlName="receiveNoteFile"
                          chooseLabel="Unesi prijemnicu"
                          class="btn"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          [defaultFileTemplate]="true"
                        >
                        </kadu-file-upload>
                      </div>
                      <div class="col-4">
                        <kadu-file-upload
                          formControlName="fellerFile"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          class="btn"
                          chooseLabel="Unesi felere"
                          [defaultFileTemplate]="true"
                        >
                        </kadu-file-upload>
                      </div>
                      <div class="col-4">
                        <kadu-button
                          kaduSubmitButton
                          type="submit"
                          class="btn btn-submit w-100"
                        >
                          Završi prijem
                        </kadu-button>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
              </kadu-form>
            </fieldset>
          </ng-template>
        </kadu-table>
      </div>
    </ng-template>
  </kadu-tabs>

  <ng-template #fulfillmentInfoTemplate>
    <kadu-table
      #table
      class="mb-2"
      [columns]="fulfillmentInfoCols"
      [rows]="40"
      [values]="purchaseOrder.fulfillmentInfo"
      [withFilters]="true"
      [loading]="false"
      [sortable]="true"
    >
    </kadu-table>
  </ng-template>

  <ng-template #receiveNoteButtonsTemplate let-receiveNote>
    <kadu-button
      *ngIf="
        !purchaseOrder.endTime &&
        !receiveNote.dispatchNote.stockUpdateFinished &&
        receiveNote.state === 'COMMITTED'
      "
      class="btn btn-small btn-submit"
      (clickHandler)="rollbackReceiveNote(receiveNote)"
    >
      Poništi prijem
    </kadu-button>
  </ng-template>

  <ng-template #buttonsTemplate let-dispatchNote>
    <kadu-button
      *ngIf="
        !purchaseOrder.endTime &&
        dispatchNote.hasCommittedReceiveNotes &&
        !dispatchNote.stockUpdateFinished
      "
      class="btn btn-small btn-submit"
      (clickHandler)="finishDispatchNote(dispatchNote)"
    >
      Završi otpremnicu
    </kadu-button>
    <kadu-button
      *ngIf="!purchaseOrder.endTime && dispatchNote.receiveNotes.length === 0"
      class="btn btn-small btn-danger"
      (clickHandler)="deleteDispatchNote(dispatchNote)"
    >
      Obriši otpremnicu
    </kadu-button>
  </ng-template>

  <ng-template #downloadTemplate let-dispatchNote>
    <a
      *ngIf="dispatchNote.stockUpdateFinished"
      [href]="dispatchNote.fileUrl"
      download=""
      ><i class="fa fa-download"></i
    ></a>
  </ng-template>

  <div class="h-100 w-100 d-flex justify-content-end">
    <kadu-button
      *ngIf="!purchaseOrder.endTime && (anyDispatchNoteFinished$ | async)"
      class="btn btn-small btn-submit"
      (clickHandler)="finishPurchaseOrder()"
    >
      Završi porudžbenicu
    </kadu-button>
  </div>
</main>
