import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { KaduTableComponent, PaginatedResult } from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";
import { TableFilter } from "src/app/_models/table-filter";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { PartnerService } from "src/app/_services/partner/partner.service";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { PartnerSingleComponent } from "./_components/partner-single/partner-single.component";

@Component({
  selector: "app-partners",
  templateUrl: "./partners.component.html",
  styleUrls: ["./partners.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnersComponent implements OnInit {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild(KaduTableComponent, { static: false }) table: KaduTableComponent;

  cols;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  partners$ = ((filters: TableFilter): Observable<PaginatedResult<any>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };

    return this.partnerService.getAllPaginated(
      generateTableRequestParams(this.currentFilters, {})
    );
  }).bind(this);

  constructor(
    private partnerService: PartnerService,
    private popupService: PopupService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "Id",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Ime partnera",
        field: "name",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Ime",
        field: "firstName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Prezime",
        field: "lastName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Adresa",
        field: "address",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Grad",
        field: "city",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Država",
        field: "country",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Email",
        field: "email",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Broj telefona",
        field: "phoneNumber",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Korisničko ime",
        field: "username",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Broj ON",
        field: "dispatchOrdersNumber",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Adresa ON",
        field: "dispatchOrdersAddress",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Grad ON",
        field: "dispatchOrdersCity",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Poštanski broj ON",
        field: "dispatchOrdersPostalCode",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Broj VN",
        field: "returnOrdersNumber",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Adresa VN",
        field: "returnOrdersAddress",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Grad VN",
        field: "returnOrdersCity",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Poštanski broj VN",
        field: "returnOrdersPostalCode",
        filterType: "string",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "string",
        type: "custom",
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  deletePartner(partner: any): void {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da obrišete partnera ${partner.name}?`,
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.partnerService.deletePartner(partner.id).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno obrisan partner",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  openDialog(partner = null): void {
    const dialog = this.dialogService.open(PartnerSingleComponent, {
      showHeader: false,
      width: "600px",
      data: {
        partner,
      },
    });

    dialog.onClose.subscribe((response) => {
      if (!response) return;

      if (response.editMode) {
        this.partnerService.editPartner(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno izmenjen partner",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      } else {
        this.partnerService.addPartner(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno dodat partner",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      }
    });
  }
}
