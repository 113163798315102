import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import * as moment from "moment";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ShippingCarrierService } from "src/app/_services/shipping-carrier/shipping-carrier.service";
import { Order } from "src/app/_view-models/order";
import { OrderFulfillmentType } from "src/app/_view-models/order-fulfillment-type.model";
import { environment } from "src/environments/environment";
import { SubSink } from "subsink";

@Component({
  selector: "app-order-single",
  templateUrl: "./order-single.component.html",
  styleUrls: ["./order-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSingleComponent {
  @ViewChild(KaduFormComponent, { static: true })
  formComponent: KaduFormComponent;
  priorityDeliveryUnavailable$ = new BehaviorSubject<boolean>(false);
  order: Order = this.config.data["order"];
  today = new Date();
  subs = new SubSink();

  disabledDates = [
    ...environment.nonWorkingDays,
    new Date(),
  ];

  shippingCarriers: any[] = [];
  shippingCarriersLoaded$ = new ReplaySubject<boolean>(1);

  shippingCarrierIds$ = this.shippingCarrierService.getAll().pipe(
    tap((shippingCarriers) => {
      this.shippingCarriers = shippingCarriers;
      this.shippingCarriersLoaded$.next(true);
    }),
    map((shippingCarriers) =>
      shippingCarriers.map((sc) => ({ value: sc.id, label: sc.name }))
    )
  );

  shippingCarriersDeliveryCodeValidators = {
    BEX: [Validators.pattern(`^\\d*$`)],
    POSTA: [],
  };

  deliveryDateFormControl = {
    deliveryDate: { state: "", validators: [Validators.required] },
  };

  orderFormControls = {
    phoneNumber: { state: "", validators: Validators.required },
    email: { state: "", validators: Validators.required },
    firstName: { state: "", validators: Validators.required },
    lastName: { state: "", validators: Validators.required },
    address: { state: "", validators: Validators.required },
    number: { state: "", validators: Validators.required },
    subNumber: { state: "" },
    floor: { state: "" },
    apartment: { state: "" },
    city: { state: "", validators: Validators.required },
    postalCode: { state: "", validators: Validators.required },
    tin: { state: "" },
    shippingCarrierId: { state: "", validators: Validators.required },
    comment: { state: "" },
    baseFreeShipping: { state: "" },
    buyerFreeShipping: { state: "" },
    paid: { state: "" },
    ransomCollected: { state: "" },
    rejectionReason: { state: "" },
    notDeliveredReason: { state: "" },
    deliveryCode: { state: "", validators: Validators.required },
    deliveryInfo: {
      type: "group",
      controls: {
        fulfillmentType: {
          state: OrderFulfillmentType.STANDARD_DELIVERY,
          validators: [
            Validators.required,
            this.priorityDeliveryAvailable.bind(this),
          ],
        },
      },
    },
  };

  fulfillmentTypes = [
    {
      label: "Standardna isporuka (2-5 dana)",
      value: OrderFulfillmentType.STANDARD_DELIVERY,
    },
    {
      label: "Prioritetna isporuka (2-3 dana) + 100 RSD",
      value: OrderFulfillmentType.PRIORITY_DELIVERY,
    },
    {
      label: "Odložena isporuka",
      value: OrderFulfillmentType.EXACT_DATE_DELIVERY,
    },
  ];

  constructor(
    private shippingCarrierService: ShippingCarrierService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  priorityDeliveryAvailable(control: AbstractControl): ValidationErrors | null {
    return control.value === OrderFulfillmentType.PRIORITY_DELIVERY &&
      !this.config.data["priorityDeliveryAvailable"]
      ? { priorityDeliveryAvailable: true }
      : null;
  }

  ngAfterViewInit() {
    this.subs.add(
      this.formComponent.formGroup
        .get("deliveryInfo.fulfillmentType")
        .valueChanges.subscribe((currentValue) => {
          this.toggleDateField();
          this.priorityDeliveryUnavailable$.next(
            currentValue === OrderFulfillmentType.PRIORITY_DELIVERY &&
              !this.config.data["priorityDeliveryAvailable"]
          );
        }),
      this.formComponent.formGroup
        .get("shippingCarrierId")
        .valueChanges.subscribe((value) => {
          this.shippingCarriersLoaded$.subscribe(() => {
            const shippingCarrierCode = this.shippingCarriers.filter(
              (sc) => sc.id == value
            )[0].code;
            this.formComponent.formGroup.get("deliveryCode").clearValidators();
            if (
              this.shippingCarriersDeliveryCodeValidators[shippingCarrierCode]
            ) {
              this.formComponent.formGroup
                .get("deliveryCode")
                .setValidators([
                  ...this.shippingCarriersDeliveryCodeValidators[
                    shippingCarrierCode
                  ],
                ]);
            }
            this.formComponent.formGroup
              .get("deliveryCode")
              .updateValueAndValidity();
          });
        })
    );

    setTimeout(() => {
      this.formComponent.formGroup.patchValue(this.order);
    });
  }

  toggleDateField(): void {
    if (
      this.formComponent.formGroup.get("deliveryInfo.fulfillmentType")
        .value === OrderFulfillmentType.EXACT_DATE_DELIVERY
    ) {
      this.formComponent.addControlToForm(
        "deliveryDate",
        this.deliveryDateFormControl,
        "deliveryInfo"
      );

      if (!this.order.deliveryInfo.deliveryDate) {
        this.formComponent.formGroup
          .get("deliveryInfo.deliveryDate")
          .setValue("");
      }
    } else {
      (
        this.formComponent.formGroup.get("deliveryInfo") as FormGroup
      ).removeControl("deliveryDate");
    }
  }

  update(formValue: any): void {
    const updated = {
      ...formValue,
      deliveryInfo: {
        ...formValue["deliveryInfo"],
        deliveryDate: formValue?.deliveryInfo?.deliveryDate
          ? moment(formValue?.deliveryInfo?.deliveryDate).format(
              "YYYY-MM-DD-00-00-00"
            )
          : null,
      },
    };

    this.ref.close(updated);
  }

  close(): void {
    this.ref.close();
  }
}
