import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { JwtService } from "./_services/jwt/jwt.service";
import { FormErrorsComponent } from "@kadung/kadung/kadu-form";
import { LoaderService } from "./_services/loader/loader.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = "BrendlyAdminPlatform";
  loggedIn$: Observable<boolean>;
  isLoading$ = this.loaderService.isLoading.asObservable();
  environment = environment;

  constructor(
    private jwtService: JwtService,
    private router: Router,
    private translateService: TranslateService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.loaderService.hide();

    FormErrorsComponent.prototype.Errors = {
      required: "Ovo polje je obavezno",
    };

    const country = environment.countries.find((country) =>
      window.location.href.includes(`.${country.alpha2Code}`)
    );
    environment.setCountry(country);

    this.translateService.setDefaultLang("sr");
    this.translateService.use("sr");
    this.loggedIn$ = this.jwtService.accessTokens$.pipe(
      map((accessToken) => accessToken !== null)
    );
  }

  onLogout() {
    this.router.navigate(["/login"]);
  }

  closeHamburgerIfOpen(hamburger: HTMLElement) {
    if (hamburger.attributes.getNamedItem("aria-expanded").value === "true") {
      hamburger.click();
    }
  }
}
