<kadu-tabs
  orientation="horizontal"
  (changeHandler)="changeTab($event)"
  [tabs]="[
    {
      header: 'Neisporučeno',
      content: deliveryCodeTemplate,
      queryParamValue: 'NOT_DELIVERED'
    },
    {
      header: 'Veličina',
      content: reclamationIdTemplate,
      queryParamValue: 'SIZE'
    }
  ]"
></kadu-tabs>

<ng-template #deliveryCodeTemplate>
  <kadu-form
    elClass="d-flex justify-content-center "
    [controls]="deliveryCodeFormControls"
    (submitHandler)="search('NOT_DELIVERED', $event)"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <div class="d-flex flex-column gap-2 col-12 col-md-6">
          <kadu-input
            formControlName="deliveryCode"
            elClass="p2"
            placeholder="Delivery code"
          ></kadu-input>
          <kadu-button type="submit" class="btn btn-small btn-submit w-100">
            Pretraži
          </kadu-button>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>

  <ng-container
    *ngTemplateOutlet="
      selectedOrderTemplate;
      context: { $implicit: 'NOT_DELIVERED' }
    "
  ></ng-container>
</ng-template>

<ng-template #reclamationIdTemplate>
  <kadu-form
    elClass="d-flex justify-content-center"
    [controls]="reclamationIdFormControls"
    (submitHandler)="search('SIZE', $event)"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <div class="d-flex flex-column gap-2 col-12 col-md-6">
          <kadu-input
            formControlName="reclamationId"
            elClass="p2"
            placeholder="Reclamation id"
          ></kadu-input>
          <kadu-button type="submit" class="btn btn-small btn-submit w-100">
            Pretraži
          </kadu-button>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>

  <ng-container
    *ngTemplateOutlet="selectedOrderTemplate; context: { $implicit: 'SIZE' }"
  ></ng-container>
</ng-template>

<ng-template #selectedOrderTemplate let-type>
  <ng-container *ngIf="(selectedOrder$ | async) && !(notFound$ | async)">
    <kadu-form
      #selectedOrderForm
      elClass="d-flex justify-content-center"
      [controls]="selectedOrderFormControls"
      (submitHandler)="update($event)"
    >
      <ng-template kaduTemplate let-formGroup>
        <ng-container [formGroup]="formGroup">
          <div class="d-flex flex-column gap-2">
            <div formArrayName="orderItems" class="p-2">
              <div
                *ngFor="
                  let row of formGroup.get('orderItems').controls;
                  let i = index
                "
              >
                <div [formGroupName]="i" class="d-flex">
                  <kadu-checkbox
                    formControlName="include"
                    class="col-3 d-flex justify-content-center"
                  ></kadu-checkbox>
                  <div>
                    <ng-container *ngIf="type === 'NOT_DELIVERED'">
                      <ng-container
                        *ngTemplateOutlet="
                          orderItemTemplate;
                          context: { $implicit: row.value.orderItem, index: i }
                        "
                      ></ng-container>
                    </ng-container>

                    <ng-container *ngIf="type === 'SIZE'">
                      <ng-container
                        *ngTemplateOutlet="
                          productItemTemplate;
                          context: { $implicit: row.value.orderItem, index: i }
                        "
                      ></ng-container
                    ></ng-container>
                  </div>
                </div>
              </div>
            </div>
            <kadu-button type="submit" class="btn btn-submit btn-small w-100">
              Dodaj
            </kadu-button>
          </div>
        </ng-container>
      </ng-template>
    </kadu-form>
  </ng-container>

  <ng-container *ngIf="notFound$ | async">
    <p class="text-center mt-1 p2">Porudžbina nije pronađena.</p>
  </ng-container>
</ng-template>

<ng-template #orderItemTemplate let-orderItem>
  <div class="text-center row m-0 h-100 w-100">
    <div class="col-6 h-100 d-flex justify-content-center align-items-center">
      <img
        [src]="(orderItem | findPart).productImageUrl"
        class="w-auto h-auto mh-100 mw-100"
      />
    </div>
    <div
      class="col-6 d-flex justify-content-center align-items-center flex-column"
      style="font-size: 14px"
    >
      <p class="mb-1 fw-600">{{ orderItem.productName }}</p>
      <p class="mb-1">{{ orderItem.productTypeName }}</p>
      <p class="mb-1">{{ orderItem.productColor }}</p>
      <p class="mb-1">{{ orderItem.productSize }}</p>
    </div>
  </div>
</ng-template>

<ng-template #productItemTemplate let-productItem>
  <div class="text-center row m-0 h-100 w-100">
    <div class="col-6 h-100 d-flex justify-content-center align-items-center">
      <img
        [src]="productItem.productImageUrl"
        class="w-auto h-auto mh-100 mw-100"
      />
    </div>
    <div
      class="col-6 d-flex justify-content-center align-items-center flex-column"
      style="font-size: 14px"
    >
      <p class="mb-1 fw-600">{{ productItem.productName }}</p>
      <p class="mb-1">{{ productItem.productTypeName }}</p>
      <p class="mb-1">{{ productItem.productColor }}</p>
      <p class="mb-1">{{ productItem.productSize }}</p>
    </div>
  </div>
</ng-template>
