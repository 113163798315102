<div class="w-50 m-auto">
  <kadu-form
    elClass="d-flex justify-content-center flex-column gap-2"
    [controls]="orderFormControls"
    (submitHandler)="update($event)"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <div>
          <p class="p3 mb-1">*Broj telefona</p>
          <kadu-input
            #phoneNumber
            class="p3"
            formControlName="phoneNumber"
          ></kadu-input>
          <app-form-errors
            [formElement]="phoneNumber"
            [control]="formGroup.get('phoneNumber')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">*Email</p>
          <kadu-input #email class="p3" formControlName="email"></kadu-input>
          <app-form-errors
            [formElement]="email"
            [control]="formGroup.get('email')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">*Ime</p>
          <kadu-input
            #firstName
            class="p3"
            formControlName="firstName"
          ></kadu-input>
          <app-form-errors
            [formElement]="firstName"
            [control]="formGroup.get('firstName')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">*Prezime</p>
          <kadu-input
            #lastName
            class="p3"
            formControlName="lastName"
          ></kadu-input>
          <app-form-errors
            [formElement]="lastName"
            [control]="formGroup.get('lastName')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">*Ulica</p>
          <kadu-input
            #address
            class="p3"
            formControlName="address"
          ></kadu-input>
          <app-form-errors
            [formElement]="address"
            [control]="formGroup.get('address')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">*Broj</p>
          <kadu-input #number class="p3" formControlName="number"></kadu-input>
          <app-form-errors
            [formElement]="number"
            [control]="formGroup.get('number')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">Podbroj</p>
          <kadu-input class="p3" formControlName="subNumber"></kadu-input>
        </div>

        <div>
          <p class="p3 mb-1">Sprat</p>
          <kadu-input class="p3" formControlName="floor"></kadu-input>
        </div>

        <div>
          <p class="p3 mb-1">Stan</p>
          <kadu-input class="p3" formControlName="apartment"></kadu-input>
        </div>

        <div>
          <p class="p3 mb-1">*Grad</p>
          <kadu-input #city class="p3" formControlName="city"></kadu-input>
          <app-form-errors
            [formElement]="city"
            [control]="formGroup.get('city')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">*Poštanski broj</p>
          <kadu-input
            #postalCode
            class="p3"
            formControlName="postalCode"
          ></kadu-input>
          <app-form-errors
            [formElement]="postalCode"
            [control]="formGroup.get('postalCode')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">PIB</p>
          <kadu-input class="p3" formControlName="tin"></kadu-input>
        </div>

        <div>
          <p class="p3 mb-1">*Kurirska služba</p>
          <kadu-select
            #shippingCarrierId
            [options]="shippingCarrierIds$ | async"
            formControlName="shippingCarrierId"
          ></kadu-select>
          <app-form-errors
            [formElement]="shippingCarrierId"
            [control]="formGroup.get('shippingCarrierId')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">*Delivery code</p>
          <kadu-input
            #deliveryCode
            class="p3"
            formControlName="deliveryCode"
          ></kadu-input>
          <app-form-errors
            [formElement]="deliveryCode"
            [control]="formGroup.get('deliveryCode')"
          ></app-form-errors>
        </div>

        <div>
          <p class="p3 mb-1">Komentar za poštara</p>
          <kadu-textarea formControlName="comment"></kadu-textarea>
        </div>

        <div>
          <kadu-checkbox
            elClass="d-flex align-items-center gap-2"
            formControlName="baseFreeShipping"
          >
            <p class="p3 mb-0">Besplatna poštarina (Dizajner)</p>
          </kadu-checkbox>
        </div>

        <div>
          <kadu-checkbox
            elClass="d-flex align-items-center gap-2"
            formControlName="buyerFreeShipping"
          >
            <p class="p3 mb-0">Besplatna poštarina (Kupac)</p>
          </kadu-checkbox>
        </div>

        <div>
          <kadu-checkbox
            elClass="d-flex align-items-center gap-2"
            formControlName="paid"
          >
            <p class="p3 mb-0">Plaćeno</p>
          </kadu-checkbox>
        </div>

        <div>
          <kadu-checkbox
            elClass="d-flex align-items-center gap-2"
            formControlName="ransomCollected"
          >
            <p class="p3 mb-0">Uplaćen otkup</p>
          </kadu-checkbox>
        </div>

        <div formGroupName="deliveryInfo" class="d-flex flex-column mt-1">
          <p class="p3 mb-1">*Tip isporuke</p>

          <kadu-radiobutton
            name="fulfillmentType"
            class="p3"
            *ngFor="let fulfillmentType of fulfillmentTypes"
            [optionValue]="fulfillmentType.value"
            [label]="fulfillmentType.label"
            formControlName="fulfillmentType"
          ></kadu-radiobutton>

          <div
            *ngIf="
              formGroup.get('deliveryInfo.fulfillmentType').value ===
              'EXACT_DATE_DELIVERY'
            "
            class="mt-2"
          >
            <p class="p3 mb-1">*Datum tačne isporuke</p>

            <kadu-date-picker
              #deliveryDate
              [minDate]="today"
              formControlName="deliveryDate"
              [disabledDates]="disabledDates"
              [disabledDays]="[0, 6]"
              [showTime]="false"
            >
            </kadu-date-picker>

            <app-form-errors
              [formElement]="deliveryDate"
              [control]="formGroup.get('deliveryDate')"
            ></app-form-errors>
          </div>

          <ng-container
            *ngTemplateOutlet="priorityDeliveryTemplate"
          ></ng-container>
        </div>

        <div>
          <p class="p3 mb-1">Razlog odbijanja (REJECTED)</p>
          <kadu-textarea formControlName="rejectionReason"></kadu-textarea>
        </div>

        <div>
          <p class="p3 mb-1">Razlog odbijanja (NOT_DELIVERED)</p>
          <kadu-textarea formControlName="notDeliveredReason"></kadu-textarea>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <kadu-button class="btn btn-small btn-submit w-100" type="submit">
            Izmeni
          </kadu-button>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>
  <p class="p3">* Obavezna polja</p>
</div>

<ng-template #priorityDeliveryTemplate>
  <p
    class="fw-600 text-danger m-0"
    *ngIf="priorityDeliveryUnavailable$ | async"
  >
    Prioritetna isporuka nije dostupna.
  </p>
</ng-template>
