<div class="p-3">
  <kadu-table
    #table
    [columns]="cols"
    [rows]="20"
    [lazy]="true"
    [request]="designerRequest"
    [withFilters]="true"
    [sortable]="true"
  >
  </kadu-table>
</div>

<ng-template #actionsTemplate let-designer>
  <div class="d-flex gap-2 align-items-center justify-content-start">
    <kadu-button
      (clickHandler)="showData(designer)"
      class="btn btn-small btn-submit"
    >
      Prikaži podatke
    </kadu-button>
    <kadu-button
      *ngIf="!designer.accountBanned"
      (clickHandler)="banDesigner(designer)"
      class="btn btn-small btn-submit"
    >
      Banuj dizajnera
    </kadu-button>

    <kadu-button
      *ngIf="designer.accountBanned"
      (clickHandler)="unbanDesigner(designer)"
      class="btn btn-small btn-submit"
    >
      Ukloni ban dizajnera
    </kadu-button>
  </div>
</ng-template>
