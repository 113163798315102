import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { KaduTableComponent, PaginatedResult } from '@kadung/kadung/kadu-table';
import { Observable } from 'rxjs';
import { TableFilter } from 'src/app/_models/table-filter';
import { generateTableRequestParams } from 'src/app/_models/table-util';
import { PurchaseOrderService } from 'src/app/_services/purchase-order/purchase-order.service';
import { PurchaseOrder } from 'src/app/_view-models/purchase-order';
import { DialogService } from 'src/app/components/dialog/dialog.service';
import { PurchaseOrderSingleComponent } from '../purchase-order-single/purchase-order-single.component';
import { SubSink } from 'subsink';
import { PurchaseOrderDetailsComponent } from '../purchase-order-details/purchase-order-details.component';
import { PopupService } from 'src/app/components/popup/popup.service';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseOrderListComponent {
  @ViewChild('buttonsTemplate', {static: true}) buttonsTemplate: TemplateRef<any>;
  @ViewChild("table") table: KaduTableComponent;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: -1,
    sortField: "start_time",
    filters: {},
  };

  initialFilters = {
    finished: {
      value: [false],
      matchMode: "in",
    },
  };

  cols: any[];

  filtersMeta = {
    id: { key: "id", matchMode: "eq:" },
    created: { key: "created", matchMode: "btn:" },
    startTime: { key: "start_time", matchMode: "btn:" },
    endTime: { key: "end_time", matchMode: "btn:" },
    finished: {key: "finished", matchMode: "in:"},
    expectedCompletionTime: { key: "expected_completion_time", matchMode: "btn:" },
    stockItemType: {key: "stock_item_type", matchMode: "in:"}
  };

  purchaseOrdersRequest = ((
    filters: TableFilter
  ): Observable<PaginatedResult<PurchaseOrder>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...this.initialFilters,
        ...filters.filters,
      },
    };

    return this.purchaseOrderService
      .getAll(generateTableRequestParams(this.currentFilters, this.filtersMeta));
  }).bind(this);

  subs = new SubSink();

  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private dialogService: DialogService,
    private popupService: PopupService,
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "ID",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Datum kreiranja",
        field: "created",
        filterType: "date",
        type: "date",
        options: {
          format: "dd.MM.yyyy",
        },
      },
      {
        header: "Početak",
        field: "startTime",
        filterType: "date",
        type: "date",
        options: {
          format: "dd.MM.yyyy",
        },
      },
      {
        header: "Završetak",
        field: "endTime",
        filterType: "date",
        type: "date",
        options: {
          format: "dd.MM.yyyy",
        },
      },
      {
        header: "Završena",
        field: "finished",
        filterType: "select",
        type: "boolean",
        options: {
          optionValues: [
            {
              label: "Da",
              value: true,
            },
            {
              label: "Ne",
              value: false,
            },
          ],
          initialValues: [false],
        },
      },
      {
        header: "Očekivano vreme izrade (dan)",
        field: "expectedCompletionTime",
        filterType: "number",
        type: "normal",
      },
      {
        header: "Ispunjenost (%)",
        field: "fulfillmentPercentage",
        filterType: "number",
        type: "normal",
      },
      {
        header: "Tip",
        field: "stockItemType",
        filterType: "select",
        type: "normal",
        options: {
          optionValues: [
            { value: "PRODUCT_TYPE_ITEM", label: "Roba" },
            { value: "RAW_MATERIAL", label: "Repromaterijal" },
          ],
        },
      },
      {
        header: "Excel",
        field: "fileUrl",
        type: "file",
        options: {
          icon: 'fa fa-download'
        }
      },
      {
        header: "Akcije",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.buttonsTemplate
        }
      },
    ];
  }

  setCurrentFilters(event): any {
    this.currentFilters = event;
  }

  viewPurchaseOrder(purchaseOrder: PurchaseOrder): any {
    const dialog = this.dialogService.open(PurchaseOrderDetailsComponent, {
      width: "90%",
      height: "90%",
      data: {
        purchaseOrder
      }
    });

    this.subs.add(
      dialog.onClose.subscribe((res) => {
        this.table.load(this.currentFilters);
      })
    );
  }

  createPurchaseOrder(): any {
    const dialog = this.dialogService.open(PurchaseOrderSingleComponent, {
      width: "70%",
      height: "90%",
    });

    this.subs.add(dialog.onClose.subscribe((res) => {
      if (res) {
        this.table.load(this.currentFilters);
      }
    }));
  }

  deletePurchaseOrder(purchaseOrder: PurchaseOrder): any {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da obrišete porudžbenicu ${purchaseOrder.id}?`,
        type: "warning",
        showCancelButton: true,
      }).then((res) => {
        if (res.value) {
          this.purchaseOrderService.delete(purchaseOrder.id).subscribe(() => {
            this.popupService.fireToast({
              title: `Uspešno obrisana porudžbenica ${purchaseOrder.id}`
            });
            this.table.load(this.currentFilters);
          },  (error) => {
            this.popupService
            .fire({
              title: `Došlo je do greške`,
              type: "error",
              text: error.error.message
            });
          });
        }
    });
  }
}
