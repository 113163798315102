<div class="d-flex justify-content-center">
  <kadu-form
    class="d-flex w-50"
    [controls]="formControls"
    (submitHandler)="create($event)"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <div class="d-block d-flex flex-column justify-content-start gap-3">
          <div>
            <p class="p3 mb-1">*Datum početka</p>
            <kadu-date-picker
              #startTime
              class="p3"
              [showTime]="false"
              formControlName="startTime"
            ></kadu-date-picker>
            <app-form-errors
              [formElement]="startTime"
              [control]="formGroup.get('startTime')"
            ></app-form-errors>
          </div>

          <div>
            <p class="p3 mb-1">*Očekivano vreme izrade</p>
            <kadu-input
              #expectedCompletionTime
              class="p3"
              formControlName="expectedCompletionTime"
            ></kadu-input>
            <app-form-errors
              [formElement]="expectedCompletionTime"
              [control]="formGroup.get('expectedCompletionTime')"
            ></app-form-errors>
          </div>

          <div>
            <p class="p3 mb-1">*Komentar</p>
            <kadu-textarea
              #note
              class="p3"
              formControlName="note"
            ></kadu-textarea>
            <app-form-errors
              [formElement]="note"
              [control]="formGroup.get('note')"
            ></app-form-errors>
          </div>

          <div>
            <kadu-file-upload
              #file
              class="btn p3"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              chooseLabel="Unesi porudžbenicu"
              formControlName="file"
              [defaultFileTemplate]="true"
            >
            </kadu-file-upload>

            <app-form-errors
              [formElement]="file"
              [control]="formGroup.get('file')"
            ></app-form-errors>
          </div>

          <div>
            <p class="p3 mb-1">*Tip</p>
            <kadu-select
              class=""
              formControlName="stockItemType"
              [options]="stockItemTypeOptions"
            ></kadu-select>
            <app-form-errors
              [formElement]="stockItemTypeOptions"
              [control]="formGroup.get('stockItemTypeOptions')"
            ></app-form-errors>
          </div>

          <kadu-button class="btn btn-small btn-submit w-100" type="submit">
            Kreiraj
          </kadu-button>

          <p class="p3">* Obavezna polja</p>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>
</div>
