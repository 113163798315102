import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from 'src/app/_interceptors/skip-interceptors';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  baseUrl = 'backoffice/address/';
  constructor(private http: HttpClient) { }


  filterAddresses(name: string, shippingCarrierCode: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}`, {
      params: {
        name,
        shippingCarrierCode
      },
      headers: new HttpHeaders().set(SKIP_DTO_TRANSFORM_INTERCEPTOR, SKIP_DTO_TRANSFORM_INTERCEPTOR)
    })
  }
}
