<ng-template #additionalFiltersTemplate>
  <kadu-form
    #filtersForm
    [controls]="filterFormControls"
    elClass="d-flex flex-column"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <div>
          <kadu-input
            formControlName="email"
            class="mb-3"
            placeholder="Email"
          ></kadu-input>

          <kadu-input
            formControlName="phoneNumber"
            class="mb-3"
            placeholder="Broj telefona"
          ></kadu-input>

          <div class="w-100 mb-3">
            <span class="p3">Datum izrade</span>
            <kadu-date-picker
              class="p-0"
              formControlName="manufacturingDate"
              [showTime]="false"
            >
            </kadu-date-picker>
          </div>

          <div class="w-100">
            <div class="p3">Partner</div>
            <kadu-select
              class="p-0"
              formControlName="partnerId"
              [multiple]="true"
              optionLabel="name"
              optionValue="id"
              [options]="partners | async"
              [selectedItemsLabel]="'Partner ({0})'"
            ></kadu-select>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>
</ng-template>

<div class="container-fluid">
  <div class="row m-2 mb-4">
    <div class="col-12 col-md-4">
      <kadu-accordion
        class="d-block"
        [tabs]="[
          {
            header: 'Dodatni filteri',
            content: additionalFiltersTemplate,
            display: true,
            selected: false
          }
        ]"
      ></kadu-accordion>
    </div>
  </div>
</div>

<ng-container *ngTemplateOutlet="bulkActionButtons"></ng-container>

<ng-template #orderActionsTemplate let-order let-index="index">
  <ng-container *ngIf="type === 'accept'">
    <div class="d-flex justify-content-around align-items-center gap-2">
      <kadu-button
        class="btn btn-small btn-submit m-1"
        (clickHandler)="acceptOrder(order)"
      >
        Prihvati
      </kadu-button>
      <kadu-button
        class="btn btn-small btn-delete m-1"
        (clickHandler)="rejectOrder(order)"
      >
        Odbij
      </kadu-button>

      <kadu-button
        class="btn btn-small btn-cancel m-1"
        (clickHandler)="editDetails(order)"
      >
        Izmeni
      </kadu-button>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'finish'">
    <div class="d-flex flex-column justify-content-start">
      <kadu-button
        class="btn btn-small btn-active m-1"
        (clickHandler)="finishSuccessfully(order)"
      >
        Uspešno
      </kadu-button>

      <kadu-button
        class="btn btn-small btn-submit m-1"
        (clickHandler)="editDetails(order)"
      >
        Izmeni porudžbinu
      </kadu-button>

      <kadu-button
        class="btn btn-small btn-danger m-1"
        (clickHandler)="finishUnsuccessfully(order)"
      >
        Neuspešno
      </kadu-button>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'all'">
    <div class="d-flex flex-column justify-content-start">
      <kadu-button
        class="btn btn-small btn-submit m-1"
        (clickHandler)="editDetails(order)"
      >
        Izmeni porudžbinu
      </kadu-button>

      <kadu-button
        class="btn btn-small btn-active m-1"
        (clickHandler)="finishSuccessfully(order)"
      >
        Uspešno završi
      </kadu-button>

      <kadu-button
        class="btn btn-small btn-danger m-1"
        (clickHandler)="finishUnsuccessfully(order)"
      >
        Neuspešno završi
      </kadu-button>

      <kadu-button
        class="btn btn-small btn-submit m-1"
        (clickHandler)="acceptOrder(order)"
      >
        Prihvati
      </kadu-button>
      <kadu-button
        class="btn btn-small btn-delete m-1"
        (clickHandler)="rejectOrder(order)"
      >
        Odbij
      </kadu-button>
    </div>
  </ng-container>
</ng-template>

<kadu-table
  #table
  [disableEditableColumnKeyboardNavigation]="true"
  [selectedValues]="selectedOrders"
  selectionMode="select"
  [selectable]="true"
  [columns]="cols"
  [rows]="40"
  [lazy]="true"
  [request]="orderRequest"
  [expandable]="true"
  [expandedTemplate]="expandedTemplate"
  [withFilters]="true"
  [loading]="true"
  [customLoader]="customLoader"
  (rowExpandHandler)="expandRow($event)"
  (rowUnselectHandler)="getSelectedOrders($event.values)"
  (rowCollapseHandler)="collapseRow($event)"
  (rowSelectHandler)="getSelectedOrders($event.values)"
  (rowSelectAllHandler)="getSelectedOrders($event.values)"
  (editInitHandler)="setEditingOrders($event)"
  (onChange)="setCurrentFilters($event)"
  [sortable]="true"
  [emptyMessageTemplate]="emptyMessage"
>
  <ng-template #emptyMessage>
    <p class="m-0 text-start ps-2">Nema dostupnih podataka</p>
  </ng-template>
  <ng-template #expandedTemplate let-order>
    <div class="d-flex flex-wrap">
      <div class="row mx-0 w-100">
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col-4">
              <h4 class="text-start p1">Kupac</h4>
              <kadu-data-card
                [items]="[
                  { label: 'Napomena', value: order.note },
                  { label: 'Komentar za poštara', value: order.comment },
                  {
                    label: 'Poštanski broj',
                    value:
                      order.postalCode +
                      (isKiM(order.postalCode) ? '- KiM' : '')
                  },
                  { label: 'Grad', value: order.city },
                  { label: 'Adresa', value: order.address },
                  { label: 'Email', value: order.email },
                  { label: 'Broj telefona', value: order.phoneNumber }
                ]"
              ></kadu-data-card>
            </div>

            <div class="col-4">
              <h4 class="text-start p1">Info</h4>

              <kadu-data-card
                [items]="[
                  { label: 'Broj pošiljke', template: deliveryCodeTemplate },
                  {
                    label: 'Broj dana u pošti',
                    value: order.daysInShipping
                  },
                  {
                    label: 'Datum izrade',
                    value: order.manufacturingDate | date : 'dd/MM/yyyy'
                  },
                  {
                    label: 'Poštarina (kupac)',
                    value: order.buyerShippingCost
                  },
                  {
                    label: 'Poštarina (dizajner)',
                    value: order.baseShippingCost
                  },
                  { label: 'Bazna cena', value: order.basePrice },
                  { label: 'Otkup', value: order.fullPrice },
                  {
                    label: 'Tip isporuke',
                    value: order.deliveryInfo.fulfillmentType
                  },
                  {
                    label: 'Prethodna porudžbina',
                    value: order.lastOrderId,
                    condition: order.lastOrderId !== '-1'
                  },
                  {
                    label: 'Naredna porudžbina',
                    value: order.newOrderId,
                    condition: order.newOrderId !== '-1'
                  }
                ]"
              ></kadu-data-card>

              <ng-template #deliveryCodeTemplate>
                <div
                  style="background: none"
                  class="d-flex justify-content-center align-items-center flex-column"
                >
                  {{ order.deliveryCode }}
                  <ng-container
                    *ngIf="
                      order.deliveryCode &&
                      (order.state === 'READY_FOR_SHIPPING' ||
                        order.state === 'SHIPPING' ||
                        order.state === 'DELIVERED' ||
                        order.state === 'NOT_DELIVERED' ||
                        order.state === 'CHARGED')
                    "
                  >
                    <ng-container [ngSwitch]="order.shippingCarrierCode">
                      <app-posta-order-check
                        *ngSwitchCase="'POSTA'"
                        [deliveryCode]="order.deliveryCode"
                      ></app-posta-order-check>
                      <app-bex-order-check
                        *ngSwitchCase="'BEX'"
                        [deliveryCode]="order.deliveryCode"
                      ></app-bex-order-check>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-template>
            </div>

            <div class="col-4">
              <h4 class="text-start p1">Dizajner</h4>

              <kadu-data-card
                [items]="[
                  {
                    label: 'Prodavnica',
                    template: shopTemplate
                  },
                  {
                    label: 'Broj računa za uplatu',
                    value: order.sellerBankAccountNumber
                      ? order.sellerBankAccountNumber
                      : order.iban
                  },
                  {
                    label: 'Broj telefona',
                    value: order.shopOwnerPhoneNumber
                  },
                  { label: 'Email', value: order.shopOwnerEmail },
                  {
                    label: 'Ime',
                    value:
                      order.shopOwnerFirstName + ' ' + order.shopOwnerLastName
                  }
                ]"
              ></kadu-data-card>

              <ng-template #shopTemplate>
                <a
                  style="background: none; color: blue"
                  [href]="order.eCommerceUrl"
                  target="_blank"
                >
                  {{ order.shopName }}
                </a>
              </ng-template>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4 class="text-start p1">Dizajnovi</h4>
              <div class="row">
                <kadu-table
                  dataKey="id"
                  [expandedRowKeys]="mapValues(order)"
                  [columns]="orderItemListCols"
                  [rows]="5"
                  [values]="order.orderItemList"
                  [loading]="false"
                  [expandable]="true"
                  [expandedTemplate]="expandedTemplate"
                >
                  <ng-template let-oi #expandedTemplate>
                    <div class="d-flex flex-wrap col-12">
                      <div
                        [ngClass]="{
                          'bg-success': pi.fromManufacturedProductId
                        }"
                        *ngFor="let pi of oi.productImages"
                        class="d-flex gap-2 flex-row align-items-center col-4 pi"
                      >
                        <kadu-data-card
                          class="col-6"
                          [items]="[
                            { label: 'Id', value: pi.id },
                            {
                              label: 'Id strane printa',
                              value: pi.productTypePartId
                            },
                            { label: 'Strana', value: pi.productTypePartName },
                            { label: 'Tip printa', value: pi.printTypeName },
                            {
                              label: 'Veličina printa',
                              value: pi.printSizeName
                            }
                          ]"
                        >
                        </kadu-data-card>

                        <ng-container *ngIf="pi.canvasImageUrl">
                          <div
                            class="d-flex flex-column align-items-center my-2"
                          >
                            <img
                              height="100px"
                              width="100px"
                              [src]="pi.productImageUrl"
                              alt="Slika"
                            />

                            <a
                              class="p2"
                              [href]="pi.canvasImageUrl"
                              target="_blank"
                            >
                              Proveri dizajn
                            </a>
                          </div>
                        </ng-container>
                        <h6 class="text-start p2" *ngIf="!pi.canvasImageUrl">
                          Bez dizajna
                        </h6>
                      </div>
                    </div>
                  </ng-template>
                </kadu-table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <kadu-accordion
            class="d-block mb-3"
            [tabs]="[
              {
                header: 'Ostale porudžbine',
                content: otherOrdersTemplate,
                data: order,
                display: true,
                selected: true
              },
              {
                header: 'Komentari',
                content: commentsTemplate,
                data: order,
                display: true,
                selected: false
              },
              {
                header: 'Akcije',
                content: actionsTemplate,
                data: order,
                display: true,
                selected: false
              },
              {
                header: 'Dostupnost prioritetne isporuke',
                content: priorityDeliveryTemplate,
                data: order,
                display: true,
                selected: false
              },
              {
                header: 'Promene stanja',
                content: orderStateTransitionsTemplate,
                data: order,
                display: true,
                selected: false
              }
            ]"
            [multiple]="true"
            [activeIndex]="[0, 1]"
          ></kadu-accordion>
          <kadu-button
            class="btn btn-small btn-submit w-100"
            (clickHandler)="showData(order)"
          >
            Prikaži detalje porudžbine
          </kadu-button>
        </div>
      </div>
    </div>
  </ng-template>
</kadu-table>

<ng-template #commentsTemplate let-order>
  <div *ngFor="let comment of order.data.comments" class="mb-2">
    <div class="d-flex justify-content-between fw-600">
      <p class="m-0 p2">{{ comment.author }}</p>
      <p class="m-0 p2">{{ comment.created | date : "dd/MM/yyyy HH:mm" }}</p>
    </div>
    <div class="d-flex">
      <pre class="p3 mb-1 text-start">{{ comment.value }}</pre>
    </div>
    <div
      class="d-flex justify-content-end gap-2 align-items-center fw-600 comment-buttons"
    >
      <kadu-button
        class="btn btn-small btn-submit"
        (clickHandler)="editComment(order, comment)"
      >
        Izmeni
      </kadu-button>
      <kadu-button
        class="btn btn-small btn-delete"
        (clickHandler)="deleteComment(order, comment)"
      >
        Obriši
      </kadu-button>
    </div>
  </div>

  <kadu-form
    #commentForm
    [controls]="commentFormControls"
    elClass="py-5 h-100 d-flex flex-column justify-content-evenly align-items-center"
    (submitHandler)="submitComment(order, $event)"
  >
    <ng-template kaduTemplate let-formGroup>
      <ng-container [formGroup]="formGroup">
        <kadu-input
          #author
          elClass="p-2 p3"
          formControlName="author"
          [isDisabled]="editCommentIds[order.data.id]"
          placeholder="Komentator"
        ></kadu-input>
        <app-form-errors
          [formElement]="author"
          [control]="formGroup.get('author')"
        ></app-form-errors>
        <kadu-input
          #value
          elClass="p-2 p3"
          formControlName="value"
          placeholder="Komentar"
        ></kadu-input>
        <app-form-errors
          [formElement]="value"
          [control]="formGroup.get('value')"
        ></app-form-errors>

        <kadu-button
          class="w-100"
          type="submit"
          class="w-100 btn btn-small btn-submit"
        >
          {{ editCommentIds[order.data.id] ? "Izmeni" : "Dodaj" }}
        </kadu-button>

        <div *ngIf="editCommentIds[order.data.id]" class="w-100 mx-0 mt-2">
          <kadu-button
            class="w-100 btn btn-small btn-cancel"
            (clickHandler)="exitEditCommentMode(order)"
          >
            Odustani
          </kadu-button>
        </div>
      </ng-container>
    </ng-template>
  </kadu-form>
</ng-template>

<ng-template #otherOrdersTemplate let-order>
  <div class="p-3" style="min-height: 320px">
    <kadu-table
      [columns]="otherOrdersCols"
      [rows]="5"
      [lazy]="true"
      [request]="loadOtherOrders(order)"
      [expandable]="true"
      [expandedTemplate]="ooExpandedTemplate"
    >
      <ng-template #ooExpandedTemplate let-otherOrder>
        <kadu-data-card
          [items]="[
            {
              label: 'Poštanski broj',
              value:
                otherOrder.postalCode +
                (isKiM(otherOrder.postalCode) ? '- KiM' : '')
            },
            { label: 'Grad', value: otherOrder.city },
            { label: 'Adresa', value: otherOrder.address },
            { label: 'Email', value: otherOrder.email },
            { label: 'Broj telefona ', value: otherOrder.phoneNumber },
            { label: 'Otkup', value: otherOrder.fullPrice }
          ]"
        ></kadu-data-card>

        <div
          class="d-flex align-items-center gap-2 my-2 border-bottom"
          *ngFor="let orderItem of otherOrder.orderItemList"
        >
          <div class="col-4">
            <img
              *ngIf="
                orderItem.productImages && orderItem.productImages.length > 0
              "
              [src]="orderItem.productImages[0].productImageUrl"
              alt="Slika"
              style="height: 130px; width: 130px"
            />
          </div>
          <div class="col-9 p3 text-start">
            <h6>Id - {{ orderItem.id }}</h6>
            <h6>Proizvod - {{ orderItem.productName }}</h6>
            <h6>Tip proizvoda - {{ orderItem.productTypeName }}</h6>
            <h6>Veličina - {{ orderItem.productSize }}</h6>
            <h6>Boja - {{ orderItem.productColor }}</h6>
            <h6>Cena - {{ orderItem.productPrice }}</h6>
          </div>
        </div>
      </ng-template>
    </kadu-table>
  </div>
</ng-template>

<ng-template #actionsTemplate let-order>
  <div class="row">
    <div class="col-12 pt-2">
      <kadu-input #orderId regex="pnum"></kadu-input>
      <div class="pt-2">
        <kadu-button
          class="w-100 btn btn-small btn-submit"
          [isDisabled]="!orderId.value"
          (clickHandler)="mergeOrders(order.data.id, orderId.value)"
        >
          Spoji sa porudžbinom
        </kadu-button>
      </div>
    </div>
    <ng-container [ngSwitch]="order.data.shippingCarrierCode">
      <app-posta-order-actions
        *ngSwitchCase="'POSTA'"
        [order]="order.data"
        (actionHandler)="onAction($event)"
      ></app-posta-order-actions>
      <app-bex-order-actions
        *ngSwitchCase="'BEX'"
        [order]="order.data"
        (actionHandler)="onAction($event)"
      ></app-bex-order-actions>
    </ng-container>
  </div>
</ng-template>

<ng-template #priorityDeliveryTemplate let-order>
  <div class="row">
    <div class="col-12 p-2">
      <div *ngIf="order.priorityDelivery?.length > 0">
        <kadu-table
          *ngIf="order.data.priorityDelivery"
          [columns]="orderPriorityDeliveryCols"
          [values]="order.data.priorityDelivery"
          [loading]="false"
        >
        </kadu-table>
      </div>

      <div *ngIf="order.data.priorityDelivery?.length === 0">
        <p style="font-weight: bold; font-size: 15px; color: green">
          Prioritetna isporuka je dostupna.
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #orderStateTransitionsTemplate let-order>
  <div class="p-2">
    <kadu-table
      *ngIf="order.data.stateTransitions"
      [columns]="orderStateTransitionsCols"
      [rows]="8"
      [values]="order.data.stateTransitions"
      [loading]="false"
    >
    </kadu-table>
  </div>
</ng-template>

<ng-container *ngTemplateOutlet="bulkActionButtons"></ng-container>

<ng-template #bulkActionButtons>
  <div class="d-flex ms-2 justify-content-between my-2 align-items-center">
    <div class="d-flex ms-2 gap-2" *ngIf="type === 'accept'">
      <kadu-button
        (clickHandler)="bulkAccept()"
        class="btn btn-small btn-submit"
        [isDisabled]="selectedOrdersIdList.length === 0"
      >
        Prihvati
      </kadu-button>
      <kadu-button
        (clickHandler)="bulkReject()"
        class="btn btn-small btn-cancel"
        [isDisabled]="selectedOrdersIdList.length === 0"
      >
        Odbij
      </kadu-button>
    </div>
    <h4 class="d-flex justify-content-end me-3 p1">
      Broj porudžbina: {{ totalResults$ | async }}
    </h4>
  </div>
</ng-template>

<ng-template #stateDefaultTemplate>
  <p class="m-0">
    {{ type === "accept" ? "PENDING" : "SHIPPING" }}
  </p>
</ng-template>

<ng-template let-oi #orderItemListActionTemplate>
  <kadu-button
    class="btn btn-small btn-submit my-2 w-100"
    (clickHandler)="changeOrderItem(oi)"
  >
    Izmeni stavku
  </kadu-button>
</ng-template>
