import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/_interceptors/loading-spinner.interceptor";
import { PaginatedResult } from "../../_models/paginated-result";
import { Reclamation } from "../../_view-models/reclamation";

@Injectable({
  providedIn: "root",
})
export class ReclamationService {
  baseUrl = "backoffice/reclamations";
  constructor(private http: HttpClient) {}

  getAll(params: any): Observable<PaginatedResult<Reclamation>> {
    return this.http
      .get<PaginatedResult<Reclamation>>(this.baseUrl, {
        params,
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR
        ),
      })
      .pipe(
        map((pr: PaginatedResult<Reclamation>) => {
          return {
            ...pr,
            currentResults: pr.currentResults
              ? pr.currentResults.map((r: Reclamation) => {
                  return {
                    ...r,
                    name: r.firstName + " " + r.lastName,
                    reclamationItems: r["reclamationItemList"].map((ri) => {
                      return {
                        ...ri,
                        change: false,
                        processed: r["processed"],
                      };
                    }),
                  };
                })
              : [],
          };
        })
      );
  }

  finish(reclamation): Observable<any> {
    // let mm = (reclamation.manufacturingDate.getMonth() + 1) + '';
    // let dd = reclamation.manufacturingDate.getDate() + '';
    // if (mm.length === 1) {
    //   mm = '0' + mm;
    // }
    // if (dd.length === 1) {
    //   dd = '0' + dd;
    // }
    // const yy = reclamation.manufacturingDate.getFullYear();
    // reclamation.manufacturing_date = yy + '-' + mm + '-' + dd + '-00-00-00';
    // delete reclamation.manufacturingDate;
    return this.http.put(this.baseUrl, reclamation);
  }
}
