import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginatedResult } from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";
import { PrintType } from "src/app/_view-models/print-type";

@Injectable({
  providedIn: "root",
})
export class PrintTypeService {
  baseUrl = "backoffice/print_types";

  constructor(private http: HttpClient) {}

  getAll(): Observable<PrintType[]> {
    return this.http.get<PrintType[]>(`${this.baseUrl}`);
  }

  getAllPaginated(params: any): Observable<PaginatedResult<any[]>> {
    return this.http.get<PaginatedResult<any[]>>(`${this.baseUrl}/paginated`, {
      params,
    });
  }

  deletePrintType(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  editPrintType(printType: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/edit/${printType.id}`, printType);
  }

  addPrintType(printType: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/add`, printType);
  }
}
