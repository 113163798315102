import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  baseUrl = 'backoffice/comments';
  constructor(private http: HttpClient) { }

  getAll(orderId: number): Observable<Comment[]> {
    return this.http.get<Comment[]>(`${this.baseUrl}`, {
     params: {
       order_id: orderId + '',
       sort_by: 'created',
       sort_order: 'asc'
     }
    });
  }

  add(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  edit(commentId: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${commentId}`, data);
  }

  delete(commentId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${commentId}`);
  }
}
