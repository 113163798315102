import * as moment from 'moment-timezone';

function convertToFormDataRecursive(
  formData: FormData,
  fileFields: string[],
  object: any,
  namespace = null
): void {
  Object.keys(object).forEach((objectKey): void => {
    let key = namespace ? namespace + "." + objectKey + "" : objectKey;
    if (
      object[objectKey] &&
      typeof object[objectKey] === "object" &&
      !(object[objectKey] instanceof Date) &&
      !(object[objectKey][0] instanceof File)
    ) {
      convertToFormDataRecursive(formData, fileFields, object[objectKey], key);
    } else {
      if (fileFields.includes(objectKey)) {
        if (object[objectKey]) {
          object[objectKey].forEach((file): void => {
            formData.append(key, file, file.name);
          });
        }
      } else {
        if(object[objectKey] instanceof Date) {
          formData.append(key, moment.tz(object[objectKey], "UTC").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
        } else {
          formData.append(key, object[objectKey]);
        }
      }
    }
  });
}

export function convertToFormData(
  object: any,
  fileFields: string[] = [],
): FormData {
  const formData = new FormData();
  convertToFormDataRecursive(formData, fileFields, object, null);
  return formData;
}
