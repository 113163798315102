<kadu-form
  elClass="p-3 mt-2"
  [controls]="formControls"
  (submitHandler)="save($event)"
>
  <ng-template kaduTemplate let-formGroup>
    <ng-container [formGroup]="formGroup">
      <div>
        <div>
          <label class="p3">Ime firme</label>
          <kadu-input elClass="p3" formControlName="name" #name></kadu-input>
          <app-form-errors
            [formElement]="name"
            [control]="formGroup.get('name')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="firstName">Ime partnera</label>
          <kadu-input
            elClass="p3"
            formControlName="firstName"
            #firstName
          ></kadu-input>
          <app-form-errors
            [formElement]="firstName"
            [control]="formGroup.get('firstName')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="lastName">Prezime partnera</label>
          <kadu-input
            elClass="p3"
            formControlName="lastName"
            #lastName
          ></kadu-input>
          <app-form-errors
            [formElement]="lastName"
            [control]="formGroup.get('lastName')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="address">Adresa</label>
          <kadu-input
            elClass="p3"
            formControlName="address"
            #address
          ></kadu-input>
          <app-form-errors
            [formElement]="address"
            [control]="formGroup.get('address')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="city">Grad</label>
          <kadu-input elClass="p3" formControlName="city" #city></kadu-input>
          <app-form-errors
            [formElement]="city"
            [control]="formGroup.get('city')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="country">Država</label>
          <kadu-input
            elClass="p3"
            formControlName="country"
            #country
          ></kadu-input>
          <app-form-errors
            [formElement]="country"
            [control]="formGroup.get('country')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="email">Email</label>
          <kadu-input elClass="p3" formControlName="email" #email></kadu-input>
          <app-form-errors
            [formElement]="email"
            [control]="formGroup.get('email')"
            [Errors]="{ pattern: 'Email nije ispravno formata' }"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="password">Lozinka</label>
          <kadu-input
            type="password"
            elClass="p3"
            formControlName="password"
            #password
          ></kadu-input>
          <app-form-errors
            [formElement]="password"
            [control]="formGroup.get('password')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="phoneNumber">Broj telefona</label>
          <kadu-input
            elClass="p3"
            formControlName="phoneNumber"
            #phoneNumber
          ></kadu-input>
          <app-form-errors
            [formElement]="phoneNumber"
            [control]="formGroup.get('phoneNumber')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="username">Korisničko ime</label>
          <kadu-input
            elClass="p3"
            formControlName="username"
            #username
          ></kadu-input>
          <app-form-errors
            [formElement]="username"
            [control]="formGroup.get('username')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="dispatchOrdersNumber">Broj ON</label>
          <kadu-input
            elClass="p3"
            formControlName="dispatchOrdersNumber"
            #dispatchOrdersNumber
          ></kadu-input>
          <app-form-errors
            [formElement]="dispatchOrdersNumber"
            [control]="formGroup.get('dispatchOrdersNumber')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="dispatchOrdersAddress">Adresa ON</label>
          <kadu-input
            elClass="p3"
            formControlName="dispatchOrdersAddress"
            #dispatchOrdersAddress
          ></kadu-input>
          <app-form-errors
            [formElement]="dispatchOrdersAddress"
            [control]="formGroup.get('dispatchOrdersAddress')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="dispatchOrdersCity">Grad ON</label>
          <kadu-input
            elClass="p3"
            formControlName="dispatchOrdersCity"
            #dispatchOrdersCity
          ></kadu-input>
          <app-form-errors
            [formElement]="dispatchOrdersCity"
            [control]="formGroup.get('dispatchOrdersCity')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="dispatchOrdersPostalCode"
            >Poštanski broj ON</label
          >
          <kadu-input
            elClass="p3"
            formControlName="dispatchOrdersPostalCode"
            #dispatchOrdersPostalCode
          ></kadu-input>
          <app-form-errors
            [formElement]="dispatchOrdersPostalCode"
            [control]="formGroup.get('dispatchOrdersPostalCode')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="returnOrdersNumber">Broj VN</label>
          <kadu-input
            elClass="p3"
            formControlName="returnOrdersNumber"
            #returnOrdersNumber
          ></kadu-input>
          <app-form-errors
            [formElement]="returnOrdersNumber"
            [control]="formGroup.get('returnOrdersNumber')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="returnOrdersAddress">Adresa VN</label>
          <kadu-input
            elClass="p3"
            formControlName="returnOrdersAddress"
            #returnOrdersAddress
          ></kadu-input>
          <app-form-errors
            [formElement]="returnOrdersAddress"
            [control]="formGroup.get('returnOrdersAddress')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="returnOrdersCity">Grad VN</label>
          <kadu-input
            elClass="p3"
            formControlName="returnOrdersCity"
            #returnOrdersCity
          ></kadu-input>
          <app-form-errors
            [formElement]="returnOrdersCity"
            [control]="formGroup.get('returnOrdersCity')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="returnOrdersPostalCode"
            >Poštanski broj VN</label
          >
          <kadu-input
            elClass="p3"
            formControlName="returnOrdersPostalCode"
            #returnOrdersPostalCode
          ></kadu-input>
          <app-form-errors
            [formElement]="returnOrdersPostalCode"
            [control]="formGroup.get('returnOrdersPostalCode')"
          ></app-form-errors>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-2 mt-2">
        <kadu-button (clickHandler)="close()" class="btn btn-cancel btn-small">
          Odustani
        </kadu-button>
        <kadu-button type="submit" class="btn btn-submit btn-small">
          Sačuvaj
        </kadu-button>
      </div>
    </ng-container>
  </ng-template>
</kadu-form>
