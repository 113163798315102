import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Partner } from "../../_view-models/partner";
import { map } from "rxjs/operators";
import { PaginatedResult } from "@kadung/kadung/kadu-table";

@Injectable({
  providedIn: "root",
})
export class PartnerService {
  baseUrl = "backoffice/partners";
  constructor(private http: HttpClient) {}

  getAll(): Observable<Partner[]> {
    return this.http.get<Partner[]>(`${this.baseUrl}`).pipe(
      map((partners: any[]) => {
        return partners.sort((a, b) => +a.id - +b.id);
      })
    );
  }

  getAllPaginated(params: any): Observable<PaginatedResult<any[]>> {
    return this.http.get<PaginatedResult<any[]>>(`${this.baseUrl}/paginated`, {
      params,
    });
  }

  deletePartner(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  editPartner(partner: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/edit/${partner.id}`, partner);
  }

  addPartner(partner: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/add`, partner);
  }
}
