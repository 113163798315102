<kadu-form
  elClass="p-3 mt-2"
  [controls]="formControls"
  (submitHandler)="save($event)"
>
  <ng-template kaduTemplate let-formGroup>
    <ng-container [formGroup]="formGroup">
      <div>
        <div>
          <label class="p3">Ime</label>
          <kadu-input #name elClass="p3" formControlName="name"></kadu-input>
          <app-form-errors
            [formElement]="name"
            [control]="formGroup.get('name')"
          ></app-form-errors>
        </div>

        <div>
          <label class="p3">Code</label>
          <kadu-input #code elClass="p3" formControlName="code"></kadu-input>
          <app-form-errors
            [formElement]="code"
            [control]="formGroup.get('code')"
          ></app-form-errors>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-2 mt-2">
        <kadu-button (clickHandler)="close()" class="btn btn-cancel btn-small">
          Odustani
        </kadu-button>
        <kadu-button type="submit" class="btn btn-submit btn-small">
          Sačuvaj
        </kadu-button>
      </div>
    </ng-container>
  </ng-template>
</kadu-form>
