import { DialogModule as PrimeNgDialogModule } from "primeng/dialog";
import {
  DynamicDialogModule,
  DialogService as PrimeNgDialogService,
} from "primeng/dynamicdialog";
import { SharedModule } from "../shared.module";
import { DialogService } from "./dialog.service";

import { NgModule } from "@angular/core";

@NgModule({
  imports: [SharedModule, PrimeNgDialogModule, DynamicDialogModule],
  providers: [PrimeNgDialogService, DialogService],
})
export class DialogModule {}
