<kadu-tabs orientation="horizontal" [tabs]="tabs"></kadu-tabs>

<ng-template #suppliesTemplate>
  <kadu-table
    [sortable]="false"
    #supplyTable
    [columns]="cols"
    [rows]="20"
    [dataKey]="'id'"
    [expandable]="true"
    [expandedTemplate]="expandedTemplate"
    [request]="supplies"
    [lazy]="true"
    [withFilters]="true"
  >
    <ng-template #expandedTemplate let-supply>
      <div class="p-3">
        <kadu-table
          [withFilters]="true"
          [sortable]="true"
          [columns]="stockUpdateCols"
          [rows]="10"
          [lazy]="true"
          [requestParam]="supply"
          [request]="stockUpdateLogRequest"
          (onChange)="setSupplyFilters(supply, $event)"
        >
        </kadu-table>
      </div>
    </ng-template>
  </kadu-table>
</ng-template>

<ng-template #actionsTemplate let-rowData>
  <kadu-button
    class="btn btn-small btn-submit"
    (clickHandler)="addSupplies(rowData)"
  >
    Dodaj nove zalihe
  </kadu-button>
</ng-template>

<ng-template #stockUpdateLogsTemplate>
  <kadu-table
    [columns]="stockUpdateLogsCols"
    [rows]="20"
    [lazy]="true"
    [request]="stockUpdateLogsRequest"
    (onChange)="setStockUpdateLogFilters($event)"
    [withFilters]="true"
    [sortable]="true"
  ></kadu-table>
</ng-template>

<ng-template #manufacturedProductsTemplate>
  <div class="mb-2">
    <kadu-button
      class="btn btn-small btn-submit"
      (clickHandler)="addManufacturedProducts()"
    >
      Dodaj nove
    </kadu-button>
  </div>

  <kadu-table
    #manufacturedProductsTable
    [withFilters]="true"
    [sortable]="true"
    [columns]="manufacturedProductsCols"
    [rows]="20"
    dataKey="id"
    [lazy]="true"
    [request]="manufacturedProductsRequest"
    (onChange)="setManufacturedProductFilters($event)"
  >
  </kadu-table>
</ng-template>

<ng-template #manufacturedProductsActions let-rowData>
  <div class="d-flex align-items-center gap-2 justify-content-center">
    <kadu-button
      class="btn btn-small btn-delete"
      (clickHandler)="removeManufacturedProduct(rowData)"
    >
      Obriši
    </kadu-button>
    <kadu-button
      class="btn btn-small btn-submit"
      (clickHandler)="editManufacturedProduct(rowData)"
    >
      Izmeni
    </kadu-button>
  </div>
</ng-template>
