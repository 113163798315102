import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SKIP_LOADING_INTERCEPTOR } from "../../_interceptors/loading-spinner.interceptor";
import { PaginatedResult } from "../../_models/paginated-result";
import { convertDateToDateString } from "../../_models/table-util";
import { Order } from "../../_view-models/order";
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from "src/app/_interceptors/skip-interceptors";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  baseUrl = "backoffice/orders";
  constructor(private http: HttpClient) {}

  priorityDeliveryCheck(orderId: number) {
    return this.http.get(`${this.baseUrl}/${orderId}/priority_delivery_check`);
  }

  getAll(params: any): Observable<PaginatedResult<Order>> {
    return this.http
      .get<PaginatedResult<Order>>(this.baseUrl, {
        params,
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR
        ),
      });
  }

  bulkAccept(orderIdList: string[]) {
    return this.http.put(`${this.baseUrl}/bulk_accept`, orderIdList);
  }

  bulkReject(orderIdList: string[], rejectionReason: string) {
    return this.http.put(`${this.baseUrl}/bulk_reject`, {
      orderIdList,
      rejectionReason,
    });
  }

  acceptOrder(order: Order) {
    return this.http.put(`${this.baseUrl}/${order.id}/accept`, {});
  }

  editDetails(order: Order, data: any) {
    if (data.manufacturingDate) {
      if (typeof data.manufacturingDate === "string") {
        data.manufacturingDate = moment(data.manufacturingDate).format(
          "YYYY-MM-DD-HH-mm-ss"
        );
      } else {
        let mm = data.manufacturingDate.getMonth() + 1 + "";
        let dd = data.manufacturingDate.getDate() + "";
        if (mm.length === 1) {
          mm = "0" + mm;
        }
        if (dd.length === 1) {
          dd = "0" + dd;
        }
        const yy = data.manufacturingDate.getFullYear();
        data.manufacturingDate = yy + "-" + mm + "-" + dd + "-00-00-00";
      }
    }
    if (
      data.deliveryInfo &&
      data.deliveryInfo.deliveryDate &&
      data.deliveryInfo.deliveryDate.includes("+")
    ) {
      data.deliveryInfo.deliveryDate = moment(
        data.deliveryInfo.deliveryDate
      ).format("YYYY-MM-DD-HH-mm-ss");
      data.deliveryInfo.deliveryDate =
        data.deliveryInfo.deliveryDate.substring(
          0,
          data.deliveryInfo.deliveryDate.length - "-00-00-00".length
        ) + "-00-00-00";
    }
    return this.http.put(`${this.baseUrl}/${order.id}`, data, {
      headers: new HttpHeaders().set(
        SKIP_LOADING_INTERCEPTOR,
        SKIP_LOADING_INTERCEPTOR
      ),
    });
  }

  editOrderItemDetails(orderItem: any, data: any) {
    return this.http.put(`backoffice/order_items/${orderItem.id}`, data);
  }

  rejectOrder(order: Order, text: string) {
    return this.http.put(`${this.baseUrl}/${order.id}/reject`, {
      rejectionReason: text,
    });
  }

  finishSuccessfully(order: Order) {
    return this.http.get(
      `${this.baseUrl}/${order.id}/close_order_successfully`
    );
  }

  finishUnsuccessfully(order: Order, text: string) {
    return this.http.put(
      `${this.baseUrl}/${order.id}/close_order_unsuccessfully`,
      {
        notDeliveredReason: text,
      }
    );
  }

  mergeOrders(o1Id, o2Id) {
    return this.http.put(
      `${this.baseUrl}/${o1Id}/merge_order?order_to_merge_id=${o2Id}`,
      {}
    );
  }

  getOrderItemPossibilities(productId) {
    return this.http.get(`backoffice/products/${productId}/colors_and_sizes`);
  }

  assignNewDeliveryCode(orderId) {
    return this.http.put(`${this.baseUrl}/${orderId}/update_delivery_code`, {});
  }

  getShippingLabel(orderId) {
    return this.http.get(`${this.baseUrl}/${orderId}/get_shipping_label`, {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders().set(
        SKIP_DTO_TRANSFORM_INTERCEPTOR,
        SKIP_DTO_TRANSFORM_INTERCEPTOR
      ),
    });
  }

  changeOrderItemSize(orderItemId, sizeId) {
    return this.http.put(
      `backoffice/order_items/${orderItemId}/update_size?size_id=${sizeId}`,
      {}
    );
  }

  changeOrderItemColor(orderItemId, colorId) {
    return this.http.put(
      `backoffice/order_items/${orderItemId}/update_color?color_id=${colorId}`,
      {}
    );
  }

  deleteOrderItem(orderItemId) {
    return this.http.delete(`backoffice/order_items/${orderItemId}`, {});
  }

  repeatManufacturingOrderItem(orderItemId) {
    return this.http.put(`backoffice/orders/${orderItemId}/repeat_manufacturing`, {});
  }

  duplicateOrderItem(orderItemId) {
    return this.http.post(`backoffice/order_items/${orderItemId}`, {});
  }

  prepressZip(params) {
    return this.http.get(`backoffice/orders/prepress_zip`, {
      params: {
        ...params,
        manufacturing_date: params.manufacturing_date
          ? convertDateToDateString(params.manufacturing_date)
          : undefined,
      },
    });
  }

  getDeliveryCode(companyId, shippingCarrierId) {
    return this.http.get(`backoffice/orders/get_delivery_code`, {
      params: {
        shippingCompanyId: companyId,
        shippingCarrierId: shippingCarrierId,
      },
    });
  }

  sendOrderAgain(orderId, newDeliveryCode = null) {
    return this.http.put(`backoffice/orders/${orderId}/send_again`, {
      deliveryCode: newDeliveryCode,
    });
  }
}
