<div class="m-2 row">
  <ng-container *ngIf="companies$ | async as companies">
    <ng-container *ngIf="shippingCarriers$ | async as shippingCarriers">
      <div class="col-12 col-md-6 p-5">
        <div class="my-2">
          <kadu-select
            #companiesSelect
            [options]="companies"
            [SelectedValue]="companies[0].value"
          ></kadu-select>
        </div>
        <div class="my-2">
          <kadu-select
            #shippingCarriersSelect
            [options]="shippingCarriers"
            [SelectedValue]="shippingCarriers[0].value"
          ></kadu-select>
        </div>
        <kadu-button
          class="btn btn-small btn-submit"
          (clickHandler)="
            getDeliveryCode(
              companiesSelect.SelectedValue,
              shippingCarriersSelect.SelectedValue
            )
          "
        >
          Preuzmi delivery code
        </kadu-button>
        <span class="me-3">{{ currentDeliveryCode }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
