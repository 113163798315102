import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-order-item-single",
  templateUrl: "./order-item-single.component.html",
  styleUrls: ["./order-item-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemSingleComponent implements AfterViewInit {
  @ViewChild(KaduFormComponent, { static: true })
  formComponent: KaduFormComponent;

  orderItem = this.config.data.orderItem;

  formControls = {
    price: { state: "", validators: [Validators.required] },
    basePrice: { state: "", validators: [Validators.required] },
    comment: { state: "" },
    isManufactured: { state: "" },
  };

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ngAfterViewInit(): void {
    this.formComponent.formGroup.patchValue(this.orderItem);
  }

  update(formValue: any): void {
    this.ref.close(formValue);
  }

  close(): void {
    this.ref.close();
  }
}
