import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./_components/login/login.component";
import { SuppliesComponent } from "./_components/supplies/supplies.component";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RouteUrlInterceptor } from "./_interceptors/route-url.interceptor";
import { HttpMessageInterceptor } from "./_interceptors/http-message.interceptor";
import { LogoutDirective } from "./_directives/logout.directive";
import { DesignersComponent } from "./_components/designers/designers.component";
import { OrdersComponent } from "./_components/orders/orders.component";
import { ShopsComponent } from "./_components/shops/shops.component";
import { OrderSingleComponent } from "./_components/order-single/order-single.component";
import { ReclamationsComponent } from "./_components/reclamations/reclamations.component";
import { MiscComponent } from "./_components/misc/misc.component";
import { OrderItemSingleComponent } from "./_components/order-item-single/order-item-single.component";
import { ManufacturedProductSingleComponent } from "./_components/manufactured-product-single/manufactured-product-single.component";
import { ManufacturedProductsCreateComponent } from "./_components/manufactured-products-create/manufactured-products-create.component";
import * as Raven from "raven-js";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from "src/environments/environment";
import { BexOrderActionsComponent } from "./_components/orders/_components/bex-order-actions/bex-order-actions.component";
import { PostaOrderActionsComponent } from "./_components/orders/_components/posta-order-actions/posta-order-actions.component";
import { BexOrderCheckComponent } from "./_components/orders/_components/bex-order-check/bex-order-check.component";
import { PostaOrderCheckComponent } from "./_components/orders/_components/posta-order-check/posta-order-check.component";
import { EmptyStringAsNullInterceptor } from "./_interceptors/empty-string-as-null.interceptor";
import { JwtRenewInterceptor } from "./_interceptors/jwt-renew.interceptor";
import { JwtAuthorizationInterceptorService } from "./_interceptors/jwt-authorization-interceptor.service";
import { Router } from "@angular/router";
import { CommonModule } from "@angular/common";
import { LoadingSpinnerInterceptor } from "./_interceptors/loading-spinner.interceptor";
import { PopupService } from "./components/popup/popup.service";
import { DialogModule } from "./components/dialog/dialog.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoaderService } from "./_services/loader/loader.service";
import { NgxJsonViewerModule } from "ngx-json-viewer";

import { KaduAnchorModule } from "@kadung/kadung/kadu-anchor";
import { KaduSidebarModule } from "@kadung/kadung/kadu-sidebar";
import { KaduLetModule } from "@kadung/kadung/kadu-let";
import { KaduRadioButtonModule } from "@kadung/kadung/kadu-radiobutton";
import { KaduFormModule } from "@kadung/kadung/kadu-form";
import { KaduButtonModule } from "@kadung/kadung/kadu-button";
import { KaduSelectModule } from "@kadung/kadung/kadu-select";
import { KaduSwitchModule } from "@kadung/kadung/kadu-switch";
import { KaduInputModule } from "@kadung/kadung/kadu-input";
import { KaduLoaderModule } from "@kadung/kadung/kadu-loader";
import { KaduTabsModule } from "@kadung/kadung/kadu-tabs";
import { KaduAccordionModule } from "@kadung/kadung/kadu-accordion";
import { KaduDatePickerModule } from "@kadung/kadung/kadu-date-picker";
import { KaduTableModule } from "@kadung/kadung/kadu-table";
import { KaduTextareaModule } from "@kadung/kadung/kadu-textarea";
import { KaduCheckboxModule } from "@kadung/kadung/kadu-checkbox";
import { KaduDataCardModule } from "@kadung/kadung/kadu-data-card";
import { KaduFileUploadModule } from "@kadung/kadung/kadu-file-upload";
import { KaduMobileSidebarModule } from "@kadung/kadung/kadu-mobile-sidebar";
import { JsonViewComponent } from "./components/json-view/json-view.component";
import { PurchaseOrderListComponent } from "./_components/purchase-order-list/purchase-order-list.component";
import { PurchaseOrderSingleComponent } from "./_components/purchase-order-single/purchase-order-single.component";
import { DtoTransformInterceptor } from "./_interceptors/dto-transform.interceptor";
import { PurchaseOrderDetailsComponent } from "./_components/purchase-order-details/purchase-order-details.component";
import { StockCheckupListComponent } from "./_components/supplies/_components/stock-checkup-list/stock-checkup-list.component";
import { StockCheckupSingleComponent } from "./_components/supplies/_components/stock-checkup-single/stock-checkup-single.component";
import { KaduTableConfig } from "@kadung/kadung/kadu-table/config/kadu-table.config";
import { ChangeOrderItemComponent } from "./_components/orders/_components/change-order-item/change-order-item.component";
import { FindPartPipe } from "./_pipes/find-part.pipe";
import { KaduNumberRangeInputModule } from "@kadung/kadung/kadu-number-range-input";
import { JoinPipe } from "./_pipes/join/join.pipe";
import { KaduTooltipModule } from "@kadung/kadung/kadu-tooltip";
import { DictionariesComponent } from "./_components/dictionaries/dictionaries.component";
import { ColorsComponent } from "./_components/dictionaries/_components/colors/colors.component";
import { ColorSingleComponent } from "./_components/dictionaries/_components/colors/_components/color-single/color-single.component";
import { SizesComponent } from "./_components/dictionaries/_components/sizes/sizes.component";
import { SizeSingleComponent } from "./_components/dictionaries/_components/sizes/_components/size-single/size-single.component";
import { CompaniesComponent } from "./_components/dictionaries/_components/companies/companies.component";
import { CompanySingleComponent } from "./_components/dictionaries/_components/companies/_components/company-single/company-single.component";
import { PrintTypesComponent } from "./_components/dictionaries/_components/print-types/print-types.component";
import { PrintTypeSingleComponent } from "./_components/dictionaries/_components/print-types/_components/print-type-single/print-type-single.component";
import { PartnersComponent } from "./_components/dictionaries/_components/partners/partners.component";
import { PartnerSingleComponent } from "./_components/dictionaries/_components/partners/_components/partner-single/partner-single.component";
import { RawMaterialsSuppliesComponent } from "./_components/supplies/_components/raw-materials-supplies/raw-materials-supplies.component";
import { RawMaterialSingleComponent } from "./_components/supplies/_components/raw-materials/_components/raw-material-single/raw-material-single.component";
import { RawMaterialsComponent } from "./_components/supplies/_components/raw-materials/raw-materials.component";
import { ProductTypesComponent } from "./_components/supplies/_components/product-types/product-types.component";
import { ProductTypeSingleComponent } from "./_components/supplies/_components/product-types/_components/product-type-single/product-type-single.component";
import { KaduColorPickerModule } from "@kadung/kadung/kadu-color-picker";
import { WarehouseLocationsComponent } from "./_components/dictionaries/_components/warehouse-locations/warehouse-locations.component";
import { WarehouseLocationSingleComponent } from "./_components/dictionaries/_components/warehouse-locations/_components/warehouse-location-single/warehouse-location-single.component";
import { StockStatesComponent } from "./_components/dictionaries/_components/stock-states/stock-states.component";
import { StockStateSingleComponent } from "./_components/dictionaries/_components/stock-states/_components/stock-state-single/stock-state-single.component";
import { KaduTextEditorModule } from "@kadung/kadung/kadu-text-editor";

Raven.config(environment.sentryDSNKey).install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err.originalError);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

export function getToken() {
  return localStorage.getItem("accessToken");
}

const kaduTableConfig: KaduTableConfig = {
  pageablePageKey: "currentResults",
  pageableTotalResultsKey: "totalResults",
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SuppliesComponent,
    LogoutDirective,
    DesignersComponent,
    OrdersComponent,
    ShopsComponent,
    RawMaterialsSuppliesComponent,
    OrderSingleComponent,
    ReclamationsComponent,
    MiscComponent,
    OrderItemSingleComponent,
    ManufacturedProductSingleComponent,
    ManufacturedProductsCreateComponent,
    BexOrderActionsComponent,
    PostaOrderActionsComponent,
    BexOrderCheckComponent,
    PostaOrderCheckComponent,
    JsonViewComponent,
    PurchaseOrderListComponent,
    PurchaseOrderSingleComponent,
    PurchaseOrderDetailsComponent,
    StockCheckupListComponent,
    StockCheckupSingleComponent,
    ChangeOrderItemComponent,
    FindPartPipe,
    ProductTypesComponent,
    JoinPipe,
    DictionariesComponent,
    ColorsComponent,
    ColorSingleComponent,
    SizesComponent,
    SizeSingleComponent,
    CompaniesComponent,
    CompanySingleComponent,
    PrintTypesComponent,
    PrintTypeSingleComponent,
    PartnersComponent,
    PartnerSingleComponent,
    RawMaterialsComponent,
    RawMaterialSingleComponent,
    ProductTypeSingleComponent,
    WarehouseLocationsComponent,
    WarehouseLocationSingleComponent,
    StockStatesComponent,
    StockStateSingleComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    KaduFormModule,
    KaduButtonModule,
    KaduSelectModule,
    KaduLoaderModule,
    KaduSwitchModule,
    KaduTabsModule,
    KaduInputModule,
    KaduTableModule.forRoot(kaduTableConfig),
    KaduCheckboxModule,
    KaduAccordionModule,
    KaduTextareaModule,
    KaduDatePickerModule,
    DialogModule,
    KaduRadioButtonModule,
    KaduDataCardModule,
    KaduSidebarModule,
    KaduMobileSidebarModule,
    NgxJsonViewerModule,
    KaduLetModule,
    KaduFileUploadModule,
    KaduAnchorModule,
    KaduNumberRangeInputModule,
    KaduTooltipModule,
    KaduTextEditorModule,
    KaduColorPickerModule,
  ],
  providers: [
    TranslateService,
    PopupService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingSpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RouteUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtRenewInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtAuthorizationInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMessageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DtoTransformInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmptyStringAsNullInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
