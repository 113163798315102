import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { FormArray, Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { SubSink } from "subsink";

@Component({
  selector: "app-stock-state-single",
  templateUrl: "./stock-state-single.component.html",
  styleUrls: ["./stock-state-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockStateSingleComponent {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  stockState = this.config.data.stockState;
  subs = new SubSink();

  formControls = {
    name: { state: null, validators: [Validators.required] },
    additionalInformation: { type: "array", controls: [], options: {} },
  };

  additionalInformationControls = {
    name: { state: null, validators: [Validators.required] },
    type: { state: null, validators: [Validators.required] },
    required: { state: false },
  };

  types = [
    { label: "Broj", value: "number" },
    { label: "String", value: "string" },
    { label: "Pattern", value: "pattern" },
    { label: "Decimalni broj", value: "decimal" },
  ];

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  addAdditionalInformationControl(controls = null): void {
    const array = this.formComponent.formGroup.get(
      "additionalInformation"
    ) as FormArray;

    array.insert(
      array.length,
      this.formComponent.generateFormGroup(
        controls ? controls : this.additionalInformationControls,
        {}
      )
    );

    array.controls.forEach((control, index) => {
      control.get("type").valueChanges.subscribe((type) => {
        if (type === "pattern") {
          this.formComponent.addControlToForm(
            "pattern",
            {
              pattern: { state: null },
            },
            `additionalInformation.${index}`,
            { validators: [Validators.required] }
          );
        } else {
          this.formComponent.removeControlFromForm(
            "pattern",
            {},
            `additionalInformation.${index}`
          );
        }
      });
    });
  }

  removeAdditionalInformationControl(index: number): void {
    const array = this.formComponent.formGroup.get(
      "additionalInformation"
    ) as FormArray;

    array.removeAt(index);
  }

  ngAfterViewInit(): void {
    if (this.stockState) {
      this.formComponent.formGroup.patchValue({ name: this.stockState.name });

      const additionalInformation = JSON.parse(
        this.stockState.additionalInformation
      );

      additionalInformation.forEach((information) => {
        let controls;

        if (information.type === "pattern") {
          controls = {
            pattern: {
              state: information.pattern,
              validators: [Validators.required],
            },
          };
        }

        controls = {
          ...controls,
          name: { state: information.name, validators: [Validators.required] },
          type: { state: information.type, validators: [Validators.required] },
          required: { state: information.required },
        };

        this.addAdditionalInformationControl(controls);
      });
    }
  }

  close(): void {
    this.ref.close(false);
  }

  save(formValue: any): void {
    const finalData = {
      ...formValue,
      additionalInformation:
        formValue.additionalInformation.length > 0
          ? JSON.stringify(formValue.additionalInformation)
          : null,
    };

    if (this.stockState) {
      this.ref.close({
        editMode: true,
        data: { ...finalData, id: this.stockState.id },
      });
    } else {
      this.ref.close({
        editMode: false,
        data: finalData,
      });
    }
  }
}
