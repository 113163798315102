import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from 'src/app/_interceptors/skip-interceptors';
import { convertToFormData } from 'src/app/_models/util';
import { DispatchNote } from 'src/app/_view-models/dispatch-note';

@Injectable({
  providedIn: 'root'
})
export class DispatchNoteService {
  baseUrl = 'backoffice/dispatch_notes/';
  constructor(private http: HttpClient) { }

  create(dispatchNote: DispatchNote): Observable<DispatchNote> {
    return this.http.post<DispatchNote>(`${this.baseUrl}`, convertToFormData(dispatchNote, ['file']), {
      headers: new HttpHeaders().set(SKIP_DTO_TRANSFORM_INTERCEPTOR, SKIP_DTO_TRANSFORM_INTERCEPTOR)
    });
  }

  finish(dispatchNoteId: number): Observable<any> {
    return this.http.patch(`${this.baseUrl}${dispatchNoteId}/finish`, {});
  }

  delete(dispatchNoteId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${dispatchNoteId}`);
  }
}
