import { Pipe, PipeTransform } from "@angular/core";
import { OrderItem } from "../_view-models/order-item";

@Pipe({
  name: "findPart",
})
export class FindPartPipe implements PipeTransform {
  transform(order: OrderItem, ...args: unknown[]): any {
    return order.productImages.find(
      (productImage: any) =>
        productImage.productTypePartId === order.productPrimaryPartId
    );
  }
}
