import {
  Component,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { DesignerService } from "src/app/_services/designer/designer.service";
import { Observable } from "rxjs";
import { Designer } from "src/app/_view-models/designer";
import { saveAs } from "file-saver";
import { PaginatedResult } from "src/app/_models/paginated-result";
import { TableColumn } from "src/app/_models/table-column";
import { PopupService } from "src/app/components/popup/popup.service";
import { TableFilter } from "src/app/_models/table-filter";
import { JsonViewComponent } from "src/app/components/json-view/json-view.component";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { KaduTableComponent } from "@kadung/kadung/kadu-table";

@Component({
  selector: "app-designers",
  templateUrl: "./designers.component.html",
  styleUrls: ["./designers.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignersComponent {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild("table") table: KaduTableComponent;

  cols: TableColumn[];

  currentFilters = {
    first: 0,
    rows: 20,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  filtersMeta = {
    firstName: { key: "firstName", matchMode: "like:" },
    lastName: { key: "lastName", matchMode: "like:" },
    username: { key: "username", matchMode: "like:" },
    phoneNumber: { key: "phoneNumber", matchMode: "like:" },
    email: { key: "email", matchMode: "like:" },
  };

  designerRequest = ((
    filters: TableFilter
  ): Observable<PaginatedResult<Designer>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };
    return this.designerService.getAll(
      generateTableRequestParams(this.currentFilters, this.filtersMeta)
    );
  }).bind(this);

  constructor(
    private designerService: DesignerService,
    private popupService: PopupService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "Ime",
        field: "firstName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Prezime",
        field: "lastName",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Korisničko ime",
        field: "username",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Broj telefona",
        field: "phoneNumber",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Email",
        field: "email",
        filterType: "string",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "custom",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  banDesigner(designer: any): void {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da banujete dizajnera?",
        text: "Unesi razlog banovanja",
        type: "warning",
        input: "text",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.designerService.banDesigner(designer.id, res.value).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno banovan dizajner",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  unbanDesigner(designer: any): void {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da uklonite ban dizajnera?",
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.designerService.unbanDesigner(designer.id).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno uklonjen ban dizajnera",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  showData(data: any): void {
    this.dialogService.open(JsonViewComponent, { data, width: "80%" });
  }
}
