import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormArray, Validators } from "@angular/forms";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { ManufacturedProductService } from "src/app/_services/manufactured-product/manufactured-product.service";
import { OrderService } from "src/app/_services/order/order.service";
import { Order } from "src/app/_view-models/order";
import { PopupService } from "src/app/components/popup/popup.service";
import { SubSink } from "subsink";

@Component({
  selector: "app-manufactured-products-create",
  templateUrl: "./manufactured-products-create.component.html",
  styleUrls: ["./manufactured-products-create.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturedProductsCreateComponent implements OnInit {
  @ViewChild("selectedOrderForm", { static: false })
  selectedOrderFormComponent: KaduFormComponent;

  subs = new SubSink();
  notFound$ = new BehaviorSubject<boolean | number>(false);
  type: "NOT_DELIVERED" | "SIZE" = "NOT_DELIVERED";

  deliveryCodeFormControls = {
    deliveryCode: { state: "", validators: Validators.required },
  };

  reclamationIdFormControls = {
    reclamationId: { state: "", validators: Validators.required },
  };

  selectedOrderFormControls = {
    orderItems: {
      type: "array",
      controls: [],
    },
  };

  orderItemControls = {
    include: { state: "" },
    orderItem: { state: "" },
  };

  orderSource = new BehaviorSubject<Order | any>(null);
  order$ = this.orderSource.asObservable();

  selectedOrderSource = new BehaviorSubject<Order>(null);
  selectedOrder$ = this.selectedOrderSource.asObservable();

  constructor(
    private orderService: OrderService,
    private manufacturedProductsService: ManufacturedProductService,
    private popupService: PopupService,
    private ref: DynamicDialogRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.order$.subscribe((order) => {
        if (order) {
          this.notFound$.next(false);
          this.selectOrder(order);
        }
      })
    );
  }

  changeTab(event: number): void {
    this.selectedOrderSource.next(null);
    this.notFound$.next(false);
    this.type = event === 0 ? "NOT_DELIVERED" : "SIZE";
  }

  filtersMeta = {
    deliveryCode: { key: "deliveryCode", matchMode: "like:" },
    state: {key: "state", matchMode: 'like:'},
    eligibleForManufacturedProductWarehouse: { key: "eligible_for_manufactured_warehouse", matchMode: "in:"}
  };

  search(type: "NOT_DELIVERED" | "SIZE", formValue: any): void {
    if (type === "NOT_DELIVERED") {
      const filters = {
        first: 0,
        rows: 20,
        sortOrder: -1,
        sortField: "created",
        filters: {
          state: {value: "NOT_DELIVERED"},
          deliveryCode: { value: formValue["deliveryCode"] },
          eligibleForManufacturedProductWarehouse: {value: [true]}
        },
      };

      this.subs.add(
        this.orderService
          .getAll(generateTableRequestParams(filters, this.filtersMeta))
          .subscribe((pr) => {
            this.selectedOrderSource.next(null);
            this.orderSource.next(pr.currentResults[0]);
            this.notFound$.next(!!(pr.currentResults.length === 0));
          })
      );
    } else if (type === "SIZE") {
      this.subs.add(
        this.manufacturedProductsService
          .getSizeOrderItems(formValue["reclamationId"])
          .subscribe((res: any) => {
            if (res.length > 0) {
              this.orderSource.next({
                orderItemList: res,
              });
              this.notFound$.next(false);
            } else {
              this.orderSource.next(null);
              this.notFound$.next(true);
            }
          })
      );
    }
  }

  selectOrder(order: any): void {
    this.selectedOrderSource.next(order);
    if (this.selectedOrderFormComponent) {
      while (
        (
          this.selectedOrderFormComponent.formGroup.get(
            "orderItems"
          ) as FormArray
        ).length > 0
      ) {
        const orderItems = this.selectedOrderFormComponent.formGroup.get(
          "orderItems"
        ) as FormArray;

        orderItems.removeAt(0);
        orderItems.updateValueAndValidity();
      }
    }

    setTimeout(() => {
      order.orderItemList.forEach((oi) => {
        const orderItems = this.selectedOrderFormComponent.formGroup.get(
          "orderItems"
        ) as FormArray;

        const group = this.selectedOrderFormComponent.generateFormGroup(
          this.orderItemControls,
          {}
        );

        group.get("include").setValue(true);
        group.get("orderItem").setValue(oi);

        orderItems.push(group);

        this.cdr.detectChanges();
      });
    });
  }

  update({ ...formValue }): void {
    const value = formValue.orderItems
      .filter((oi) => oi.include)
      .map((oi) => {
        return {
          type: this.type,
          fromOrderItemId:
            this.type === "NOT_DELIVERED"
              ? oi.orderItem.id
              : oi.orderItem.orderItemId,
          productItemId: oi.orderItem.productItemId,
          isUsed: false,
        };
      });
    if (value.length === 0) {
      this.popupService.fire({
        title: "Greška",
        text: "Morate izabrati makar jedan SKU",
        type: "error",
      });
      return;
    }
    this.ref.close(value);
  }

  close(): void {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
