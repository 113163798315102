import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/_interceptors/loading-spinner.interceptor";
import { PaginatedResult } from "../../_models/paginated-result";
import { ManufacturedProduct } from "../../_view-models/manufactured-product";

@Injectable({
  providedIn: "root",
})
export class ManufacturedProductService {
  baseUrl = "backoffice/manufactured_products";
  constructor(private http: HttpClient) {}

  getAll(params: any): Observable<PaginatedResult<ManufacturedProduct>> {
    return this.http.get<PaginatedResult<ManufacturedProduct>>(
      `${this.baseUrl}`,
      {
        params,
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR
        ),
      }
    );
  }

  add(data): Observable<any> {
    return this.http.post(`${this.baseUrl}`, data);
  }

  edit(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

  getSizeOrderItems(id: number): Observable<any[]> {
    return this.http.get<any[]>(
      `backoffice/reclamations/${id}/product_items?reclamation_reason=SIZE`
    );
  }
}
