import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { KaduTableComponent, PaginatedResult } from "@kadung/kadung/kadu-table";
import { ProductTypeService } from "src/app/_services/product-type/product-type.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { ProductTypeSingleComponent } from "./_components/product-type-single/product-type-single.component";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { TableFilter } from "src/app/_models/table-filter";
import { Observable } from "rxjs";

@Component({
  selector: "app-product-types",
  templateUrl: "./product-types.component.html",
  styleUrls: ["./product-types.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTypesComponent {
  @ViewChild("coverImageUrlTemplate", { static: true })
  coverImageUrlTemplate: TemplateRef<any>;
  @ViewChild("sizeInfoUrlTemplate", { static: true })
  sizeInfoUrlTemplate: TemplateRef<any>;
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild(KaduTableComponent, { static: false }) table: KaduTableComponent;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  productTypeCols;
  productTypes$ = ((filters: TableFilter): Observable<PaginatedResult<any>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };

    return this.productTypeService.getAllPaginated(
      generateTableRequestParams(this.currentFilters, {})
    );
  }).bind(this);
  constructor(
    private popupService: PopupService,
    private productTypeService: ProductTypeService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.productTypeCols = [
      {
        header: "Id",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Ime",
        field: "name",
        filterType: "string",
        type: "normal",
        width: "7%",
      },
      {
        header: "Aktivan",
        field: "active",
        filterType: "string",
        type: "boolean",
      },
      {
        header: "Opis",
        field: "description",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Bazna cena",
        field: "basePrice",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Slug",
        field: "slug",
        filterType: "string",
        type: "normal",
        width: "7%",
      },
      {
        header: "Partner Id",
        field: "partnerId",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Sort number",
        field: "sortNumber",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Pr. profit",
        field: "recommendedProfit",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Pr. cena",
        field: "recommendedPrice",
        filterType: "string",
        type: "normal",
      },
      {
        header: "SKU",
        field: "sku",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Brend",
        field: "brand",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Brend SKU",
        field: "skuBrand",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Size info url",
        field: "sizeInfoUrl",
        filterType: "string",
        type: "custom",
        options: {
          template: this.sizeInfoUrlTemplate,
        },
      },
      {
        header: "Cover image url",
        field: "coverImageUrl",
        filterType: "string",
        type: "custom",
        options: {
          template: this.coverImageUrlTemplate,
        },
      },
      {
        header: "",
        field: "actions",
        filterType: "string",
        type: "custom",
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  deleteProductType(productType: any): void {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da obrišete tip proizvoda ${productType.name}?`,
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.productTypeService.deleteProductType(productType.id).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno obrisan tip proizvoda",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  openDialog(productType = null): void {
    const dialog = this.dialogService.open(ProductTypeSingleComponent, {
      showHeader: false,
      width: "99%",
      height: "99%",
      data: {
        productType,
      },
    });

    dialog.onClose.subscribe((response) => {
      if (!response) return;

      this.table.load(this.currentFilters);
    });
  }

  showImagePopup(imageUrl: string): void {
    this.popupService.fire({
      html: ` <img draggable="false" style="height: 400px; max-width: 100%; max-height: 400px" src="${imageUrl}" />`,
      showCloseButton: true,
      showConfirmButton: false,
    });
  }
}
