import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/_interceptors/loading-spinner.interceptor";
import { PaginatedResult } from "../../_models/paginated-result";

@Injectable({
  providedIn: "root",
})
export class StockUpdateLogService {
  baseUrl = "backoffice/stock_update_logs";
  constructor(private http: HttpClient) {}

  getAll(params: any): Observable<PaginatedResult<any>> {
    return this.http.get<PaginatedResult<any>>(`${this.baseUrl}`, {
      params,
      headers: new HttpHeaders().set(
        SKIP_LOADING_INTERCEPTOR,
        SKIP_LOADING_INTERCEPTOR
      ),
    });
  }
}
