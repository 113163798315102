import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/_services/auth/auth.service";
import { KaduFormRequest } from "@kadung/kadung/kadu-form";
import { SubSink } from "subsink";
import { UserLogin } from "src/app/_models/userLogin";
import { Observable } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends KaduFormRequest implements OnDestroy {
  namespace = "components.login";
  formControls = {
    email: { state: "", validators: Validators.required },
    password: { state: "", validators: Validators.required },
  };

  subs = new SubSink();

  constructor(private authService: AuthService, private router: Router) {
    super();
  }

  login(form: any): void {
    const user = {
      email: form.email,
      password: form.password,
    };

    this.subs.add(
      this.getRequest(user).subscribe(
        () => {
          this.router.navigate(["orders", "accept"]);
        },
        (error) => {
          this.handleError(error);
        }
      )
    );
  }

  protected _getRequest(user: UserLogin): Observable<any> {
    return this.authService.login(user);
  }

  handleError(error): void {
    switch (error.error.status) {
      case 403:
      case 404: {
        this.formComponent.formGroup.get("password").setErrors({
          failLogin: true,
        });

        break;
      }
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
