export const convertDateToDateString = (
  date,
  time: "start" | "end" = "start"
) => {
  let mm = date.getMonth() + 1 + "";
  let dd = date.getDate() + "";
  if (mm.length === 1) {
    mm = "0" + mm;
  }
  if (dd.length === 1) {
    dd = "0" + dd;
  }
  const yy = date.getFullYear();
  return (
    yy + "-" + mm + "-" + dd + (time === "start" ? "-00-00-00" : "-23-59-59")
  );
};

export function prepareCurrentFilters(
  currentFilters: any,
  tableFilters: any,
  tableAdditionalFilters?: any,
  ...additionalParams: any
): any {
  Object.entries(tableFilters).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      delete tableFilters[key];
    }
  });

  tableFilters["sortOrder"] =
    currentFilters["sortOrder"] ?? tableFilters["sortOrder"];

  return {
    ...currentFilters,
    ...tableFilters,
    ...tableAdditionalFilters,
    filters: {
      ...tableFilters.filters,
      ...Object.assign({}, ...additionalParams),
    },
  };
}

export function generateTableRequestParams(data: any, filtersMeta: any): any {
  const params = {
    size: data.rows,
    page: data.first / data.rows,
    sort_by: data.sortField
      ? toCamelCase(filtersMeta[data.sortField]?.key)
      : undefined,
    sort_order: data.sortOrder === 1 ? "asc" : "desc",
  };

  if (params.sort_by === undefined) {
    delete params.sort_by;
    delete params.sort_order;
  }

  Object.keys(data.filters).forEach((filter: any) => {
    if (!data.filters[filter]) return;

    let param = "";
    if (data.filters[filter].matchMode === "numberBetween") {
      const [from, to] = Object.values(data.filters[filter].value);

      const matchMode = from && to ? `btn:` : from ? `gt:` : `lt:`;

      param =
        from && to
          ? `${matchMode}${from},${to}`
          : `${matchMode}${from ? from : to}`;
    } else {
      if (data.filters[filter].value instanceof Date) {
        data.filters[filter].value = convertDateToDateString(
          data.filters[filter].value
        );
      }

      param =
        data.filters[filter].value instanceof Array
          ? `${filtersMeta[filter].matchMode}${data.filters[filter].value.join(
              ","
            )}`
          : `${filtersMeta[filter].matchMode}${data.filters[filter].value}`;
    }

    params[filtersMeta[filter].key] = param;
  });

  return params;
}

export const toCamelCase = (value: string): string =>
  value?.replace(/[_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
