<div>
  <p class="p3">VN - Vraćeni nalog</p>
  <p class="p3">ON - Otpremni nalog</p>
</div>

<kadu-button
  (clickHandler)="openDialog()"
  class="btn btn-submit btn-small mb-2"
>
  Dodaj novog partnera
</kadu-button>
<kadu-table
  [columns]="cols"
  [request]="partners$"
  [lazy]="true"
  [rows]="20"
  [loading]="false"
></kadu-table>

<ng-template #actionsTemplate let-partner>
  <div class="d-flex justify-content-center gap-2">
    <span class="cursor-pointer">
      <i
        (click)="openDialog(partner)"
        class="btn btn-submit btn-small bi bi-pencil p3"
      >
      </i>
    </span>
    <span class="cursor-pointer">
      <i
        (click)="deletePartner(partner)"
        class="btn btn-cancel btn-small bi bi-trash p3"
      >
      </i>
    </span>
  </div>
</ng-template>
