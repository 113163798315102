import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { KaduTableComponent, PaginatedResult } from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";
import { TableFilter } from "src/app/_models/table-filter";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { SizeService } from "src/app/_services/size/size.service";
import { SizeSingleComponent } from "./_components/size-single/size-single.component";
import { PopupService } from "src/app/components/popup/popup.service";
import { DialogService } from "src/app/components/dialog/dialog.service";

@Component({
  selector: "app-sizes",
  templateUrl: "./sizes.component.html",
  styleUrls: ["./sizes.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizesComponent implements OnInit {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild(KaduTableComponent, { static: false }) table: KaduTableComponent;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  sizes$ = ((filters: TableFilter): Observable<PaginatedResult<any>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };

    return this.sizeService.getAllPaginated(
      generateTableRequestParams(this.currentFilters, {})
    );
  }).bind(this);

  cols;

  constructor(
    private sizeService: SizeService,
    private popupService: PopupService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "Id",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Ime",
        field: "name",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Sku code",
        field: "skuCode",
        filterType: "string",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "string",
        type: "custom",
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  deleteSize(size: any): void {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da obrišete veličinu ${size.name}?`,
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.sizeService.deleteSize(size.id).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno obrisana veličina",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  openDialog(size = null): void {
    const dialog = this.dialogService.open(SizeSingleComponent, {
      showHeader: false,
      width: "600px",
      data: {
        size,
      },
    });

    dialog.onClose.subscribe((response) => {
      if (!response) return;

      if (response.editMode) {
        this.sizeService.editSize(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno izmenjena veličina",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      } else {
        this.sizeService.addSize(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno dodata veličina",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      }
    });
  }
}
