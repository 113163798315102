import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
  selector: "app-json-view",
  templateUrl: "./json-view.component.html",
  styleUrls: ["./json-view.component.scss"],
})
export class JsonViewComponent {
  data = this.config.data;

  constructor(private config: DynamicDialogConfig) {}
}
