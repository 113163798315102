import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DesignersComponent } from "./_components/designers/designers.component";
import { DictionariesComponent } from "./_components/dictionaries/dictionaries.component";
import { LoginComponent } from "./_components/login/login.component";
import { MiscComponent } from "./_components/misc/misc.component";
import { OrdersComponent } from "./_components/orders/orders.component";
import { ReclamationsComponent } from "./_components/reclamations/reclamations.component";
import { ShopsComponent } from "./_components/shops/shops.component";
import { SuppliesComponent } from "./_components/supplies/supplies.component";
import { AlreadyLoggedInGuard } from "./_guards/already-logged-in.guard";
import { AuthGuard } from "./_guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      {
        path: "orders/:type",
        component: OrdersComponent,
      },
      {
        path: "supplies",
        component: SuppliesComponent,
      },
      {
        path: "designers",
        component: DesignersComponent,
      },
      {
        path: "shops",
        component: ShopsComponent,
      },
      {
        path: "reclamations",
        component: ReclamationsComponent,
      },
      {
        path: "dictionaries",
        component: DictionariesComponent,
      },
      {
        path: "misc",
        component: MiscComponent,
      },
    ],
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AlreadyLoggedInGuard],
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login",
  },
  {
    path: "**",
    redirectTo: "login",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
