<div class="p-3">
  <kadu-table
    [columns]="cols"
    [rows]="20"
    [withFilters]="false"
    [values]="rawMaterials$ | async"
    [sortable]="true"
    [loading]="false"
  >
  </kadu-table>
</div>

<ng-template #actionsTemplate let-rowData>
  <kadu-button
    class="btn btn-small btn-submit"
    (clickHandler)="addSupplies(rowData)"
  >
    Dodaj nove zalihe
  </kadu-button>
</ng-template>
