import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderPartnerService {
  baseUrl = 'backoffice/order_partners';
  constructor(private http: HttpClient) { }

  update(data: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${data['id']}`, data);
  }
}
