import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { KaduDOMEvent } from "@kadung/kadung/_core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { DispatchNoteService } from "src/app/_services/dispatch-note/dispatch-note.service";
import { PurchaseOrderService } from "src/app/_services/purchase-order/purchase-order.service";
import { ReceiveNoteService } from "src/app/_services/receive-note/receive-note.service";
import { DispatchNote } from "src/app/_view-models/dispatch-note";
import { PurchaseOrder } from "src/app/_view-models/purchase-order";
import { ReceiveNote } from "src/app/_view-models/receive-note";
import { PopupService } from "src/app/components/popup/popup.service";
import { map } from "rxjs/operators";
import { KaduFormComponent } from "@kadung/kadung/kadu-form";
import { ColorService } from "src/app/_services/color/color.service";
import { SizeService } from "src/app/_services/size/size.service";
import { ProductTypeService } from "src/app/_services/product-type/product-type.service";

@Component({
  selector: "app-purchase-order-details",
  templateUrl: "./purchase-order-details.component.html",
  styleUrls: ["./purchase-order-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseOrderDetailsComponent implements OnInit {
  @ViewChild("buttonsTemplate", { static: true })
  buttonsTemplate: TemplateRef<any>;
  @ViewChild("receiveNoteButtonsTemplate", { static: true })
  receiveNoteButtonsTemplate: TemplateRef<any>;
  @ViewChild("downloadTemplate", { static: true })
  downloadTemplate: TemplateRef<any>;
  purchaseOrder: PurchaseOrder;
  @ViewChild("form", { static: false }) formComponent: KaduFormComponent;

  cols: any[];

  formControls = {
    receiveNoteFile: { state: null, validators: Validators.required },
    fellerFile: { state: null, validators: Validators.required },
  };

  receiveNoteCols: any[];

  dispatchNotes$ = new Subject<DispatchNote[]>();
  anyDispatchNoteFinished$ = this.dispatchNotes$.pipe(
    map(
      (dispatchNotes) =>
        dispatchNotes.filter((dn) => dn.stockUpdateFinished).length > 0
    )
  );

  fulfillmentInfoCols = [
    {
      header: "SKU",
      field: "sku",
      filterType: "string",
      type: "normal",
    },
    {
      header: "Tip",
      field: "productTypeName",
      filterType: "select",
      type: "normal",
      options: {
        lazy: true,
        optionValues: this.productTypeService.getAll().pipe(
          map((productTypes) => {
            return productTypes.map((pt) => {
              return {
                value: pt["id"],
                label: pt["name"],
              };
            });
          })
        ),
      },
    },
    {
      header: "Boja",
      field: "colorName",
      filterType: "select",
      type: "normal",
      options: {
        lazy: true,
        optionValues: this.colorService.getAll().pipe(
          map((colors) => {
            return colors.map((c) => {
              return {
                value: c["id"],
                label: c["name"],
              };
            });
          })
        ),
      },
    },
    {
      header: "Veličina",
      field: "sizeName",
      filterType: "select",
      type: "normal",
      options: {
        lazy: true,
        optionValues: this.sizeService.getAll().pipe(
          map((sizes) => {
            return sizes.map((s) => {
              return {
                value: s["id"],
                label: s["name"],
              };
            });
          })
        ),
      },
    },
    {
      header: "Poručeno",
      field: "purchasedAmount",
      filterType: "number",
      type: "normal",
    },
    {
      header: "Otpremljeno",
      field: "dispatchedAmount",
      filterType: "number",
      type: "normal",
    },
    {
      header: "Primljeno",
      field: "receivedAmount",
      filterType: "number",
      type: "normal",
    },
    {
      header: "Feleri",
      field: "fellerAmount",
      filterType: "number",
      type: "normal",
    },
    {
      header: "Primljeno zaista",
      field: "validReceivedAmount",
      filterType: "number",
      type: "normal",
    },
    {
      header: "Preostalo",
      field: "remainingAmount",
      filterType: "number",
      type: "normal",
    },
  ];

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private purchaseOrderService: PurchaseOrderService,
    private dispatchNoteService: DispatchNoteService,
    private receiveNoteService: ReceiveNoteService,
    private popupService: PopupService,
    private colorService: ColorService,
    private sizeService: SizeService,
    private productTypeService: ProductTypeService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "ID",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Datum kreiranja",
        field: "created",
        filterType: "date",
        type: "date",
        options: {
          format: "dd.MM.yyyy",
        },
      },
      {
        header: "Excel",
        field: "fileUrl",
        type: "custom",
        options: {
          template: this.downloadTemplate,
        },
      },
      {
        header: "Sufiks",
        field: "suffixValidator",
        type: "normal",
      },
      {
        header: "Otprema završena",
        field: "stockUpdateFinished",
        type: "boolean",
      },
      {
        header: "Akcije",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.buttonsTemplate,
        },
      },
    ];

    this.receiveNoteCols = [
      {
        header: "Datum",
        field: "created",
        filterType: "date",
        type: "date",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "ID prijemnice",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Prijemnica",
        field: "fileUrl",
        type: "file",
        options: {
          icon: "fa fa-download",
        },
      },
      {
        header: "Stanje prijemnice",
        field: "state",
        type: "normal",
      },
      {
        header: "ID felera",
        field: "fellerId",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Feleri",
        field: "fellerFileUrl",
        type: "file",
        options: {
          icon: "fa fa-download",
        },
      },
      {
        header: "Akcije",
        type: "custom",
        sortDisabled: true,
        options: {
          template: this.receiveNoteButtonsTemplate,
        },
      },
    ];

    this.purchaseOrder = this.config.data.purchaseOrder;
    this.loadDispatchNotes();
  }

  loadDispatchNotes(): void {
    this.purchaseOrderService
      .getAllDispatchNotes(this.purchaseOrder.id)
      .subscribe((dispatchNotes) => {
        this.dispatchNotes$.next(
          dispatchNotes.map((dn) => ({
            ...dn,
            receiveNotes: dn.receiveNotes.map((rn) => ({
              ...rn,
              dispatchNote: dn,
            })),
            hasCommittedReceiveNotes:
              dn.receiveNotes.filter((rn) => rn.state === "COMMITTED").length >
              0,
          }))
        );
      });
  }

  createDispatchNote(event: KaduDOMEvent<InputEvent, any>): void {
    this.dispatchNoteService
      .create({ file: event.value, purchaseOrderId: this.purchaseOrder.id })
      .subscribe(
        (res) => {
          this.popupService.fireToast({
            title: "Uspešno dodata otpremnica",
            type: "success",
          });
          this.loadDispatchNotes();
        },
        (error) => {
          this.popupService.fire({
            title: "Greška prilikom dodavanja otpremnice",
            type: "error",
            text: error.error.message,
          });
        }
      );
  }

  addReceiveNote(dispatchNote: DispatchNote, formValue: any): void {
    this.receiveNoteService
      .create({ ...formValue, dispatchNoteId: dispatchNote.id })
      .subscribe(
        (res) => {
          this.popupService.fireToast({
            title: "Uspešno završen prijem",
            type: "success",
          });
          this.loadDispatchNotes();
          this.formComponent.formGroup.reset();
        },
        (error) => {
          this.popupService.fire({
            title: "Greška prilikom završavanja prijema",
            type: "error",
            text: error.error.message,
          });
        }
      );
  }

  rollbackReceiveNote(receiveNote: ReceiveNote): void {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da poništite prijem?",
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.receiveNoteService.rollback(receiveNote.id).subscribe(
            (res) => {
              this.popupService.fireToast({
                title: "Uspešno poništen prijem",
                type: "success",
              });
              this.loadDispatchNotes();
            },
            (error) => {
              this.popupService.fire({
                title: "Greška prilikom poništavanja prijema",
                type: "error",
                text: error.error.message,
              });
            }
          );
        }
      });
  }

  deleteDispatchNote(dispatchNote: DispatchNote): void {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da obrišete otpremnicu?",
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.dispatchNoteService.delete(dispatchNote.id).subscribe(
            (res) => {
              this.popupService.fireToast({
                title: "Uspešno obrisana otpremnica",
                type: "success",
              });
              this.loadDispatchNotes();
            },
            (error) => {
              this.popupService.fire({
                title: "Greška prilikom brisanja otpremnice",
                type: "error",
                text: error.error.message,
              });
            }
          );
        }
      });
  }

  finishDispatchNote(dispatchNote: DispatchNote): void {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da završite otpremnicu?",
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.dispatchNoteService.finish(dispatchNote.id).subscribe(
            (res) => {
              this.popupService.fireToast({
                title: "Uspešno završen prijem",
                type: "success",
              });
              this.loadDispatchNotes();
            },
            (error) => {
              this.popupService.fire({
                title: "Greška prilikom završavanja prijema",
                type: "error",
                text: error.error.message,
              });
            }
          );
        }
      });
  }

  finishReceiveNote(receiveNote: ReceiveNote): void {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da završite prijem?",
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.dispatchNoteService.finish(receiveNote.id).subscribe(
            (res) => {
              this.popupService.fireToast({
                title: "Uspešno završen prijem",
                type: "success",
              });
              this.loadDispatchNotes();
            },
            (error) => {
              this.popupService.fire({
                title: "Greška prilikom završavanja prijema",
                type: "error",
                text: error.error.message,
              });
            }
          );
        }
      });
  }

  finishPurchaseOrder(): void {
    this.popupService
      .fire({
        title: "Da li ste sigurni da želite da završite porudžbenicu?",
        type: "info",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.purchaseOrderService.finish(this.purchaseOrder.id).subscribe(
            (res) => {
              this.popupService.fireToast({
                title: "Uspešno završena porudžbenica",
                type: "success",
              });
              this.ref.close(true);
            },
            (error) => {
              this.popupService.fire({
                title: "Greška prilikom dodavanja otpremnice",
                type: "error",
                text: error.error.message,
              });
            }
          );
        }
      });
  }
}
