<form
  class="d-inline"
  method="post"
  target="_blank"
  action="https://www.bex.rs/onlinepracenjeposiljke.php?getStatus="
>
  <input type="hidden" name="numberShipment" [value]="deliveryCode" />
  <kadu-button type="submit" class="ms-2 btn btn-xs btn-submit">
    Proveri
  </kadu-button>
</form>
