import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Shop } from "../../_view-models/shop";

import { map } from "rxjs/operators";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/_interceptors/loading-spinner.interceptor";
import { PaginatedResult } from "../../_models/paginated-result";

@Injectable({
  providedIn: "root",
})
export class ShopService {
  baseUrl = "backoffice/e_commerces";
  constructor(private http: HttpClient) {}

  getAll(params: any): Observable<PaginatedResult<Shop>> {
    return this.http
      .get<PaginatedResult<Shop>>(`${this.baseUrl}`, {
        params,
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR
        ),
      })
      .pipe(
        map((pr: PaginatedResult<Shop>) => {
          return {
            ...pr,
            currentResults: pr.currentResults
              ? pr.currentResults.map((shop) => {
                  return {
                    ...shop,
                    url: shop["shopUrl"],
                    isInAggregatedShop: shop["isInAggregatedShop"],
                  };
                })
              : [],
          };
        })
      );
  }

  getOrderFulfillmentTypes(): Observable<any> {
    return this.http.get(`orders/order_fulfillment_types`);
  }
}
