<kadu-button
  class="btn btn-small btn-submit ms-2 mb-2 mt-2"
  (clickHandler)="createPurchaseOrder()"
>
  Unesi novu porudžbenicu
</kadu-button>

<kadu-table
  #table
  [columns]="cols"
  [rows]="40"
  [lazy]="true"
  [request]="purchaseOrdersRequest"
  [withFilters]="true"
  [loading]="true"
  (onChange)="setCurrentFilters($event)"
  [sortable]="true"
>
  <ng-template #buttonsTemplate let-purchaseOrder let-index="index">
    <div class="d-flex justify-content-around gap-2">
      <kadu-button
        class="btn btn-small btn-submit"
        (clickHandler)="viewPurchaseOrder(purchaseOrder)"
      >
        Vidi detalje
      </kadu-button>
      <kadu-button
        class="btn btn-small btn-danger"
        (clickHandler)="deletePurchaseOrder(purchaseOrder)"
        >
        Obriši
      </kadu-button
      >
    </div>
  </ng-template>
</kadu-table>
