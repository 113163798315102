import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import {
  KaduTableComponent,
  KaduTableModule,
  PaginatedResult,
} from "@kadung/kadung/kadu-table";
import { Observable } from "rxjs";
import { TableFilter } from "src/app/_models/table-filter";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { ColorService } from "src/app/_services/color/color.service";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { ColorSingleComponent } from "./_components/color-single/color-single.component";

@Component({
  selector: "app-colors",
  templateUrl: "./colors.component.html",
  styleUrls: ["./colors.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorsComponent implements OnInit {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild("colorPreview", { static: true })
  colorPreview: TemplateRef<any>;
  @ViewChild(KaduTableComponent, { static: false }) table: KaduTableComponent;
  cols;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  colors$ = ((filters: TableFilter): Observable<PaginatedResult<any>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };

    return this.colorService.getAllPaginated(
      generateTableRequestParams(this.currentFilters, {})
    );
  }).bind(this);

  constructor(
    private colorService: ColorService,
    private popupService: PopupService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "Id",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Ime",
        field: "name",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Alpha",
        field: "alpha",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Blue",
        field: "blue",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Green",
        field: "green",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Red",
        field: "red",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Pregled boje",
        field: "colorPreview",
        filterType: "string",
        type: "custom",
        options: {
          template: this.colorPreview,
        },
      },
      {
        header: "Sku code",
        field: "skuCode",
        filterType: "string",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "string",
        type: "custom",
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  deleteColor(color: any): void {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da obrišete boju ${color.name}?`,
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.colorService.deleteColor(color.id).subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno obrisana boja",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
        }
      });
  }

  openDialog(color = null): void {
    const dialog = this.dialogService.open(ColorSingleComponent, {
      showHeader: false,
      width: "600px",
      data: {
        color,
      },
    });

    dialog.onClose.subscribe((response) => {
      if (!response) return;

      if (response.editMode) {
        this.colorService.editColor(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno izmenjena boja",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      } else {
        this.colorService.addColor(response.data).subscribe(
          (res) => {
            this.popupService.fire({
              title: "Uspešno dodata boja",
              type: "success",
            });
            this.table.load(this.currentFilters);
          },
          (err) => {
            console.log(err);
            this.popupService.fire({
              title: "Greška",
              text: err?.error?.message,
              type: "error",
            });
          }
        );
      }
    });
  }
}
