<div class="p-3">
  <kadu-table
    #table
    [columns]="cols"
    [rows]="20"
    [lazy]="true"
    [request]="reclamationRequest"
    [expandable]="true"
    [expandedTemplate]="expandedTemplate"
    (onChange)="setCurrentFilters($event)"
    [withFilters]="true"
    [sortable]="true"
  >
    <ng-template #expandedTemplate let-reclamation>
      <h2 *ngIf="reclamation.note">Napomena: {{ reclamation.note }}</h2>
      <div class="p-2">
        <kadu-table
          [sortable]="true"
          [columns]="reclamationItemCols"
          [rows]="100"
          [loading]="false"
          [values]="reclamation.reclamationItems"
          [paginator]="false"
          [loading]="false"
        >
        </kadu-table>
      </div>

      <div class="d-flex justify-content-end">
        <kadu-button
          class="btn btn-submit btn-small w-25"
          (clickHandler)="closeReclamation(reclamation)"
        >
          Završi
        </kadu-button>
      </div>
    </ng-template>
  </kadu-table>
</div>

<ng-template #reclamationItemInfoTemplate let-reclamationItem>
  <div class="text-center">
    <img
      [src]="reclamationItem.productImageUrl"
      alt=""
      style="width: 150px"
      class="h-auto"
    />
    <p class="m-0 p3">{{ reclamationItem.productTypeName }}</p>
    <p class="m-0 p3">{{ reclamationItem.colorName }}</p>
    <p class="m-0 p3">{{ reclamationItem.sizeName }}</p>
    <kadu-button
      class="btn btn-small btn-submit my-2"
      (clickHandler)="toggleChangeReclamationItemMode(reclamationItem)"
    >
      {{ !reclamationItem.change ? "Izmeni stavku" : "Sakrij menjanje" }}
    </kadu-button>
    <fieldset *ngIf="reclamationItem.change">
      <legend class="p2">Promeni u</legend>
      <div>
        <fieldset class="px-2">
          <div class="d-flex gap-2 align-items-center my-1">
            <label class="p2">Veličina</label>

            <kadu-select
              elClass="w-100"
              *ngIf="orderItemInfo[reclamationItem.id]?.sizes"
              [options]="orderItemInfo[reclamationItem.id]?.sizes"
              [SelectedValue]="reclamationItem.sizeId"
              (changeHandler)="setReclamationItemSize(reclamationItem, $event)"
              #sizeSelect
            >
            </kadu-select>
          </div>
          <div class="d-flex gap-2 align-items-center my-1">
            <label class="p2">Boja</label>

            <kadu-select
              elClass="w-100"
              *ngIf="orderItemInfo[reclamationItem.id]?.colors"
              [options]="orderItemInfo[reclamationItem.id]?.colors"
              [SelectedValue]="reclamationItem.colorId"
              (changeHandler)="setReclamationItemColor(reclamationItem, $event)"
              #colorSelect
            >
            </kadu-select>
          </div>
        </fieldset>
      </div>
    </fieldset>
  </div>
</ng-template>

<ng-template #reclamationItemImagesTemplate let-reclamationItem>
  <div class="w-100">
    <img
      *ngFor="let image of reclamationItem.reclamationImageList"
      class="p-2"
      [src]="image"
      alt=""
      style="height: 150px; width: 150px"
    />
  </div>
</ng-template>

<ng-template #reclamationItemActionsTemplate let-reclamationItem>
  <ng-container *ngIf="reclamationItem.processed">
    <p class="p2">
      Akcija:
      {{
        reclamationItem.reclamationReason !== "NONE"
          ? "Prihvacena"
          : "Odbijena"
      }}
    </p>
    <p class="p2">Kategorija: {{ reclamationItem.reclamationReason }}</p>
  </ng-container>
  <ng-container *ngIf="!reclamationItem.processed">
    <p class="p2">Akcija:</p>
    <kadu-select
      elClass="w-100"
      [options]="actions"
      (changeHandler)="setReclamationItemAction(reclamationItem, $event)"
      #actionSelect
    >
    </kadu-select>
    <div class="m-3" *ngIf="actionSelect.SelectedValue === 'ACCEPTED'">
      <p class="p2">Kategorija:</p>
      <kadu-select
        elClass="w-100"
        [options]="reclamationCategory"
        (changeHandler)="setReclamationItemCategory(reclamationItem, $event)"
      >
      </kadu-select>
    </div>
  </ng-container>
</ng-template>
