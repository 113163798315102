import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthInterceptor } from "../../_interceptors/route-url.interceptor";
import { Token } from "../../_view-models/token";
import { JwtService } from "../jwt/jwt.service";
import { SKIP_JWT_AUTHORIZATION_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR } from "../../_interceptors/skip-interceptors";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl = "backoffice/auth";

  AUTH_HEADERS = {
    headers: new HttpHeaders()
      .set(SKIP_JWT_AUTHORIZATION_INTERCEPTOR, SKIP_JWT_AUTHORIZATION_INTERCEPTOR)
      .set(SKIP_JWT_RENEW_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR),
  };

  constructor(private http: HttpClient, private jwtService: JwtService) {}

  get isLoggedIn() {
    return this.jwtService.getDecodedAccessToken();
  }

  login(user: {
    email?: string;
    password?: string;
    barcode?: string;
    pin?: string;
  }): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/login`, user, this.AUTH_HEADERS)
      .pipe(
        switchMap((res): any => {
          this.jwtService.saveAccessToken(res["accessToken"]);
          this.jwtService.saveRefreshToken(res["refreshToken"]);
          return of(this.jwtService.getDecodedAccessToken());
        })
      );
  }

  forgotPassword(forgotPassword: {
    resetUrl: string;
    email: string;
  }): Observable<any> {
    const url: string =
      this.baseUrl + "/forgot_password/" + forgotPassword.email;
    return this.http.post(url, this.AUTH_HEADERS);
  }

  resetPassword(resetPasswordDto: any): Observable<any> {
    const url: string =
      this.baseUrl +
      `${this.jwtService.getDecodedAccessToken()["userId"]}/reset_password`;
    return this.http.put(
      url,
      { newPassword: resetPasswordDto },
      this.AUTH_HEADERS
    );
  }

  changePassword(password: string): Observable<any> {
    const url: string = this.baseUrl + "/change_password";
    return this.http.put(url, { password }, this.AUTH_HEADERS);
  }

  logOut(): Observable<any> {
    const url = `${this.baseUrl}/invalidate_refresh_token`;
    return this.http.post(
      url,
      {
        accessToken: this.jwtService.getAccessToken(),
        userId: this.jwtService.getDecodedAccessToken()["userId"],
        refreshToken: this.jwtService.getRefreshToken(),
      },
      {
        headers: new HttpHeaders().set(SKIP_JWT_RENEW_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR),
      }
    );
  }

  renewRefreshToken(token: Token): Observable<Token> {
    return this.http.put<Token>(`${this.baseUrl}/refresh_token`, token, {
      headers: new HttpHeaders().set("refresh", "refresh"),
    });
  }
}
