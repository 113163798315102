import * as moment from 'moment';

export const environment = {
  production: false,
  apiProtocol: "https://",
  apiHost: "api-brendly-stage.herokuapp.com",
  apiUrl: "",
  countryLogo: "",
  s3Url: "https://brendi-stage.s3.eu-central-1.amazonaws.com/",
  nonWorkingDays: [],
  sentryDSNKey:
    "https://621463e94c0d746312e876494eaf5433@o1377780.ingest.us.sentry.io/4507500114018304",
  appUrl: "https://admin.stage.brendly.rs",
  countries: [
    {
      alpha2Code: "rs",
      apiUrl: "https://api.stage.brendly.rs/",
      logo: 'assets/images/countries/rs.png',
      nonWorkingDays: [
        moment('2024-01-01').toDate(),
        moment('2024-01-02').toDate(),
        moment('2024-01-07').toDate(),
        moment('2024-02-15').toDate(),
        moment('2024-02-16').toDate(),
        moment('2024-05-01').toDate(),
        moment('2024-05-02').toDate(),
        moment('2024-05-03').toDate(),
        moment('2024-05-04').toDate(),
        moment('2024-05-05').toDate(),
        moment('2024-05-06').toDate(),
        moment('2024-11-11').toDate(),
      ]
    },
    {
      alpha2Code: "hr",
      apiUrl: "https://api.stage.brendly.hr/",
      logo: 'assets/images/countries/hr.png',
      nonWorkingDays: [
        moment('2024-01-01').toDate(),
        moment('2024-01-06').toDate(),
        moment('2024-03-31').toDate(),
        moment('2024-04-01').toDate(),
        moment('2024-05-01').toDate(),
        moment('2024-05-30').toDate(),
        moment('2024-06-22').toDate(),
        moment('2024-08-05').toDate(),
        moment('2024-08-15').toDate(),
        moment('2024-11-01').toDate(),
        moment('2024-11-01').toDate(),
        moment('2024-11-18').toDate(),
        moment('2024-12-25').toDate(),
        moment('2024-12-26').toDate(),
      ]
    },
  ],
  setCountry: (country: any) => {
    environment.apiUrl = country.apiUrl;
    environment.countryLogo = country.logo;
    environment.nonWorkingDays = country.nonWorkingDays;
  }
};
