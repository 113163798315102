<kadu-button
  (clickHandler)="openDialog()"
  class="btn btn-submit btn-small mb-2"
>
  Dodaj novi tip printa
</kadu-button>
<kadu-table
  [columns]="productTypeCols"
  [request]="productTypes$"
  [lazy]="true"
  [rows]="20"
  [loading]="false"
></kadu-table>

<ng-template #sizeInfoUrlTemplate let-productType>
  <ng-container
    *ngTemplateOutlet="
      imageTemplate;
      context: { $implicit: productType.sizeInfoUrl }
    "
  ></ng-container>
</ng-template>
<ng-template #coverImageUrlTemplate let-productType>
  <ng-container
    *ngTemplateOutlet="
      imageTemplate;
      context: { $implicit: productType.coverImageUrl }
    "
  ></ng-container>
</ng-template>

<ng-template #imageTemplate let-imageUrl>
  <img
    *ngIf="imageUrl"
    (click)="showImagePopup(imageUrl)"
    style="height: 50px; width: 50px"
    class="cursor-pointer"
    [src]="imageUrl"
    alt="Proizvod"
  />
</ng-template>

<ng-template #actionsTemplate let-productType>
  <div class="d-flex justify-content-center gap-2">
    <span class="cursor-pointer">
      <i
        (click)="openDialog(productType)"
        class="btn btn-submit btn-small bi bi-pencil p3"
      >
      </i>
    </span>
    <span class="cursor-pointer">
      <i
        (click)="deleteProductType(productType)"
        class="btn btn-cancel btn-small bi bi-trash p3"
      >
      </i>
    </span>
  </div>
</ng-template>
