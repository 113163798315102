<nav class="navbar navbar-expand-sm bg-light" style="height: 100px">
  <div class="d-flex align-items-center h-100 w-100 justify-content-between">
    <div class="d-flex align-items-center h-100 gap-2">
      <img class="h-75 w-auto" src="assets/images/logo.png" alt="Logo" />
      <img
        class="h-50 w-auto ms-2"
        [src]="environment.countryLogo"
        [alt]="environment.countryLogo"
      />
    </div>
    <ng-container *ngIf="loggedIn$ | async">
      <div
        class="d-lg-flex d-none align-items-center justify-content-center w-100"
      >
        <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
      </div>

      <div class="d-lg-flex d-none align-items-center justify-content-center">
        <ng-container *ngTemplateOutlet="logoutTemplate"></ng-container>
      </div>

      <div class="d-flex d-lg-none">
        <kadu-mobile-sidebar #sidebar class="sidebar">
          <ul (click)="sidebar.close($event)" class="w-100 m-0 p-0 h-100">
            <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="logoutTemplate"></ng-container>
          </ul>
        </kadu-mobile-sidebar>
      </div>
    </ng-container>
  </div>
</nav>

<ng-template #menuTemplate>
  <ul
    class="d-flex gap-2 align-items-center flex-column flex-lg-row p-0"
    style="list-style: none"
  >
    <kadu-anchor
      routerLinkActive="active"
      elClass="nav-link"
      [path]="['orders', 'accept']"
    >
      Na čekanju
    </kadu-anchor>

    <kadu-anchor
      routerLinkActive="active"
      elClass="nav-link"
      [path]="['orders', 'finish']"
    >
      Čekaju završavanje
    </kadu-anchor>

    <kadu-anchor
      routerLinkActive="active"
      elClass="nav-link"
      [path]="['orders', 'all']"
    >
      Sve porudžbine
    </kadu-anchor>

    <kadu-anchor
      routerLinkActive="active"
      elClass="nav-link"
      [path]="['supplies']"
    >
      Magacin
    </kadu-anchor>

    <kadu-anchor
      routerLinkActive="active"
      elClass="nav-link"
      [path]="['designers']"
    >
      Dizajneri
    </kadu-anchor>

    <kadu-anchor
      routerLinkActive="active"
      elClass="nav-link"
      [path]="['shops']"
    >
      Prodavnice
    </kadu-anchor>

    <kadu-anchor
      routerLinkActive="active"
      elClass="nav-link"
      [path]="['reclamations']"
    >
      Reklamacije
    </kadu-anchor>

    <kadu-anchor
      routerLinkActive="active"
      elClass="nav-link"
      [path]="['dictionaries']"
    >
      Šifarnici
    </kadu-anchor>

    <kadu-anchor routerLinkActive="active" elClass="nav-link" [path]="['misc']">
      Ostalo
    </kadu-anchor>
  </ul>
</ng-template>

<ng-template #logoutTemplate>
  <ul
    class="d-flex gap-2 align-items-center flex-column flex-lg-row p-0 logout"
    style="list-style: none"
  >
    <li class="nav-item">
      <a class="nav-link cursor-pointer" authLogout (onLogout)="onLogout()">
        Logout
      </a>
    </li>
  </ul>
</ng-template>

<router-outlet></router-outlet>
<kadu-loader [isLoading$]="isLoading$"></kadu-loader>
