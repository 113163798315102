<kadu-button
  (clickHandler)="openDialog()"
  class="btn btn-submit btn-small mb-2"
>
  Dodaj novu lokaciju magacina
</kadu-button>
<kadu-table
  [columns]="cols"
  [request]="warehouseLocations$"
  [lazy]="true"
  [rows]="20"
  [loading]="false"
></kadu-table>

<ng-template #actionsTemplate let-warehouseLocation>
  <div class="d-flex justify-content-center gap-2">
    <span class="cursor-pointer">
      <i
        (click)="openDialog(warehouseLocation)"
        class="btn btn-submit btn-small bi bi-pencil p3"
      >
      </i>
    </span>
    <span class="cursor-pointer">
      <i
        (click)="deleteWarehouseLocation(warehouseLocation)"
        class="btn btn-cancel btn-small bi bi-trash p3"
      >
      </i>
    </span>
  </div>
</ng-template>
