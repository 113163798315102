import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { PopupService } from "src/app/components/popup/popup.service";
import { OrderService } from "src/app/_services/order/order.service";
import { Order } from "src/app/_view-models/order";
import { saveAs } from "file-saver";

@Component({
  selector: "app-bex-order-actions",
  templateUrl: "./bex-order-actions.component.html",
  styleUrls: ["./bex-order-actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BexOrderActionsComponent {
  @Input() order: Order;
  @Output() actionHandler = new EventEmitter<boolean>();

  constructor(
    private popupService: PopupService,
    private orderService: OrderService
  ) {}

  getShippingLabel(): void {
    this.orderService.getShippingLabel(this.order.id).subscribe((res) => {
      saveAs(res.body, `shipping_label_${this.order.id}.pdf`);
    });
  }

  sendOrderAgain() {
    this.popupService
      .fire({
        title: `Da li ste sigurni da hoćete ponovo da pošaljete porudžbinu ${this.order.id}`,
        type: "info",
        showCancelButton: true,
      })
      .then((resa) => {
        if (resa.value) {
          this.orderService.sendOrderAgain(this.order.id).subscribe(() => {
            this.popupService.fire({
              titleText: "Uspešno",
              type: "success",
              text: "Porudžbina ponovo poslata",
            });
            this.actionHandler.emit(true);
          });
        }
      });
  }
}
