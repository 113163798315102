import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { KaduTableComponent, PaginatedResult } from "@kadung/kadung/kadu-table";
import { DialogService } from "src/app/components/dialog/dialog.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { WarehouseLocationSingleComponent } from "./_components/warehouse-location-single/warehouse-location-single.component";
import { TableFilter } from "src/app/_models/table-filter";
import { Observable } from "rxjs";
import { generateTableRequestParams } from "src/app/_models/table-util";
import { WarehouseLocationService } from "src/app/_services/warehouse-location/warehouse-location.service";

@Component({
  selector: "app-warehouse-locations",
  templateUrl: "./warehouse-locations.component.html",
  styleUrls: ["./warehouse-locations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarehouseLocationsComponent implements OnInit {
  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild(KaduTableComponent, { static: false }) table: KaduTableComponent;
  cols;

  currentFilters = {
    first: 0,
    rows: 40,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  warehouseLocations$ = ((
    filters: TableFilter
  ): Observable<PaginatedResult<any>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
      filters: {
        ...filters.filters,
      },
    };

    return this.warehouseLocationService.getAll(
      generateTableRequestParams(this.currentFilters, {})
    );
  }).bind(this);

  constructor(
    private popupService: PopupService,
    private dialogService: DialogService,
    private warehouseLocationService: WarehouseLocationService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        header: "Id",
        field: "id",
        filterType: "string",
        type: "normal",
      },
      {
        header: "Adresa",
        field: "address",
        filterType: "string",
        type: "normal",
      },
      {
        header: "SKU",
        field: "skuCode",
        filterType: "string",
        type: "normal",
      },
      {
        header: "",
        field: "actions",
        filterType: "string",
        type: "custom",
        options: {
          template: this.actionsTemplate,
        },
      },
    ];
  }

  deleteWarehouseLocation(warehouseLocation: any): void {
    this.popupService
      .fire({
        title: `Da li ste sigurni da želite da obrišete lokaciju magacina ${warehouseLocation.address}?`,
        type: "warning",
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) {
          this.warehouseLocationService
            .deleteWarehouseLocation(warehouseLocation.id)
            .subscribe(
              (res) => {
                this.popupService.fire({
                  title: "Uspešno obrisana lokacija magacina",
                  type: "success",
                });
                this.table.load(this.currentFilters);
              },
              (err) => {
                console.log(err);
                this.popupService.fire({
                  title: "Greška",
                  text: err?.error?.message,
                  type: "error",
                });
              }
            );
        }
      });
  }

  openDialog(warehouseLocation = null): void {
    const dialog = this.dialogService.open(WarehouseLocationSingleComponent, {
      showHeader: false,
      width: "600px",
      data: {
        warehouseLocation,
      },
    });

    dialog.onClose.subscribe((response) => {
      if (!response) return;

      if (response.editMode) {
        this.warehouseLocationService
          .editWarehouseLocation(response.data)
          .subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno izmenjena lokacija magacina",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
      } else {
        this.warehouseLocationService
          .addWarehouseLocation(response.data)
          .subscribe(
            (res) => {
              this.popupService.fire({
                title: "Uspešno dodata lokacija magacina",
                type: "success",
              });
              this.table.load(this.currentFilters);
            },
            (err) => {
              console.log(err);
              this.popupService.fire({
                title: "Greška",
                text: err?.error?.message,
                type: "error",
              });
            }
          );
      }
    });
  }
}
