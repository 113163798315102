<kadu-form
  elClass="p-3 mt-2"
  [controls]="formControls"
  (submitHandler)="save($event)"
>
  <ng-template kaduTemplate let-formGroup>
    <ng-container [formGroup]="formGroup">
      <div>
        <div>
          <label class="p3">Ime</label>
          <kadu-input elClass="p3" formControlName="name" #name></kadu-input>
          <app-form-errors
            [formElement]="name"
            [control]="formGroup.get('name')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="address">Adresa</label>
          <kadu-input
            elClass="p3"
            formControlName="address"
            #address
          ></kadu-input>
          <app-form-errors
            [formElement]="address"
            [control]="formGroup.get('address')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="city">Grad</label>
          <kadu-input elClass="p3" formControlName="city" #city></kadu-input>
          <app-form-errors
            [formElement]="city"
            [control]="formGroup.get('city')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="country">Država</label>
          <kadu-input
            elClass="p3"
            formControlName="country"
            #country
          ></kadu-input>
          <app-form-errors
            [formElement]="country"
            [control]="formGroup.get('country')"
          ></app-form-errors>
        </div>
        <div class="d-flex gap-2 align-items-baseline mt-2">
          <label class="p3" for="inVat">U PDV-u</label>
          <kadu-checkbox formControlName="inVat" #inVat></kadu-checkbox>
        </div>
        <div>
          <label class="p3" for="tin">PIB</label>
          <kadu-input elClass="p3" formControlName="tin"></kadu-input>
        </div>
        <div>
          <label class="p3" for="postalCode">Poštanski broj</label>
          <kadu-input
            elClass="p3"
            formControlName="postalCode"
            #postalCode
          ></kadu-input>
          <app-form-errors
            [formElement]="postalCode"
            [control]="formGroup.get('postalCode')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="shortName">Skraćeno ime</label>
          <kadu-input
            elClass="p3"
            formControlName="shortName"
            #shortName
          ></kadu-input>
          <app-form-errors
            [formElement]="shortName"
            [control]="formGroup.get('shortName')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="bank">Banka</label>
          <kadu-input elClass="p3" formControlName="bank" #bank></kadu-input>
          <app-form-errors
            [formElement]="bank"
            [control]="formGroup.get('bank')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="bankAccountNumber">Broj računa</label>
          <kadu-input
            elClass="p3"
            formControlName="bankAccountNumber"
            #bankAccountNumber
          ></kadu-input>
          <app-form-errors
            [formElement]="bankAccountNumber"
            [control]="formGroup.get('bankAccountNumber')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="legalNumber">Pravni broj</label>
          <kadu-input
            elClass="p3"
            formControlName="legalNumber"
            #legalNumber
          ></kadu-input>
          <app-form-errors
            [formElement]="legalNumber"
            [control]="formGroup.get('legalNumber')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="code">Kod</label>
          <kadu-input elClass="p3" formControlName="code" #code></kadu-input>
          <app-form-errors
            [formElement]="code"
            [control]="formGroup.get('code')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="contactPerson">Kontakt osoba</label>
          <kadu-input
            elClass="p3"
            formControlName="contactPerson"
            #contactPerson
          ></kadu-input>
          <app-form-errors
            [formElement]="contactPerson"
            [control]="formGroup.get('contactPerson')"
          ></app-form-errors>
        </div>
        <div>
          <label class="p3" for="contactPhone">Broj kontakta</label>
          <kadu-input
            elClass="p3"
            formControlName="contactPhone"
            #contactPhone
          ></kadu-input>
          <app-form-errors
            [formElement]="contactPhone"
            [control]="formGroup.get('contactPhone')"
          ></app-form-errors>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-2 mt-2">
        <kadu-button (clickHandler)="close()" class="btn btn-cancel btn-small">
          Odustani
        </kadu-button>
        <kadu-button type="submit" class="btn btn-submit btn-small">
          Sačuvaj
        </kadu-button>
      </div>
    </ng-container>
  </ng-template>
</kadu-form>
